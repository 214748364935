/* eslint-disable */

const urlIdParamSeparator = ':';

const getOrderId = (urlParams) => {
    if (urlParams && urlParams.id){
        urlParams.id = urlParams.id.replace("?update","")
        if(Number.isInteger(1 * urlParams.id)){
            return urlParams.id;
        }

        const decodedIds = atob(urlParams.id);
        return (1 * decodedIds.split(urlIdParamSeparator)[0]);
    }

    return undefined;
};

const getCalculationId = (urlParams) => {
    if (urlParams && urlParams.id){
        urlParams.id = urlParams.id.replace("?update","")
        if(Number.isInteger(1 * urlParams.id)){
            return undefined;
        }
        console.log("getCalculationId: ", urlParams.id)
        const decodedIds = atob(urlParams.id);
        return (1 * decodedIds.split(urlIdParamSeparator)[1]) || -1;
    }

    return undefind;
};

const encodPublicCalculationLinkId = (orderId, calculationId) => {
    return btoa(`${orderId}:${calculationId}`);
};

const getPublicLinkToCalculation = (orderId, calculationId) => {
    return `${window.location.origin}/calculation/public/${encodPublicCalculationLinkId(orderId, calculationId)}`;
};

export {
    getOrderId
    , getCalculationId
    , encodPublicCalculationLinkId
    , getPublicLinkToCalculation
}
