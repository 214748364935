import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import Loadable from 'react-loadable';
import { addLocaleData, IntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import zh from 'react-intl/locale-data/zh';
import 'iframe-resizer/js/iframeResizer.contentWindow.js';
import withAppInsights from './AppInsight';
import UserStore from './stores/userStore';
import AppStore from './stores/appStore';
// import FlashStore from '../src/stores/flashStore';
import * as URL from './constants/routes';
import { pushRouteOrRedirect, setLangInStorage } from './utils/utils';
import { Preloader } from './components/ui/Preloader';
// eslint-disable-next-line import/extensions
import config from './config';
import {
  ADMIN_LOGISTIC_DOCUMENT_KINDS_CRUD, ADMIN_PROFILE, ADMIN_RATING_USERS,
  ADMIN_STATISTICS,
  ADMIN_SUBSCRIPTION_REQUESTS,
  ADMIN_SUBSCRIPTION_USERS,
} from './constants/routes';
import ManageSubscription from "./pages/common/ManageSubscription";
import AdminRatingUser from "./pages/admin/pages/AdminRatingUser";

addLocaleData([...en, ...ru, ...zh]);

@observer
class App extends Component {

  async UNSAFE_componentWillMount() {
    AppStore.pushRouteInHistory(window.location.pathname + window.location.hash);
    // window.amo_social_button.setMeta({
    //   note: {
    //     text: document.cookie,
    //     element_type: 1,
    //     note_type: 4,
    //   },
    // });
  }

  async componentDidMount() {
    const { history } = this.props;
    history.listen(this.handleLocationChange());
    window.addEventListener('message', this.handlePostMessage);
    this.setLangFromSearch();
    const x = null; const y = 'Hello in ES6+ syntax';
    console.log("ES6+ TEST SUPPORT", x ?? y, document?.getElementById("root"))
    await AppStore.init();
  }

  shouldComponentUpdate(nextProp) {
    const currentLocation = this.props.location;
    const nextLocation = nextProp.location;

    return currentLocation.pathname !== nextLocation.pathname;
  }

  handleLocationChange() {
    return ({ pathname = '' }) => {
      UserStore.showOrHideAccessDeniedMessage();
      pushRouteOrRedirect(this.props.history, pathname);
      window.parent.postMessage('[wizard] scroll top', '*');
      // FlashStore.message = { ...this._message };
      // FlashStore.clear();
    };
  }

  getComponent(path) {
    return Loadable({
      loader: () => import(`./pages/${path}`),
      loading: ({ error, pastDelay }) => {
        console.log('getComponent loading');
        console.log(path);
        console.log('error', error);
        console.log('pastDelay', pastDelay);

        if (error || pastDelay) {
          return <Preloader isShow />;
        }
        return <Preloader isShow isSpinner />;
      },
    });
  }

  setLangFromSearch() {
    const { search } = this.props.history.location;
    const params = new URLSearchParams(search);
    if (params.has('language')) {
      const langFromSearch = params.get('language');
      //Перезаписываем куку, в любом случае, если указан язык в запросе
      setLangInStorage(langFromSearch);
    }else{
      const lang = localStorage.getItem('userLang');
      //Если куки нет, то устанавливаем по умолчанию язык "англ"
      if(lang===undefined||lang===null){
        setLangInStorage("en")
      }
    }
  }

  getComponentForRender(path, props = {}) {
    const LoadableComponent = this.getComponent(path);

    return <LoadableComponent {...props} />;
  }

  handlePostMessage = async (event) => {
    if (config.isProd()) {
      console.log('handlePostMessage event', event);
      console.log('handlePostMessage event.data', event.data);
    }
    if (event.data.appId) {
      AppStore.setAppId(event.data.appId);
      if (config.isProd()) {
        console.log('handlePostMessage appId', event.data.appId);
      }
    }

    if (event.data.organization) {
      if (!UserStore.isGuest()) {
        await UserStore.logout(false);
      }

      const user = await UserStore.auth(`${event.data.organization}@client.com`, `_${event.data.organization}!`);
      // TODO: comment before commit
      // const user = await UserStore.auth('client@admin.com', '123456');

      if (user) {
        AppStore.setProperties(event.data);

        if (event.data.colorScheme) {
          const {
            primaryColor,
            primaryActiveColor,
            primaryActiveDarkenColor,
            primaryBgColor,
            primaryDarkenColor,
            primaryHoverColor,
            iconsColorName,
            companyLogo,
          } = event.data.colorScheme;

          document.documentElement.style.setProperty('--primary-color', primaryColor);
          document.documentElement.style.setProperty('--primary-active-color', primaryActiveColor);
          document.documentElement.style.setProperty('--primary-active-darken-color', primaryActiveDarkenColor);
          document.documentElement.style.setProperty('--primary-bg-color', primaryBgColor);
          document.documentElement.style.setProperty('--primary-darken-color', primaryDarkenColor);
          document.documentElement.style.setProperty('--primary-hover-color', primaryHoverColor);

          if (iconsColorName) {
            AppStore.setIconsColorClassName(`color-${iconsColorName}`);
          }

          if (companyLogo) {
            AppStore.setCompanyLogo(companyLogo);
          }
        }

        window.parent.postMessage('[wizard] login', '*');
      } else {
        window.parent.postMessage('[wizard] error', '*');
      }
    }

    if (event.data.pathname) {
      this.props.history.push(event.data.pathname);
      this.forceUpdate();
    }
  };

  render() {
    const langMessages = AppStore.langMessages;

    if (AppStore.isLoading) {
      return <div className="overlay" />;
    }

    return (
      <IntlProvider defaultLocale="ru" locale={AppStore.userLang} messages={langMessages}>
        <Switch>
          <Route exact path="/" component={this.getComponent('index')} />
          <Route path={"/code"} component={this.getComponent('common/CodePage')}/>
          <Route path={URL.AUTH_LOGIN} component={this.getComponent('auth/Login')} />
          <Route path={URL.AUTH_REGISTER} component={this.getComponent('auth/RegisterPage')} />
          <Route path={URL.AUTH_PASSWORD_RECOVERY} component={this.getComponent('auth/PasswordRecovery')} />
          <Route path={URL.WIZARD} component={this.getComponent('wizard/Wizard')} />

          <Route path={URL.ERROR} component={this.getComponent('common/ErrorPage')} />

          <Route path={`${URL.CITIES_CITIES}/:fromCountry/:toCountry/:hubs/:fromCity/:toCity/:cargo`} component={this.getComponent('wizard/CitiesToCities')} />

          <Route path={URL.WIDGET_WIZARD} render={() => this.getComponentForRender('wizard/Wizard', { widget: true, ...this.props })} />
          <Route path={`${URL.WIDGET_ORDERS}/:id`} render={({ match }) => this.getComponentForRender('common/Calculation', { widget: true, ...this.props, match })} />

          <Route path={URL.ADMIN_USERS} component={this.getComponent('admin/pages/AdminUsersCrud')} />
          <Route path={URL.ADMIN_SUBSCRIPTION_CONFIG} render={() => this.getComponentForRender('admin/pages/AdminSubscriptionConfig', { title: 'Конфигурация', ...this.props })} />
          <Route path={URL.ADMIN_SUBSCRIPTION_MANAGE} component={this.getComponent('common/ManageSubscription')} />
          <Route path={URL.PARTNER_SUBSCRIPTION_MANAGE} component={this.getComponent('common/ManageSubscription')} />
          <Route path={URL.CLIENT_SUBSCRIPTION_MANAGE} component={this.getComponent('common/ManageSubscription')} />
          <Route path={URL.ADMIN_SUBSCRIPTION_USERS} component={this.getComponent('admin/pages/AdminUserSubcriptionCrud')} />
          <Route path={URL.ADMIN_RATING_USERS} component={this.getComponent('admin/pages/AdminRatingUser')} />
          <Route path={URL.ADMIN_SUBSCRIPTION_REQUESTS} component={this.getComponent('admin/pages/AdminSubscriptionRequestsCrud')} />
          <Route path={URL.ADMIN_SUBSCRIPTION_TARIFFS} component={this.getComponent('admin/pages/AdminSubscriptionTariffPlanCrud')} />
          <Route path={URL.ADMIN_ORGANIZATIONS} component={this.getComponent('admin/pages/AdminOrganizationCrud')} />
          <Route path={`${URL.ADMIN_ORDERS}/:id`} component={this.getComponent('common/Calculation')} />
          <Route path={URL.ADMIN_ORDER_TEMPLATE} render={() => this.getComponentForRender('common/TemplateOrdersPage', { title: 'operatorAdmin.directories.template', ...this.props })} />
          <Route path={URL.ADMIN_TEMPLATES_DOCUMENTS} render={() => this.getComponentForRender('common/templatesDocuments/TemplatesDocuments', { title: 'operatorAdmin.directories.template', ...this.props })} />
          <Route path={URL.ADMIN_ORDERS} component={this.getComponent('common/Orders')} />
          <Route path={URL.ADMIN_PERSONAL_ORDERS} render={() => this.getComponentForRender('common/PersonalOrders', { pageName: 'operatorAdmin', urlToRedirect: URL.ADMIN_DELIVERY_ORDERS, ...this.props })} />
          <Route
            path={`${URL.ADMIN_DELIVERY_ORDERS}/:id`}
            component={this.getComponent('admin/pages/AdminDeliveryOrder')}
          />
          <Route path={URL.ADMIN_DELIVERY_ORDERS} component={this.getComponent('admin/pages/AdminDeliveryOrders')} />
          <Route path={URL.ADMIN_EMPTY_ORDERS} component={this.getComponent('admin/pages/AdminEmptyOrders')} />
          <Route path={URL.ADMIN_ORGANIZATION_PROFILES} component={this.getComponent('admin/pages/AdminOrganizationProfiles')} />

          <Route path={URL.ADMIN_GEOGRAPHY_OF_PRESENCE} component={this.getComponent('common/GeographyOfPresence')} />
          <Route path={URL.ADMIN_DOCUMENTS} component={this.getComponent('common/Documents')} />
          <Route path={URL.ADMIN_FREIGHT_SURCHARGES} component={this.getComponent('common/rates/FreightSurcharges')} />
          <Route path={URL.ADMIN_COMMISSION} component={this.getComponent('common/Commission')} />
          <Route path={URL.ADMIN_TRANSPORTATION_RULES} component={this.getComponent('common/TransportationRules')} />
          <Route path={URL.ADMIN_RATES_SEA} component={this.getComponent('common/rates/TariffsSea')} />
          <Route path={URL.ADMIN_PORT_SERVICES} component={this.getComponent('common/rates/PortServices')} />
          <Route path={URL.ADMIN_AIRPORT_SERVICES} component={this.getComponent('common/rates/AirportServices')} />
          <Route path={URL.ADMIN_ROAD_TRANSPORTATION} component={this.getComponent('common/rates/RoadTransportation')} />
          <Route path={URL.ADMIN_RAILWAY_TRANSPORTATION} component={this.getComponent('common/rates/RailwayTransportation')} />
          <Route path={URL.ADMIN_RAILWAY_SERVICES} component={this.getComponent('common/rates/RailwayServices')} />
          <Route path={URL.ADMIN_AIR_TRANSPORTATION} component={this.getComponent('common/rates/AirTransportation')} />
          <Route path={URL.ADMIN_TRUNKING} component={this.getComponent('common/rates/Trunking')} />
          <Route path={URL.ADMIN_TRUNKING_INTERNATIONAL} component={this.getComponent('common/rates/TrunkingInternational')} />
          <Route path={URL.ADMIN_COMPLEX_TARIFF} component={this.getComponent('common/rates/ComplexTariff')} />
          <Route path={URL.ADMIN_PALLET} component={this.getComponent('common/rates/Pallet')} />
          <Route path={URL.ADMIN_INTEREST_TYPES} component={this.getComponent('admin/pages/AdminInterestTypes')} />

          <Route path={URL.ADMIN_LANGUAGES} component={this.getComponent('admin/pages/AdminLanguageCrud')} />
          <Route path={URL.ADMIN_CURRENCIES} component={this.getComponent('admin/pages/AdminCurrencyCrud')} />

          <Route path={URL.ADMIN_LOGISTIC_DOCUMENT_KINDS_CRUD} render={() => this.getComponentForRender('admin/pages/AdminLogisticDocumentKindCrud', { title: 'operatorAdmin.directories.logisticDocumentKindTitle', ...this.props })} />

          <Route path={URL.ADMIN_COMMON_CONTAINER_TYPES} render={() => this.getComponentForRender('admin/pages/AdminContainerTypeCrud', { kind: 'COMMON', title: 'operatorAdmin.directories.typesOfGoods', ...this.props })} />
          <Route path={URL.ADMIN_AUTO_CONTAINER_TYPES} render={() => this.getComponentForRender('admin/pages/AdminContainerTypeCrud', { kind: 'AUTO', title: 'operatorAdmin.directories.trucks', ...this.props })} />
          <Route path={URL.ADMIN_AIR_CONTAINER_TYPES} render={() => this.getComponentForRender('admin/pages/AdminContainerTypeCrud', { kind: 'AIR', title: 'operatorAdmin.directories.airs', ...this.props })} />

          <Route path={URL.ADMIN_GENERAL_TYPES} render={() => this.getComponentForRender('admin/pages/AdminGeneralTypeCrud', { title: 'operatorAdmin.directories.typesOfGeneralCargo', ...this.props })} />

          <Route path={URL.ADMIN_SEA_LINES} component={this.getComponent('admin/pages/AdminSeaLineCrud')} />
          <Route path={URL.ADMIN_CONSOLIDATORS} component={this.getComponent('admin/pages/AdminConsolidatorCrud')} />
          <Route path={URL.ADMIN_RAILWAY_OPERATORS} component={this.getComponent('admin/pages/AdminRailwayOperatorsCrud')} />
          <Route path={URL.ADMIN_SEA_PORT_HUBS} component={this.getComponent('admin/pages/AdminSeaPortHubCrud')} />
          <Route path={URL.ADMIN_AIR_HUBS} component={this.getComponent('admin/pages/AdminAirHubCrud')} />
          <Route path={URL.ADMIN_AIRLINES} component={this.getComponent('admin/pages/AdminAirlinesCrud')} />
          <Route path={URL.ADMIN_RAILWAY_HUBS} component={this.getComponent('admin/pages/AdminRailwayHubsCrud')} />
          <Route path={URL.ADMIN_SURCHARGES} component={this.getComponent('admin/pages/AdminFreightSurcharges')} />
          <Route path={URL.ADMIN_AIRLINE_DISCLAIMERS} component={this.getComponent('admin/pages/AdminAirlineDisclaimers')} />
          <Route path={URL.ADMIN_RAILWAY_DISCLAIMERS} component={this.getComponent('admin/pages/AdminRailwayDisclaimers')} />
          <Route path={URL.ADMIN_VEHICLE_DISCLAIMERS} component={this.getComponent('admin/pages/AdminVehicleTransportationDisclaimers')} />
          <Route path={URL.ADMIN_SEA_CONDITIONS} component={this.getComponent('admin/pages/AdminSeaConditionsCrud')} />
          <Route path={URL.ADMIN_TRANSPORT_HUBS} component={this.getComponent('admin/pages/AdminTransportHubs')} />
          <Route path={URL.ADMIN_SERVICES} component={this.getComponent('admin/pages/AdminServicesCrud')} />
          <Route path={URL.ADMIN_GEOGRAPHY} component={this.getComponent('admin/pages/AdminGeography')} />
          <Route path={URL.ADMIN_EXTERNAL_APIS} component={this.getComponent('admin/pages/AdminExternalAPIs')} />
          <Route path={URL.ADMIN_EXTERNAL_APIS_AGORA} component={this.getComponent('admin/pages/AdminExternalAPIsAgora')} />
          <Route path={URL.ADMIN_REPORTS} component={this.getComponent('admin/pages/AdminReports')} />
          <Route path={URL.ADMIN_RESOURCE_REPORT} component={this.getComponent('admin/pages/AdminResourceReport')} />
          <Route path={URL.ADMIN_FILES} component={this.getComponent('common/staticFileServer/StaticFileServerPage')} />
          <Route path={URL.ADMIN_STATISTICS} component={this.getComponent('admin/pages/AdminStatistics')} />
          <Route path={URL.ADMIN_AUDIT} component={this.getComponent('admin/pages/AdminAudit')} />
          <Route path={URL.ADMIN_FORWARDERS} component={this.getComponent('admin/pages/AdminForwarders')} />
          <Route path={URL.ADMIN_CUSTOMS} component={this.getComponent('common/Customs')} />
          <Route path={URL.ADMIN_COUNTRY_CATEGORIES} component={this.getComponent('admin/pages/AdminCountryCategories')} />
          <Route path={URL.ADMIN_INSURANCE_RATES} component={this.getComponent('common/Insurance')} />
          <Route path={URL.ADMIN_EVENTS} component={this.getComponent('admin/pages/AdminEvents')} />
          <Route path={URL.ADMIN_STATUSES} component={this.getComponent('admin/pages/AdminStatuses')} />
          <Route path={URL.ADMIN_CUSTOMS_CLEARANCE} component={this.getComponent('admin/pages/AdminCustomsClearanceCrud')} />
          <Route path={URL.ADMIN_CHATBOT} render={() => this.getComponentForRender('admin/pages/AdminChatbotCrud', { title: 'operatorAdmin.directories.chatbot', ...this.props })} />
          <Route path={URL.ADMIN_ORGANIZATION_ACTS} component={this.getComponent('admin/pages/AdminOrganizationActsCrud')} />
          <Route path={URL.ADMIN_TEMPLATES_DESCR_TARIFFS} component={this.getComponent('common/templatesDescriptionTariff/TemplatesDescriptionTariffCrud')} />
          <Route path={URL.ADMIN_PROFILE} component={this.getComponent('partner/pages/PartnerGeneralInformation')} />
          <Route path={URL.ADMIN} component={this.getComponent('admin/pages/AdminDashboard')} />

          <Route path={URL.PARTNER_PROFILE} component={this.getComponent('partner/pages/PartnerGeneralInformation')} />
          <Route path={`${URL.PARTNER_ORDERS}/:id`} component={this.getComponent('common/Calculation')} />
          <Route path={URL.PARTNER_ORDERS} component={this.getComponent('common/Orders')} />
          <Route path={URL.PARTNER_ORDER_TEMPLATE} render={() => this.getComponentForRender('common/TemplateOrdersPage', { title: 'operatorAdmin.directories.template', ...this.props })} />
          <Route path={URL.PARTNER_TEMPLATES_DOCUMENTS} render={() => this.getComponentForRender('common/templatesDocuments/TemplatesDocuments', { title: 'operatorAdmin.directories.template', ...this.props })} />
          <Route path={URL.PARTNER_PERSONAL_ORDERS} render={() => this.getComponentForRender('common/PersonalOrders', { pageName: 'partner', urlToRedirect: URL.PARTNER_DELIVERY_GROUP_ORDERS, ...this.props })} />
          <Route path={`${URL.PARTNER_DELIVERY_GROUP_ORDERS}/:id`} component={this.getComponent('partner/pages/PartnerPersonalOrder')} />
          <Route path={URL.PARTNER_FREIGHT_SURCHARGES} component={this.getComponent('common/rates/FreightSurcharges')} />
          <Route path={URL.PARTNER_GEOGRAPHY_OF_PRESENCE} component={this.getComponent('common/GeographyOfPresence')} />
          <Route path={URL.PARTNER_DOCUMENTS} component={this.getComponent('common/Documents')} />
          <Route path={URL.PARTNER_FILES} component={this.getComponent('common/staticFileServer/StaticFileServerPage')} />
          <Route path={URL.PARTNER_USERS} component={this.getComponent('partner/pages/PartnerUsers')} />
          <Route path={URL.PARTNER_ORGANIZATIONS} component={this.getComponent('partner/pages/PartnerOrganizationCrud')} />
          <Route path={URL.PARTNER_COMMISSION} component={this.getComponent('common/Commission')} />
          <Route path={URL.PARTNER_RATES_SEA} component={this.getComponent('common/rates/TariffsSea')} />
          <Route path={URL.PARTNER_PORT_SERVICES} component={this.getComponent('common/rates/PortServices')} />
          <Route path={URL.PARTNER_AIRPORT_SERVICES} component={this.getComponent('common/rates/AirportServices')} />
          <Route path={URL.PARTNER_ROAD_TRANSPORTATION} component={this.getComponent('common/rates/RoadTransportation')} />
          <Route path={URL.PARTNER_AIR_TRANSPORTATION} component={this.getComponent('common/rates/AirTransportation')} />

          <Route path={URL.PARTNER_RAILWAY_TRANSPORTATION} component={this.getComponent('common/rates/RailwayTransportation')} />
          <Route path={URL.PARTNER_RAILWAY_SERVICES} component={this.getComponent('common/rates/RailwayServices')} />
          <Route path={`${URL.PARTNER_DELIVERY_ORDERS}/:id`} component={this.getComponent('partner/pages/PartnerDeliveryOrder')} />
          <Route path={URL.PARTNER_DELIVERY_ORDERS} component={this.getComponent('partner/pages/PartnerDeliveryOrders')} />
          <Route path={URL.PARTNER_TRUNKING} component={this.getComponent('common/rates/Trunking')} />
          <Route path={URL.PARTNER_TRUNKING_INTERNATIONAL} component={this.getComponent('common/rates/TrunkingInternational')} />
          <Route path={URL.PARTNER_COMPLEX_TARIFF} component={this.getComponent('common/rates/ComplexTariff')} />
          <Route path={URL.PARTNER_PALLET} component={this.getComponent('common/rates/Pallet')} />
          <Route path={URL.PARTNER_CUSTOMS} component={this.getComponent('common/Customs')} />
          <Route path={URL.PARTNER_SUBSCRIPTION_MANAGE} render={() => this.getComponentForRender('common/ManageSubscription', { title: 'Моя подписка', ...this.props })} />
          <Route path={URL.PARTNER_TEMPLATES_DESCR_TARIFFS} component={this.getComponent('common/templatesDescriptionTariff/TemplatesDescriptionTariffCrud')} />
          <Route path={URL.PARTNER_REPORTS} component={this.getComponent('admin/pages/AdminReports')} />
          <Route path={URL.PARTNER_STATISTICS} component={this.getComponent('admin/pages/AdminStatistics')} />
          <Route path={URL.PARTNER_CUSTOMS_CLEARANCE} component={this.getComponent('partner/pages/PartnerCustomsClearanceCrud')} />
          <Route path={URL.PARTNER_AIRLINE_DISCLAIMERS} component={this.getComponent('partner/pages/PartnerAirlineDisclaimers')} />
          <Route path={URL.PARTNER_RAILWAY_DISCLAIMERS} component={this.getComponent('partner/pages/PartnerRailwayDisclaimers')} />
          <Route path={URL.PARTNER_VEHICLE_DISCLAIMERS} component={this.getComponent('partner/pages/PartnerVehicleTransportationDisclaimers')} />
          <Route path={URL.PARTNER_SEA_CONDITIONS} component={this.getComponent('partner/pages/PartnerSeaConditionsCrud')} />
          {/*<Route path={PARTNER_RATES} component={PartnerRates} />*/}
          <Route path={URL.PARTNER} component={this.getComponent('partner/pages/PartnerDashboard')} />

          <Route path={`${URL.CLIENT_ORDERS}/:id`} component={this.getComponent('common/Calculation')} />
          <Route path={URL.CLIENT_ORDERS} component={this.getComponent('common/Orders')} />
          <Route path={`${URL.CLIENT_DELIVERY_ORDERS}/:id`} component={this.getComponent('client/pages/ClientDeliveryOrder')} />
          <Route path={URL.CLIENT_DELIVERY_ORDERS} component={this.getComponent('client/pages/ClientDeliveryOrders')} />
          <Route path={URL.CLIENT_DOCUMENTS} component={this.getComponent('common/Documents')} />
          <Route path={URL.CLIENT_CUSTOMS} component={this.getComponent('common/Customs')} />
          <Route path={URL.CLIENT} component={this.getComponent('client/pages/ClientDashboard')} />
          <Route path={URL.CLIENT_SUBSCRIPTION_MANAGE} render={() => this.getComponentForRender('common/ManageSubscription', { title: 'Моя подписка', ...this.props })} />
          {/*<Route path={`${GUEST_ORDERS}/:id`} render={() => <Calculation scope="guest" {...this.props} />} />*/}
          <Route path={`${URL.GUEST_ORDERS}/:id`} component={this.getComponent('common/Calculation')} />
          {/* <Route path={URL.AGREEMENT}
           component={this.getComponent('common/Agreement')} /> */}

          <Route
            path={`${URL.PUBLIC_ORDER_CALCULATION}/:id`}
            component={this.getComponent('common/Calculation')}
            isPublicCalculationLinkUsed={'test'}
          />

          <Route path={'/ui-kit'} component={this.getComponent('UIKit')} />
          <Route path={URL.IN_PROGRESS} component={this.getComponent('common/InProgress')} />
          <Route path="*" component={this.getComponent('common/NotFound')} />
        </Switch>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withAppInsights(App);
