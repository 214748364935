import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, Col, Collapse, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {get} from 'lodash';
import {Error} from '../ui/form-control/common';
import {NumberInput, Select} from '../ui/form-control';
import {DEFAULT_GENERAL} from '../wizard/DefaultFields';
import {addFormatName, getQuantitySizeUnits} from "../../utils/utils";

export class CargoGeneralsItem extends Component {
    state = {
        palletType: null,
        toggled: true,
    };


    UNSAFE_componentWillMount() {
        const {fields} = this.props;

        if (get(fields, 'palletType')) {
            this.setState({
                palletId: fields.palletType.id,
            });
        }
    }

    onChangePalletType = (value) => {
        const {pathToField} = this.props;

        this.setState({
            palletId: value.id,
        });

        const values = {
            [`${pathToField}.palletId`]: value.id,
            [`${pathToField}.sizeUnit`]: value.sizeUnit,
            [`${pathToField}.width`]: value.width,
            [`${pathToField}.length`]: value.length,
            [`${pathToField}.height`]: value.height,
        };

        this.props.onChange(values);
    };

    toggle = () => {
        const isToggled = this.state.toggled;

        this.setState({
            toggled: !isToggled,
        });
    };

    onChangeType = (value) => {
        const {onChange, pathToField} = this.props;
        const sizeUnit =  value.id === 3 ? DEFAULT_GENERAL.cubSizeUnit : DEFAULT_GENERAL.sizeUnit;

        this.setState({
            palletId: null,
        });

        onChange({
            [`${pathToField}.typeId`]: value.id,
            [`${pathToField}.sizeUnit`]: sizeUnit,
            [`${pathToField}.palletId`]:'',
            [`${pathToField}.width`]: '',
            [`${pathToField}.length`]: '',
            [`${pathToField}.height`]: '',
            [`${pathToField}.cubeVolume`]: '',
        });
    };

    renderPackingTypeError = () => {
        const {fields, pathToField, errors} = this.props;

        return (
            errors[`${pathToField}.height`] &&
            fields.typeId === 2 &&
            !this.state.palletId
        );
    };

    getSizeUnitSelect(packingType) {
        const {sizeUnits, fields, pathToField, onChange} = this.props;
        const type = (packingType && packingType === 'purchase') ? 'DERIVED' : 'BASIC';
        const prepareSizeUnits = addFormatName(getQuantitySizeUnits(sizeUnits, type));
        const sizeUnit = sizeUnits.find(item => item.code === fields.sizeUnit);

        return (
            <Select
              classNameGroup={`wizard__select-size-unit ${packingType}`}
                values={prepareSizeUnits}
                labelKey="code"
                valueKey="code"
                value={sizeUnit}
                onChange={value => onChange(value.code, `${pathToField}.sizeUnit`)}
                clearable={false}
            />
        );
    }

    getPalletSizeGroupFields = () => {
        const {palletTypes, pathToField, onChange, fields, errors} = this.props;
        const palletType = palletTypes.find(
            pallet => pallet.id === fields.palletId
        );

        if (palletType) {
            return (
                <div>
                    <div className="f-group cargo-general-size">
                        <NumberInput
                            min={0}
                            value={fields.length}
                            onChange={value => onChange(value, `${pathToField}.length`)}
                            disabled={!!palletType.length}
                            isSpinner={false}
                            placeholder="wizard.cargo.form.size.length.placeholder"
                            label="wizard.cargo.form.size.length"
                            decimalScale={2}
                            //error={errors[`${pathToField}.length`]}
                        />
                        <NumberInput
                            min={0}
                            style={{marginTop:"1px"}}
                            value={fields.width}
                            onChange={value => onChange(value, `${pathToField}.width`)}
                            disabled={!!palletType.width}
                            isSpinner={false}
                            placeholder="wizard.cargo.form.size.width.placeholder"
                            label={' '}
                            decimalScale={2}
                            //error={errors[`${pathToField}.width`]}
                        />
                        <NumberInput
                            min={0}
                            style={{marginTop:"1px"}}
                            value={fields.height}
                            onChange={value => onChange(value, `${pathToField}.height`)}
                            disabled={!!palletType.height}
                            isSpinner={false}
                            placeholder="wizard.cargo.form.size.height.placeholder"
                            showErrors={false}
                            label={' '}
                            error={errors[`${pathToField}.height`]}
                            decimalScale={2}
                        />
                        {this.getSizeUnitSelect('pallets')}
                    </div>
                    {this.renderErrorGeneralSize(pathToField, errors)}
                </div>
            );
        }

        return (
            <Select
                placeholder="wizard.cargo.form.palletTypes.placeholder"
                values={palletTypes}
                classNameGroup="wizard__select-100"
                labelKey="formatName"
                valueKey="id"
                label="wizard.cargo.form.palletTypes"
                value={palletType}
                onChange={this.onChangePalletType}
                showErrors={false}
                error={this.renderPackingTypeError() ? 'common.required' : ''}
                clearable={false}
            />
        );
    };

    getBoxSizeGroupFields = () => {
        const {pathToField, onChange, fields, errors } = this.props;

        return (
            <div>
                <div className="f-group cargo-general-size">
                    <NumberInput
                        min={0}
                        value={fields.length}
                        onChange={value => onChange(value, `${pathToField}.length`)}
                        isSpinner={false}
                        placeholder="wizard.cargo.form.size.length"
                        label="deliveryOrders.cargoInformation.cargoSizeTitle"
                        showErrors={false}
                        error={errors[`${pathToField}.length`]}
                        decimalScale={2}
                    />
                    <NumberInput
                        style={{marginTop:"1px"}}
                        min={0}
                        value={fields.width}
                        onChange={value => onChange(value, `${pathToField}.width`)}
                        isSpinner={false}
                        label={' '}
                        placeholder="wizard.cargo.form.size.width"
                        showErrors={false}
                        error={errors[`${pathToField}.width`]}
                        decimalScale={2}
                    />
                    <NumberInput
                        min={0}
                        style={{marginTop:"1px"}}
                        value={fields.height}
                        onChange={value => onChange(value, `${pathToField}.height`)}
                        isSpinner={false}
                        label={' '}
                        placeholder="wizard.cargo.form.size.height"
                        showErrors={false}
                        error={errors[`${pathToField}.height`]}
                        decimalScale={2}
                    />
                    {this.getSizeUnitSelect('boxes-crates')}
                </div>
                {this.renderErrorGeneralSize(pathToField, errors)}
            </div>
        );
    };

    getCubeSizeFields = (sizeUnit) => {
        const {pathToField, onChange, fields, errors } = this.props;
        fields.amount = 1;
        return (
            <div>
                <div className="f-group cargo-general-size cubes">
                    <NumberInput
                        style={{marginTop:"1px"}}
                        min={0}
                        value={fields.cubeVolume}
                        onChange={value => onChange(value, `${pathToField}.cubeVolume`)}
                        isSpinner={false}
                        label="wizard.cargo.totalVolume"
                        placeholder={`wizard.cargo.form.CubVolume.placeholder.${sizeUnit.code}`}
                        showErrors={false}
                        error={errors[`${pathToField}.cubeVolume`]}
                        decimalScale={2}
                    />
                    {this.getSizeUnitSelect('purchase')}
                </div>
                {this.renderErrorCubeVolume(pathToField, errors)}
            </div>
        );
    };

    renderErrorGeneralSize = (pathToField, errors) => {
        if (
            errors[`${pathToField}.height`] ||
            errors[`${pathToField}.width`] ||
            errors[`${pathToField}.length`]
        ) {
            return <Error >{'common.required'}</Error>
        }

        return null;
    };

    renderErrorCubeVolume = (pathToField, errors) => {
        if (
            errors[`${pathToField}.cubeVolume`]
        ) {
            return <Error >{'common.required'}</Error>
        }
        return null;
    };

    renderWeightError = (pathToField, errors) => {
        if (errors[`${pathToField}.weight`]) {
            return <Error>{'common.required'}</Error>;
        }

        return null;
    };


    getCommonError = () => {
        return (
            <Col xs={12} lg={12}>
                <Error>{{id:'general.oversized.cargo'}}</Error>
            </Col>

        );
    }


    renderContent = (isLg) => {
        const {
            className = '',
            packingTypes,
            weightUnits,
            sizeUnits,
            fields,
            onChange,
            pathToField,
            onRemove,
            isShowControl,
            errors,
        } = this.props;
        const packingType = packingTypes.find(item => item.id === fields.typeId);
        const weightUnit = weightUnits.find(
            item => item.code === fields.weightUnit
        );
        const sizeUnit = sizeUnits.find(
            item => item.code === fields.sizeUnit
        );

        const weightUnitSelect = (
            <Select
                classNameGroup="wizard__select-weight-unit"
                values={weightUnits}
                labelKey="code"
                valueKey="code"
                value={weightUnit}
                onChange={value => onChange(value.code, `${pathToField}.weightUnit`)}
                clearable={false}
            />
        );

        return (
            <div><Row className={`f-inline align-items-start cargo-item mt-3 ${className}`}>
                <Col xs={12} className="pl-0">
                    <Row>
                        <Col xs={12} lg={4}>
                            <Row className="align-items-end">
                                <Col xs={12} md={7} style={{margin:"0"}}>
                                    <Select
                                        values={packingTypes}
                                        value={packingType}
                                        labelKey="formatName"
                                        valueKey="id"
                                        classNameGroup="wizard__select-100"
                                        onChange={this.onChangeType}
                                        label="wizard.cargo.form.typeOfCargo"
                                        clearable={false}
                                    />
                                </Col>
                                {packingType && (packingType.id === 1 || packingType.id === 2)&&
                                    <Col xs={6} md={5}>
                                    <NumberInput
                                        step={1}
                                        precision={0}
                                        strict
                                        placeholder="wizard.cargo.form.amount.placeholder"
                                        label="wizard.cargo.form.amount"
                                        value={fields.amount}
                                        onChange={value =>
                                            onChange(value, `${pathToField}.amount`)
                                        }
                                        error={errors[`${pathToField}.amount`]}
                                    />
                                </Col>}
                            </Row>
                        </Col>
                        <Col xs={12} lg={4} className="d-flex align-items-start">

                            {packingType && packingType.id === 1 && this.getBoxSizeGroupFields()}
                            {packingType && packingType.id === 2 && this.getPalletSizeGroupFields()}
                            {packingType && packingType.id === 3 && this.getCubeSizeFields(sizeUnit)}

                            {this.renderPackingTypeError() ? (
                                <Error>{'common.required'}</Error>
                            ) : null}
                        </Col>

                        <Col xs={9} lg={2} className="d-flex align-items-start">
                            <div >
                                <div className="f-group">
                                    <NumberInput
                                        min={0}
                                        placeholder={`wizard.cargo.form.weight.placeholder.${weightUnit.code}`}
                                        label={packingType && packingType.id === 3 ? "wizard.cargo.form.size.totalWeight" : "wizard.cargo.form.weight"}
                                        value={fields.weight}
                                        onChange={value =>
                                            onChange(value, `${pathToField}.weight`)
                                        }
                                        isSpinner={false}
                                        showErrors={false}
                                        error={errors[`${pathToField}.weight`]}
                                    />
                                    {weightUnitSelect}
                                </div>
                                {this.renderWeightError(pathToField, errors)}
                            </div>
                        </Col>
                        <Col
                            xs={3}
                            lg={1}
                            className="d-flex align-items-start "
                        >
                            <div className={"d-flex total-weight"} style={{minHeight:"48px"}}>
                                <span className="align-self-center">{/*<FormattedMessage id="wizard.general.weight" />{': '}*/}
                                {(
                                    get(fields, 'amount', 0) * get(fields, 'weight', 0)
                                ).toFixed(2)}{' '}
                                {weightUnit.formatName}
                                </span>
                            </div>
                        </Col>
                        {get(fields,"errorOverload") &&
                        <Col xs={12} lg={1} className="d-lg-none">
                            <Row className="inv_show">
                                {this.getCommonError()}
                            </Row>
                        </Col>
                        }
                        <Col
                            xs={6}
                            lg={1}
                            className="d-flex align-items-start"
                        >
                            {isShowControl && isLg && (
                                <div className={"d-flex"} style={{minHeight:"48px"}}>
                                    <span className="align-self-center" style={{marginTop:"-4px"}}>
                                        <Button
                                            className="btn-sm btn-remove"
                                            color="default"
                                            onClick={onRemove}
                                        />
                                    </span>
                                </div>
                            )}
                        </Col>
                        {get(fields,"errorOverload") &&
                            <Col xs={1} lg={12} className="d-none d-lg-block">
                                <Row className="inv_show">
                                    {this.getCommonError()}
                                </Row>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            </div>
        );
    };

    render() {
        const {
            weightUnits,
            fields,
            onRemove,
            isShowControl,
            index,
            errors,
            errorsRefs,
            onErrorsRefs,pathToField,
        } = this.props;

        const {toggled} = this.state;
        const classToggleName = toggled ? 'up' : 'down';

        const weightUnit = weightUnits.find(
            item => item.code === fields.weightUnit
        );

        if (weightUnit) {
            return (
                <div ref={(ref)=>{
                    if (errors[`${pathToField}.weight`] ||
                        errors[`${pathToField}.amount`] ||
                        errors[`${pathToField}.height`] ||
                        errors[`${pathToField}.width`] ||
                        errors[`${pathToField}.length`]) {
                        errorsRefs.push(ref);
                        onErrorsRefs();
                    }
                }}>
                    <div className="container summary">
                        <Row className="wizard__cargo-from_header_summary">
                            <Col xs={9} className="wizard__cargo-from_header_title">
                                <FormattedMessage id="form.wizard.title.cargoItem"/>
                                <span>{' '} {index}</span>
                            </Col>
                            <Col xs={3} className="wizard__cargo-from_header_button">
                                {isShowControl && (
                                    <Button
                                        className="btn-sm btn-remove"
                                        color="default"
                                        onClick={onRemove}
                                    />
                                )}
                                <Button
                                    className={`btn-sm btn-collapse-cargo ${classToggleName}`}
                                    onClick={this.toggle}
                                />
                            </Col>
                        </Row>
                        <Collapse isOpen={toggled}>
                            <Card>
                                <CardBody>
                                    {this.renderContent()}
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                    <div className="container summaryLg">
                        {this.renderContent(true)}
                    </div>
                </div>
            );
        }

        return null;
    }
}

CargoGeneralsItem.propTypes = {
    className: PropTypes.string,
    pathToField: PropTypes.string,
    fields: PropTypes.object,
    weightUnits: PropTypes.array,
    sizeUnits: PropTypes.array,
    packingTypes: PropTypes.array,
    palletTypes: PropTypes.array,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    isShowControl: PropTypes.bool,
    errors: PropTypes.object,
    index: PropTypes.string,
    onErrorsRefs: PropTypes.func,
};
