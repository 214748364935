import HttpProvider from './httpProvider';
import { API } from '../constants/api';

class OrderService {
  constructor() {
    this.http = HttpProvider;
  }

  async getDisclaimers(idOrder, idCalculation, lang){
    return await this.http.get(API.DISCLAIMERS.CURRENT_CALCULATION({idOrder, idCalculation, lang}),true);
  }

  async createOrder(data) {
    return await this.http.post(API.ORDERS.BASE, data);
  }

  async getOrder(id, onlySaved = false) {
    return await this.http.get(API.ORDERS.CURRENT_ORDER({ id, onlySaved}), false);
  }

  async getOrderTasks(id) {
    return await this.http.get(API.ORDERS.CURRENT_ORDER_TASKS({ id }), false);
  }

  async createOrderGeneral(id) {
    return await this.http.put(API.ORDERS.ORDER_GENERAL({ id }), false);
  }

  async createOrderContainer(id, type = 20) {
    return await this.http.post(API.ORDERS.ORDER_CONTAINER({ id, type }), false);
  }

  async getOrderExternalRates(id, sortType = 'BEST_TIME') {
    return await this.http.get(API.ORDERS.CURRENT_ORDER_EXTERNAL_RATES({ id, sortType }), false);
  }

  async getOrderCalculations(id, onlySaved = false, isWidget=false, isPublic, hash=-1) {
    return await this.http.get(API.ORDERS.CALCULATIONS({ id, onlySaved,isWidget, hash }), false);
  }

  async requestDeliveryByCalculation(params, data) {
    return await this.http.post(API.ORDERS.REQUEST_DELIVERY(params), data);
  }

  async updateRequestDetails(params, data) {
    return await this.http.put(API.ORDER_DELIVERY.UPDATE_REQUEST_DETAILS(params), data);
  }

  async updateCalculation(id, calcId, data) {
    return await this.http.put(API.ORDERS.UPDATE_CALCULATION({ id, calcId }), data);
  }

  async recalculationPrice(orderId, calculationId){
    return await this.http.put(API.ORDERS.UPDATE_PRICE_CALCULATION({orderId, calculationId}), {})
  }

  async getExpiredCalculationsForOrder(orderId){
    return await this.http.get(API.ORDERS.EXPIRED_CALCULATIONS({orderId}))
  }

  async postUpdateExpiredOrder(orderId){
    return await this.http.get(API.ORDERS.POST_UPDATE_EXPIRED_ORDER({orderId}))
  }

  async saveCalculation(id, calcId, data) {
    return await this.http.post(API.ORDERS.UPDATE_CALCULATION({ id, calcId }), data);
  }

  async sendOrderByEmail(id, data) {
    return await this.http.post(API.ORDERS.CALCULATIONS_EMAIL_SEND({ id }), data);
  }

  async sendQuestionByCalculationByEmail(id, calcId, data) {
    return await this.http.put(API.ORDERS.QUESTION_BY_CALCULATION_EMAIL_SEND({ id, calcId }), data);
  }

  async sendQuestionByOrderByEmail(id, data) {
    return await this.http.put(API.ORDERS.QUESTION_BY_ORDER_EMAIL_SEND({ id }), data);
  }

  async sortCalculationList(id, onlySaved = false, data) {
    return await this.http.put(API.ORDERS.SORT_CALCULATIONS({ id, onlySaved }), data);
  }

  async calculateCustoms(data) {
    return await this.http.post(API.CUSTOMS.CALC_CUSTOMS, data);
  }

  async calcCustoms(data) {
    return await this.http.post(API.ORDERS.CUSTOMS(), data);
  }

  async requestCustoms(id, data) {
    return await this.http.post(API.ORDERS.CALC_CUSTOMS({ id }), data);
  }

  async updateCustoms(id, customsId, data) {
    return await this.http.put(API.ORDERS.UPDATE_CUSTOMS({ id, customsId }), data);
  }

  async getCustoms(id) {
    return await this.http.get(API.ORDERS.GET_CUSTOMS({ id }), false);
  }

  async calcInsurance(data) {
    return await this.http.post(API.ORDERS.INSURANCE(), data);
  }

  async getCountExpiredOrders(){
    return await this.http.get(API.ORDERS.EXPIRED_ORDERS_COUNT(), false)
  }
  async getExpiredOrders(){
    return await this.http.get(API.ORDERS.EXPIRED_ORDERS(), false)
  }

  async createOrderFake(request) {
    return await  this.http.post(API.ORDERS.CREATE_FAKE(), request)
  }

  async checkAccessToOrder(id){
    return await this.http.get(API.ORDERS.CHECK_ACCESS({id}), false)
  }
}

export default new OrderService();
