import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Container, FormGroup, Label, Row} from "reactstrap";
import {AllHubsSelect, DateInput, Select, TextInput} from "../ui/form-control";
import {cloneDeep, get, set} from "lodash";
import UserStore from "../../stores/userStore";
import {FormattedMessage} from "react-intl";
import NumberInput from "../ui/form-control/NumberInput";
import {addFormatName, translate} from "../../utils/utils";
import DictionaryService from "../../services/dictionaryService";
import ChangeOwnerOrderModal from "../Orders/ChangeOwnerOrderModal";

const OrderDetailsBlock = ({data, onSave, errors, onErrorDelete, toCountry}) => {

    const [disableCompany, setDisableCompany]=useState(false)
    const [disablePhone, setDisablePhone]=useState(false)
    const [currencies, setCurrencies] = useState([])
    const [transitHubs, setTransitHubs] = useState([])
    const [transitHub, setTransitHub] = useState(null)
    const [newOwner, setNewOwner]=useState(null)

    const onChange=(name, value)=>{
        const newData = cloneDeep(data)
        set(newData, name, value)
        if(name==="price.value")
            onErrorDelete(`price`)
        else
            onErrorDelete(`shortOrderRequestDetailsParam.${name}`)
        onSave(newData)
    }

    useEffect(() => {
        if(UserStore.user.organization){
            const newData = cloneDeep(data)
            if(UserStore.user.organization.name) {
                newData.companyName = UserStore.user.organization.name
                setDisableCompany(true)
            }
            if(UserStore.user.organization.phone) {
                newData.phone = UserStore.user.organization.phone
                setDisablePhone(true)
            }
            onSave(newData)
        }
        DictionaryService.getCurrencies().then(res=>{
            setCurrencies(res)
        })
    }, []);

    useEffect(()=>{
        DictionaryService.getAllHubs().then(res=>{
            const newData = res.filter(v=>v.country.code.toString()===toCountry.toString()||v.country.id.toString()===toCountry.toString())
            setTransitHubs(newData)
        })
    }, [toCountry])

    const getError=(name)=>{
        if(errors){
            return get(errors, name, null)
        }
        return null
    }

    const onChangeOwner=(nameField, valueField)=>{
        onChange(`newOwner.${nameField}`, valueField)
    }

    return (
        <Container className={"block-margin-top"}>
            <div className={"details-order-param"}>
                <Row>
                    <Col xs={12}>
                        <h1><FormattedMessage id={"order.createNewManual.detailsOrder.title"}/></h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={8}>
                        <FormGroup>
                            {toCountry &&
                                <AllHubsSelect
                                    item={transitHub}
                                    name={"transitHubId"}
                                    async
                                    multi={false}
                                    searchable
                                    values={addFormatName(transitHubs)}
                                    valueKey="id"
                                    labelKey="formatName"
                                    value={transitHub}
                                    onChange={value => onChange("transitHubId",value)}
                                    className="crud-select"
                                    countryCode={toCountry.toString()}
                                    label={<span className={"f-label"} style={{marginBottom:"5px"}}><FormattedMessage id={"crud.field.transitHub.title"}/></span>}
                                />
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <TextInput
                                disabled={disableCompany}
                                error={getError("shortOrderRequestDetailsParam.companyName")}
                                value={data.companyName}
                                onChange={(ev)=>onChange("companyName", ev.target.value)}
                                label={<span className={"f-label flag-required"}><FormattedMessage id={"orderForm.companyName"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <TextInput
                                disabled={disablePhone}
                                error={getError("shortOrderRequestDetailsParam.phone")}
                                value={data.phone}
                                onChange={(ev)=>onChange("phone", ev.target.value)}
                                label={<span className={"f-label flag-required"}><FormattedMessage id={"form.field.phone"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <TextInput
                                value={data.cargoDescription}
                                error={getError("shortOrderRequestDetailsParam.cargoDescription")}
                                onChange={(ev)=>onChange("cargoDescription", ev.target.value)}
                                label={<span className={"f-label flag-required"}><FormattedMessage id={"form.field.cargoName"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <DateInput
                                placeholder={"form.order.shippingDatePlan"}
                                value={data.dateReadyCargo.plan}
                                onChange={(ev)=>onChange("dateReadyCargo.plan", ev)}
                                label={<span className={"f-label"}><FormattedMessage id={"form.order.shippingDatePlan"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <TextInput
                                value={data.remarks}
                                placeholder={"form.order.remarks"}
                                onChange={(ev)=>onChange("remarks", ev.target.value)}
                                label={<span className={"f-label"}><FormattedMessage id={"form.order.remarks"}/></span>}
                                type={"textarea"}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={8}>
                        <FormGroup className={"mb-0"}>
                            <Label className={"f-label flag-required"}><FormattedMessage id={"deliveryOrders.listItem.totalPrice"}/></Label>
                            <div className={"d-flex"}>
                                <NumberInput
                                    error={getError("price")}
                                    value={data.price.value}
                                    placeholder={"crud.field.price.title"}
                                    onChange={value => onChange("price.value", value)}
                                />
                                <div style={{width:"80px"}} className={"ml-3"}>
                                    <Select
                                        multi={false}
                                        values={currencies}
                                        labelKey="code"
                                        valueKey="code"
                                        value={data.price.currency}
                                        onChange={el => onChange('price.currency', el.code )}
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <ChangeOwnerOrderModal isBlock={true} onChange={onChangeOwner}/>
            </div>
        </Container>
    );
};

OrderDetailsBlock.propTypes = {};

export default OrderDetailsBlock;
