import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "../Modal";
import HeaderModalTableTariffs from "./HeaderModalTableTariffs";
import TableTariffs from "./TableTariffs";
import {FormattedMessage} from "react-intl";
import {Button, Col, Row} from "reactstrap";
import AppStore from "../../../stores/appStore";

const ModalTableTariffInput = ({tableTariffsType, bulkEditItems, tableTariffsEndpoint, items, onAfterSave}) => {
    const [isShow, setIsShow] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);

    const onCancel = () => {
        setIsShowConfirm(true)
    }

    return (
        <div style={{display: 'contents'}}>
            <Modal
                className={"confirm-cancel-modal"}
                isShow={isShowConfirm}
                onClose={()=>setIsShowConfirm(false)}
                isModal={true}
                title={<FormattedMessage id={"dialog.confirm.default.question"} />}
            >
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"tableComponent.confirm.cancelChages"}/>
                    </Col>
                </Row>
                <Row className="btn-controls mt-3" style={{justifyContent: 'flex-start'}}>
                    <Col xs={12}>
                        <Button className={"btn-primary-new"} onClick={() => {
                            setIsShowConfirm(false);
                            setIsShow(false);
                            onAfterSave();
                        }}>
                            <FormattedMessage id="dialog.confirm.yes"/>
                        </Button>
                        <Button className={"btn-outline-primary-new"} onClick={() => setIsShowConfirm(false)}>
                            <FormattedMessage id="dialog.confirm.no"/>
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal className={'modal-resource-report-crud modal-minheight-700px modal-table-tariffs'}
                   title={
                       <HeaderModalTableTariffs
                           tariffType={tableTariffsType}
                           isEdit={bulkEditItems.length !== 0}
                       />
                   }
                   isModal={true}
                   isShow={isShow}
                   onClose={onCancel}>
                <div className="mx-auto text-center">
                    <TableTariffs endpoint={tableTariffsEndpoint}
                                  dataTariffsEdit={items.filter(v => bulkEditItems.includes(v.id))}
                                  onAfterSave={() => {
                                      setIsShow(false);
                                      onAfterSave();
                                  }}
                                  onCancel={onCancel}
                                  type={tableTariffsType}/>
                </div>
            </Modal>
            <div onClick={() => setIsShow(true)} className="btn btn-primary" style={{minWidth:"130px",marginTop: `4px`}}>
                {bulkEditItems.length === 0 ?
                    <FormattedMessage id="crud.form.table.add"/> : <FormattedMessage id="crud.form.table.edit"/>}
            </div>
        </div>
    );
};

ModalTableTariffInput.propTypes = {};

export default ModalTableTariffInput;