/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import {
  uniqueId,
  unary,
  get,
  isEmpty,
  findIndex,
  find,
  filter as _filter,
  map,
  compact,
  cloneDeep,
  uniqBy,
  debounce,
  set,
} from 'lodash';
import FontAwesome from 'react-fontawesome';
import {
  Collapse, Button, Input, Col, Row,
  Popover, PopoverBody, Label, UncontrolledPopover
} from 'reactstrap';
import { Error } from '../form-control/common';
import { translate, addFormatName, addFormatNameToConstant, confirm, addFormatNameForAirsWithCity } from '../../../utils/utils'; //addHashParamToUrl, deleteAllHashParamsFromUrl,
import Checkbox from '../form-control/Checkbox';
import Select from '../form-control/Select';
import DictionaryService from '../../../services/dictionaryService';
import OrganizationService from '../../../services/organizationService';
import { DateInput } from '../form-control';
import {
  ACT_STATUS_FILTER,
  DATE_FORMAT,
  FILTER_EDITORS,
  FILTER_FIELD_TYPES,
  ORDER_DELIVERY_STATUS_FILTER,
  ORGANIZATIONS_PROFILES_STATUSES
} from '../../../constants/global'; // EXPIRED_TARIFFS_FILTER_NAME
import AppStore from '../../../stores/appStore';
import UserStore from '../../../stores/userStore';
import { TARIFICATIONS, SURCHARGE_TYPES, NDS, INSURANCE_TRANSPORTATION, MODIFIER, CHARGING } from '../../../constants/staticData';
import NumberInput from '../form-control/NumberInput';
import CrudService from '../../../services/crudService';
import FilterStore from '../../../stores/filterStore';
import HttpProvider from '../../../services/httpProvider';
import { API } from '../../../constants/api';
import userStore from "../../../stores/userStore";

export default class CrudFilters extends Component {
  state = {
    currentFilter: {},
    filters: [],
    fields: this.props.fields.map((field) => {
      let label = '';
      let labels = [];

      if (field.label) {
        label = translate(field.label);
      }

      if (field.labels) {
        labels = field.labels.map(unary(translate));
      }

      return { ...field, label, labels };
    }),
    selectOptions: {},
    isOpen: false,
    isOpenAvailableFields: false,
    errors: [],
    targetId: uniqueId(),
  };

  crudEndpoint = 'filters';
  crudActions = {
    list: async (offset, limit, query) => await HttpProvider.get(API.BASE(`${this.crudEndpoint}?offset=${offset}&limit=${limit}${query}`), false),
    create: async data => await HttpProvider.post(API.BASE(this.crudEndpoint), data),
    read: async id => await HttpProvider.get(API.BASE(`${this.crudEndpoint}/${id}`), false),
    update: async (id, data) => await HttpProvider.put(API.BASE(`${this.crudEndpoint}/${id}`), data),
    delete: async id => await HttpProvider.remove(API.BASE(`${this.crudEndpoint}/${id}`)),
  };

  store = new FilterStore(this.crudEndpoint, {}, {}, this.crudActions);

  emptyFilterModel = {
    collapsed: true,
    context: this.props.context,
    name: '',
    primary: false,
    value: [],
  };

  crudActions = (crudEndpoint) => {
    return {
      read: async id => await HttpProvider.get(API.BASE(`${crudEndpoint}/${id}`), false),
    };
  };

  getSelectOptionById = {
    [FILTER_EDITORS.SELECT_SEA_LINE]: (id) => {
      return (new CrudService('dictionaries/sealines', this.crudActions('dictionaries/sealines'))).get(id);
    },
    [FILTER_EDITORS.SELECT_CONSOLIDATOR]: (id) => {
      return (new CrudService('dictionaries/sealines', this.crudActions('dictionaries/sealines'))).get(id);
    },
    [FILTER_EDITORS.SELECT_LINE_CONSOLIDATOR]: (id) => {
      return (new CrudService('dictionaries/sealines', this.crudActions('dictionaries/sealines'))).get(id);
    },
    [FILTER_EDITORS.SELECT_PORT]: (id) => {
      return (new CrudService('dictionaries/sea-port-hubs', this.crudActions('dictionaries/sea-port-hubs'))).get(id);
    },
    [FILTER_EDITORS.SELECT_AIR_PORT]: (id) => {
      return (new CrudService('dictionaries/airport-hubs', this.crudActions('dictionaries/airport-hubs'))).get(id);
    },
    [FILTER_EDITORS.SELECT_AIR_PORT_NO_TERMINAL]: (id) => {
      return (new CrudService('dictionaries/airport-hubs/has-no-terminals', this.crudActions('dictionaries/airport-hubs/has-no-terminals'))).get(id);
    },
    [FILTER_EDITORS.SELECT_HUB]: (id) => {
      return (new CrudService('common/dictionaries/all-hubs', this.crudActions('common/dictionaries/all-hubs'))).get(id);
    },
    [FILTER_EDITORS.SELECT_RAILWAY]: (id) => {
      return (new CrudService('common/dictionaries/railway-hubs', this.crudActions('common/dictionaries/railway-hubs'))).get(id);
    },
    [FILTER_EDITORS.SELECT_SERVICE_CATEGORY]: (id) => {
      return (new CrudService('dictionaries/service-categories', this.crudActions('dictionaries/service-categories'))).get(id);
    },
    [FILTER_EDITORS.SELECT_SERVICE_PROFILE]: (id) => {
      return (new CrudService('dictionaries/service-profiles', this.crudActions('dictionaries/service-profiles'))).get(id);
    },
    [FILTER_EDITORS.SELECT_ORGANIZATION_TARIFFS]: (id) => {
      return (new CrudService('organizations', this.crudActions('organizations'))).get(id);
    },
    [FILTER_EDITORS.SELECT_ORGANIZATION_CLIENT]: (id) => {
      return (new CrudService(`organizations/${userStore.user.organization.id}/clients`, this.crudActions('organizations'))).get(id);
    },
    [FILTER_EDITORS.SELECT_REGIONS]: (id) => {
      return (new CrudService('dictionaries/geo', this.crudActions('dictionaries/geo'))).get(id);
    },
    [FILTER_EDITORS.SELECT_CITIES]: (id) => {
      return (new CrudService('dictionaries/geo', this.crudActions('dictionaries/geo'))).get(id);
    },
    [FILTER_EDITORS.SELECT_COUNTRY]: (id) => {
      return (new CrudService('dictionaries/geo', this.crudActions('dictionaries/geo'))).get(id);
    },
    [FILTER_EDITORS.SELECT_USER]: (id) => {
      return (new CrudService('users', this.crudActions('users'))).get(id);
    },
    [FILTER_EDITORS.SELECT_TRANSPORTATION_TYPE]: (id) => {
      return (new CrudService('common/dictionaries/transportation-types', this.crudActions('common/dictionaries/transportation-types'))).get(id);
    },
  };

  getSelectOptions = {
    [FILTER_EDITORS.SELECT_SEA_LINE]: (params) => {
      return DictionaryService.getSeaLines({ ...params, type: 'COMMON' });
    },
    [FILTER_EDITORS.SELECT_CONSOLIDATOR]: (params) => {
      return DictionaryService.getSeaLines({ ...params, type: 'CONSOLIDATOR' });
    },
    [FILTER_EDITORS.SELECT_LINE_CONSOLIDATOR]: (params) => {
      return DictionaryService.getSeaLines(params);
    },
    [FILTER_EDITORS.SELECT_PORT]: (params) => {
      return DictionaryService.getSeaPortHubs(params);
    },
    [FILTER_EDITORS.SELECT_AIR_PORT]: (params) => {
      return DictionaryService.getAirHubs(params);
    },
    [FILTER_EDITORS.SELECT_AIR_PORT_NO_TERMINAL]: (params) => {
      return DictionaryService.getAirHubsWithNoTerminals(params);
    },
    [FILTER_EDITORS.SELECT_HUB]: (params) => {
      return DictionaryService.getAllHubsWithChina(params);
    },
    [FILTER_EDITORS.SELECT_RAILWAY]: (params) => {
      return DictionaryService.getRailwayHubs(params);
    },
    [FILTER_EDITORS.SELECT_COUNTRY]: (params) => {
      return DictionaryService.getCountries(params);
    },
    [FILTER_EDITORS.SELECT_CONTAINER_TYPE]: () => {
      return DictionaryService.getContainerTypes();
    },
    [FILTER_EDITORS.SELECT_CURRENCY_CODE]: () => {
      return DictionaryService.getCurrencies();
    },
    [FILTER_EDITORS.SELECT_SERVICE_CATEGORY]: (params) => {
      return DictionaryService.getAllPortServiceCategories(params);
    },
    [FILTER_EDITORS.SELECT_SERVICE_PROFILE]: (params) => {
      return DictionaryService.getAllServiceProfiles(params);
    },
    [FILTER_EDITORS.SELECT_ORGANIZATION_TARIFFS]: (params) => {
      return OrganizationService.getOrganizations(params)
          .then((data) => {
            const options = get(data, 'data.items', []);
            const user = UserStore.user;
            let id = null;

            if (user) {
              id = user.organization.id;
            }

            return options.map((option) => {
              if (option.id === id) {
                const cloneOption = cloneDeep(option);
                cloneOption.name = translate('crud.field.organization.name.title.my');
                return cloneOption;
              }
              return option;
            });
          });
    },
    [FILTER_EDITORS.SELECT_ORGANIZATION_CLIENT]: (params) => {
      return OrganizationService.getOrganizationsClients(userStore.user.organization.id)
          .then((data) => {
            const options = get(data, 'data', []);
            const user = UserStore.user;
            let id = null;

            if (user) {
              id = user.organization.id;
            }

            return options.map((option) => {
              if (option.id === id) {
                const cloneOption = cloneDeep(option);
                cloneOption.name = translate('crud.field.organization.name.title.my');
                return cloneOption;
              }
              return option;
            });
          });
    },
    [FILTER_EDITORS.SELECT_ORGANIZATION]: () => {
      return OrganizationService.getOrganizationsAll()
          .then(options => get(options, 'data', []));
    },
    [FILTER_EDITORS.SELECT_REGIONS]: (params) => {
      return DictionaryService.getAllRegions(params);
    },
    [FILTER_EDITORS.SELECT_CITIES]: (params) => {
      return DictionaryService.getAllCities(params);
    },
    [FILTER_EDITORS.SELECT_EVENT_TYPE]: (params) => {
      return DictionaryService.getAuditEventTypes(params);
    },
    [FILTER_EDITORS.SELECT_OBJECT_TYPE]: (params) => {
      return DictionaryService.getAuditObjectTypes(params);
    },
    [FILTER_EDITORS.SELECT_USER]: (params) => {
      return DictionaryService.getAllUsersFilter(params);
    },
    [FILTER_EDITORS.SELECT_TRANSPORTATION_TYPE]: (params) => {
      return DictionaryService.getTransportationTypes(params);
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const showOnlyAdminIndex = findIndex(nextProps.fields, field => field.isShow === true);
    let cloneFields = null;

    if (showOnlyAdminIndex !== -1) {
      cloneFields = cloneDeep(nextProps.fields);
    }
    if (showOnlyAdminIndex === -1) {
      cloneFields = _filter(nextProps.fields, field => field.isShow !== false);
    }

    this.setState({
      fields: cloneFields.map((field) => {
        let label = '';
        let labels = [];

        if (field.label) {
          label = translate(field.label);
        }

        if (field.labels) {
          labels = field.labels.map(unary(translate));
        }

        return { ...field, label, labels };
      }),
    });

    if (isEmpty(nextProps.currentFilter)) {
      return;
    }

    this.state.fields.forEach((fieldInfo) => {
      const getSelectOptions = this.getSelectOptions[fieldInfo.editor];

      if (!getSelectOptions) {
        return;
      }

      getSelectOptions()
          .then((options) => {
            if (fieldInfo.editor === FILTER_EDITORS.SELECT_ORGANIZATION_TARIFFS) {
              this.setState({
                ...this.state.selectOptions,
                [fieldInfo.name]: options,
              });
            } else if (fieldInfo.editor === FILTER_EDITORS.SELECT_ORGANIZATION_CLIENT) {
              this.setState({
                ...this.state.selectOptions,
                [fieldInfo.name]: options,
              });
            } else if (fieldInfo.editor === FILTER_EDITORS.SELECT_USER) {
              this.setState({
                ...this.state.selectOptions,
                [fieldInfo.name]: options,
              });
            } else if (this.state.selectOptions[fieldInfo.name]) {
              // для совмещения массивов из первой сотни и уже выбранного элемента
              const formattedOptions = fieldInfo.editor === FILTER_EDITORS.SELECT_AIR_PORT ? addFormatNameForAirsWithCity(options) : addFormatName(options);
              const prepareData = uniqBy([
                ...this.state.selectOptions[fieldInfo.name],
                ...formattedOptions,
              ], 'id');

              this.setState({
                selectOptions: {
                  ...this.state.selectOptions,
                  [fieldInfo.name]: prepareData,
                },
              });
            }
            // Подзагрузка элемента, если он не в первой сотне
            const getOptionById = this.getSelectOptionById[getSelectOptions.id];

            if (!getOptionById) {
              return;
            }

            Promise.all(options.map(unary(getOptionById)))
                .then((responses) => {
                  const successOptions = map(
                      _filter(responses, { status: 'success' }),
                      'data'
                  );

                  let clone = [];
                  clone = cloneDeep(this.state.selectOptions[fieldInfo.editor]);

                  if (!clone) {
                    clone = [];
                  }

                  const formattedOptions = fieldInfo.editor === FILTER_EDITORS.SELECT_AIR_PORT ? addFormatNameForAirsWithCity(successOptions) : addFormatName(successOptions);
                  formattedOptions.forEach(el => clone.push(el));

                  const used = {};
                  const prepareClone = clone.filter((obj) => {
                    if (obj.id in used) {
                      return 0;
                    }
                    used[obj.id] = 1;

                    return used[obj.id];
                  });

                  this.setState({
                    selectOptions: {
                      ...this.state.selectOptions,
                      [fieldInfo.editor]: prepareClone,
                    },
                  });
                });
          });

      // this.setState({
      //   selectOptions: {
      //     ...this.state.selectOptions,
      //     [fieldInfo.name]: [],
      //   },
      // });
    });

    this.setState({
      currentFilter: nextProps.currentFilter,
      filters: nextProps.filtersWithNames,
      isOpen: !!nextProps.currentFilter.collapsed,
    });

    nextProps.currentFilter.value.forEach((field) => {
      this.getOptionsDescription({
        optionsValues: field.values,
        fieldInfo: find(this.state.fields, { name: field.name }),
      });
    });
  }

  isLastFieldLeft = (filter) => {
    return get(filter, 'value.length') === 1;
  };

  sortFieldsByPropsFieldsOrder = (propsFields = [], addedFields = []) => {
    const sortedFields = [];

    if (isEmpty(propsFields) || isEmpty(addedFields)) {
      return sortedFields;
    }

    sortedFields.length = propsFields.length;

    addedFields.forEach((addedField) => {
      const fieldIndex = findIndex(propsFields, { name: addedField.name });
      sortedFields[fieldIndex] = addedField;
    });

    return compact(sortedFields);
  };

  renderFieldEditor = ({ filter, field, fieldInfo }) => {
    const {
      SELECT_SEA_LINE,
      SELECT_CONSOLIDATOR,
      SELECT_LINE_CONSOLIDATOR,
      SELECT_PORT,
      SELECT_AIR_PORT,
      SELECT_AIR_PORT_NO_TERMINAL,
      SELECT_HUB,
      SELECT_RAILWAY,
      SELECT_CONTAINER_TYPE,
      SELECT_SERVICE_CATEGORY,
      SELECT_SERVICE_PROFILE,
      SELECT_CURRENCY_CODE,
      SELECT_SURCHARGE_TYPE,
      SELECT_TARIFICATION,
      SELECT_NDS_VALUE,
      SELECT_ORDER_STATUS,
      SELECT_ORGANIZATION_PROFILE_STATUS,
      SELECT_FLAG,
      SELECT_FLAG_REVERSE,
      DATE_RANGE_EDITOR,
      DATE,
      SELECT_COUNTRY,
      SELECT_ORGANIZATION,
      SELECT_ORGANIZATION_TARIFFS,
      SELECT_ORGANIZATION_CLIENT,
      SELECT_REGIONS,
      SELECT_CITIES,
      SELECT_MODIFIER,
      SELECT_CHARGING,
      SELECT_EVENT_TYPE,
      SELECT_OBJECT_TYPE,
      SELECT_USER,
      SELECT_TRANSPORTATION_TYPE,
      SELECT_INSURANCE_TRANSPORTATION,
      SELECT_STATUS_ACT,
    } = FILTER_EDITORS;

    const multiSelectEditors = [
      SELECT_SEA_LINE,
      SELECT_CONSOLIDATOR,
      SELECT_LINE_CONSOLIDATOR,
      SELECT_PORT,
      SELECT_AIR_PORT,
      SELECT_AIR_PORT_NO_TERMINAL,
      SELECT_HUB,
      SELECT_RAILWAY,
      SELECT_CONTAINER_TYPE,
      SELECT_CURRENCY_CODE,
      SELECT_SERVICE_CATEGORY,
      SELECT_SERVICE_PROFILE,
      SELECT_COUNTRY,
      SELECT_ORGANIZATION,
      SELECT_ORGANIZATION_TARIFFS,
      SELECT_ORGANIZATION_CLIENT,
      SELECT_REGIONS,
      SELECT_CITIES,
      SELECT_EVENT_TYPE,
      SELECT_OBJECT_TYPE,
      SELECT_USER,
      SELECT_TRANSPORTATION_TYPE,
      SELECT_ORDER_STATUS,
    ];

    const { editor, searchOnServer = true } = fieldInfo;
    const selectedValue = field.values.join('');
    let selectedOptions = [];
    let options = [];
    let filterOption = (option, inputValue) => {
      if (option.name && typeof option.name === 'string') {
        return option.name.toLowerCase().includes(inputValue);
      }
      return true;
    };
    let searchable = true;
    let async = false;
    let multi = false;
    let labelKey = 'formatName';
    let valueKey = 'code';

    const labelsForRange = ['crud.filter.from', 'crud.filter.to']
        .map(unary(translate));

    if (multiSelectEditors.includes(editor)) {
      multi = true;
      async = true;
      valueKey = 'id';
      const values = this.state.selectOptions[field.name];
      options = values ? addFormatName(values) : values;

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }

    if (editor === SELECT_CURRENCY_CODE) {
      multi = true;
      valueKey = 'code';
      labelKey = valueKey;
      searchable = false;
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.code));
      });
    }

    if (editor === SELECT_COUNTRY) {
      multi = true;
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }

    if (editor === SELECT_ORGANIZATION) {
      multi = true;
      labelKey = 'name';
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }

    if (editor === SELECT_ORGANIZATION_TARIFFS) {
      multi = true;
      labelKey = 'name';
      valueKey = 'id';
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }

    if (editor === SELECT_ORGANIZATION_CLIENT) {
      multi = true;
      labelKey = 'name';
      valueKey = 'id';
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }


    if (!searchOnServer) {
      async = false;
      filterOption = null;
    }

    if (editor === SELECT_CONTAINER_TYPE) {
      multi = true;
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (optionValue) => {
        return field.values.includes(String(optionValue.id));
      });
    }

    if (editor === SELECT_SURCHARGE_TYPE) {
      options = addFormatNameToConstant(SURCHARGE_TYPES);
      valueKey = 'type';
    }

    if (editor === SELECT_TARIFICATION) {
      options = addFormatNameToConstant(TARIFICATIONS);
      valueKey = 'tarificationType';
    }

    if (editor === SELECT_MODIFIER) {
      options = addFormatNameToConstant(MODIFIER);
      valueKey = 'id';
    }

    if (editor === SELECT_CHARGING) {
      multi = true;
      valueKey = 'charging';
      options = addFormatNameToConstant(CHARGING);

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.charging));
      });
    }

    if (editor === SELECT_INSURANCE_TRANSPORTATION) {
      options = addFormatNameToConstant(INSURANCE_TRANSPORTATION);
      valueKey = 'id';
    }

    if (editor === SELECT_NDS_VALUE) {
      options = addFormatNameToConstant(NDS).map(nds => Object.assign({
        ...nds,
        code: String(nds.value),
      }));
    }

    if (editor === SELECT_STATUS_ACT) {
      let formatOptions =[
        {
          code:"PAID",
          title:"constants.PAID"
        },
        {
          code:"NOT_PAID",
          title:"constants.NOT_PAID"
        }
      ];
      for(let opt of formatOptions){
        set(opt, "formatName", translate(opt.title))
      }
      options = map(formatOptions, opt=>({
        code: opt.code,
        formatName: translate(opt.title)
      }))
      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.code));
      });
      console.log("IS SELECT:",editor.includes('SELECT'))
    }

    if (editor === SELECT_ORDER_STATUS) {
      searchable = false;
      async = false;
      valueKey = 'code';

      options = Array.from(ORDER_DELIVERY_STATUS_FILTER.values()).map(s => ({
        code: s.key,
        formatName: translate(s.title),
      }));

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.code));
      });
    }

    if (editor === SELECT_ORGANIZATION_PROFILE_STATUS) {
      searchable = false;

      options = map(ORGANIZATIONS_PROFILES_STATUSES, status => ({
        code: status,
        formatName: translate(`organizationsProfiles.status.${status}`),
      }));
    }

    if (editor === SELECT_FLAG) {
      searchable = false;

      options = [{
        formatName: fieldInfo.labels[0] || translate('crud.label.yes'),
        code: 'true',
      }, {
        formatName: fieldInfo.labels[1] || translate('crud.label.no'),
        code: 'false',
      }];
    }

    if (editor === SELECT_FLAG_REVERSE) {
      searchable = false;

      options = [{
        formatName: fieldInfo.labels[0] || translate('crud.label.yes'),
        code: 'false',
      }, {
        formatName: fieldInfo.labels[1] || translate('crud.label.no'),
        code: 'true',
      }];
    }

    if (editor === SELECT_EVENT_TYPE || editor === SELECT_OBJECT_TYPE) {
      multi = true;
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }

    if (editor === SELECT_USER) {
      multi = true;
      labelKey = 'email';
      options = this.state.selectOptions[field.name];

      selectedOptions = _filter(options, (selectValue) => {
        return field.values.includes(String(selectValue.id));
      });
    }

    if (editor && editor.includes('SELECT')) {
      return (
          <Select
              async={async}
              searchable={searchable}
              clearable={!multi}
              multi={multi}
              value={multi ? selectedOptions : selectedValue}
              values={options}
              valueKey={valueKey}
              labelKey={labelKey}
              className="crud-select"
              optionRenderer={editor === SELECT_HUB ? this.getOptionWithIcon : undefined}
              valueRenderer={editor === SELECT_HUB ? this.getOptionWithIcon : undefined}
              filterOption={filterOption}
              loadOptions={(searchText) => {
                if (isEmpty(editor)) {
                  return Promise.resolve();
                }

                const getSelectOptionsBy = get(this.getSelectOptions, editor);

                if (!getSelectOptionsBy) {
                  return Promise.resolve();
                }

                if (editor === FILTER_EDITORS.SELECT_ORGANIZATION_TARIFFS) {
                  return getSelectOptionsBy(searchText ? { name: searchText } : {})
                      .then(fetchedOptions => ({
                        options: fetchedOptions,
                      }));
                }

                if (editor === FILTER_EDITORS.SELECT_ORGANIZATION_CLIENT) {
                  return getSelectOptionsBy(searchText ? { name: searchText } : {})
                      .then(fetchedOptions => ({
                        options: fetchedOptions,
                      }));
                }
                return getSelectOptionsBy(searchText ? { name: searchText } : {})
                    .then(fetchedOptions => ({
                      options: fieldInfo.editor === FILTER_EDITORS.SELECT_AIR_PORT ? addFormatNameForAirsWithCity(toJS(fetchedOptions)) : addFormatName(toJS(fetchedOptions)),
                    }));
              }}
              onChange={(option) => {
                let clone = [];
                clone = cloneDeep(this.state.selectOptions[field.name]);

                if (!clone) {
                  clone = [];
                }

                if (multi) {
                  option.forEach(el => clone.push(el));

                  const used = {};

                  const prepareClone = clone.filter(obj => obj).filter((obj) => {
                    if (obj.id in used) {
                      return 0;
                    }
                    used[obj.id] = 1;

                    return used[obj.id];
                  });
                  this.setState({
                    ...this.state,
                    selectOptions: {
                      ...this.state.selectOptions,
                      [field.name]: prepareClone,
                    },
                  });
                }

                let optionsValues = [];

                if (multi) {
                  optionsValues = option.map(selected => String(selected[valueKey]));

                  this.getOptionsDescription({
                    optionsValues,
                    fieldInfo,
                  });
                } else {
                  optionsValues = option ? [option[valueKey]] : [];
                }
                this.updateField(filter, {
                  ...field,
                  values: optionsValues,
                }, false);
              }}
          />
      );
    }

    if (fieldInfo.editor === DATE) {
      return (
          <DateInput
              value={selectedValue}
              dateFormat={DATE_FORMAT[AppStore.userLang]}
              /*icon={<i className="fa fa-calendar" aria-hidden="true" />}*/
              onChange={(newDate) => {
                this.updateField(filter, {
                  ...field,
                  values: [newDate],
                }, false);
              }}
          />
      );
    }

    if (editor === DATE_RANGE_EDITOR) {
      const [dateFrom = '', dateTo = ''] = field.values;

      return (
          <div>
            <Row>
              <Col xs={12} sm={6} className="mb-sm-0 mb-2">
                <div className="d-flex align-content-center">
                  <Label className="mt-1 mr-2 d-flex align-items-center">
                    {labelsForRange[0]}
                  </Label>
                  <DateInput
                      className="fullWidth"
                      value={dateFrom}
                      dateFormat={DATE_FORMAT[AppStore.userLang]}
                      /*icon={<i className="fa fa-calendar" aria-hidden="true" />}*/
                      onChange={(newDateFrom) => {
                        this.updateField(filter, {
                          ...field,
                          values: [newDateFrom, dateTo],
                        }, false);
                      }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="d-flex align-content-center">
                  <Label className="mt-1 mr-2 d-flex align-items-center">
                    {labelsForRange[1]}
                  </Label>
                  <DateInput
                      className="fullWidth"
                      value={dateTo}
                      dateFormat={DATE_FORMAT[AppStore.userLang]}
                      /*icon={<i className="fa fa-calendar" aria-hidden="true" />}*/
                      onChange={(newDateTo) => {
                        this.updateField(filter, {
                          ...field,
                          values: [dateFrom, newDateTo],
                        }, false);
                      }}
                  />
                </div>
              </Col>
            </Row>
          </div>
      );
    }

    if (fieldInfo.type === FILTER_FIELD_TYPES.INT_RANGE) {
      const [from = 0, to = 0] = field.values;

      return (
          <Row>
            <Col xs={12} sm={6} className="mb-sm-0 mb-2">
              <div className="d-flex align-content-center">
                <Label className="mt-1 mr-2 d-flex align-items-center">
                  {labelsForRange[0]}
                </Label>
                <Input
                    type="number"
                    className="simple-input"
                    value={from}
                    onKeyUp={() => this.getUpdateFilterClick(this.state.currentFilter)}
                    onChange={(newFrom) => {
                      this.updateField(filter, {
                        ...field,
                        values: [String(newFrom.target.value), to],
                      }, true);
                    }}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="d-flex align-content-center">
                <Label className="mr-2 mt-1 d-flex align-items-center">
                  {labelsForRange[1]}
                </Label>
                <Input
                    type="number"
                    className="simple-input"
                    value={to}
                    onKeyUp={() => this.getUpdateFilterClick(this.state.currentFilter)}
                    onChange={(newTo) => {
                      this.updateField(filter, {
                        ...field,
                        values: [from, String(newTo.target.value)],
                      }, true);
                    }}
                />
              </div>
            </Col>
          </Row>
      );
    }

    return (
        <Input
            type="text"
            className="simple-input"
            value={selectedValue}
            placeholder={fieldInfo.label}
            onKeyUp={() => this.getUpdateFilterClick(this.state.currentFilter)}
            onChange={(event) => {
              this.updateField(filter, {
                ...field,
                values: [event.target.value],
              }, true);
            }}
        />
    );
  };

  getOptionWithIcon(option) {
    const typeToIcon = {
      SEA_PORT: 'anchor',
      TRANSPORT: 'truck',
      AIRPORT_HUB: 'plane',
      RAILWAY_HUB: 'train',
    };

    return (
        <div>
          <FontAwesome name={get(typeToIcon, option.type, '')} style={{ paddingRight: '10px' }} />
          {option.formatName}
        </div>
    );
  }

  getOptionsDescription({ optionsValues, fieldInfo = {} }) {
    const { editor, name, searchOnServer = true } = fieldInfo;
    const getOptionById = this.getSelectOptionById[editor];

    if (!getOptionById || !searchOnServer) {
      return;
    }

    Promise.all(optionsValues.map(unary(getOptionById)))
        .then((responses) => {
          const successOptions = map(
              _filter(responses, { status: 'success' }),
              'data'
          );

          let clone = [];
          clone = cloneDeep(this.state.selectOptions[name]);

          if (!clone) {
            clone = [];
          }

          if (editor === FILTER_EDITORS.SELECT_ORGANIZATION_TARIFFS || editor === FILTER_EDITORS.SELECT_ORGANIZATION_CLIENT) {
            const user = UserStore.user;
            let id = null;

            if (user) {
              id = user.organization.id;
            }

            successOptions.forEach((el) => {
              if (id === el.id) {
                const copy = cloneDeep(el);
                copy.name = translate('crud.field.organization.name.title.my');
                clone.push(copy);
              } else {
                clone.push(el);
              }
            });
          } else {
            const formattedOptions = fieldInfo.editor === FILTER_EDITORS.SELECT_AIR_PORT ? addFormatNameForAirsWithCity(successOptions) : addFormatName(successOptions);
            formattedOptions.forEach(el => clone.push(el));
          }

          const used = {};
          const prepareClone = clone.filter((obj) => {
            if (obj.id in used) {
              return 0;
            }
            used[obj.id] = 1;

            return used[obj.id];
          });

          this.setState({
            ...this.state,
            selectOptions: {
              ...this.state.selectOptions,
              [name]: prepareClone,
            },
          });
        });
  }

  toggleFilter(filter) {
    if (!filter) {
      return;
    }

    this.setState({ isOpen: !this.state.isOpen });
    let updFilter = Object.assign({}, {
      ...filter,
      collapsed: !filter.collapsed,
    });

    this.updateFilter(updFilter);
  }

  toggleAvailableFilterFields() {
    this.setState({ isOpenAvailableFields: !this.state.isOpenAvailableFields });
  }

  toggleField(filterToUpdate, field, isChecked) {
    if (isChecked) {
      this.deleteField(filterToUpdate, field);
    } else if (filterToUpdate.id) {
      this.createField(filterToUpdate, field);
    } else {
      this.createFilter(Object.assign({}, this.emptyFilterModel, {
        value: [{
          name: field.name,
          type: field.type,
          values: [],
          disabled: false,
        }],
      }));
    }
  }

  createField(filter, field) {
    let updFilter = {
      ...filter,
      value: [].concat(toJS(filter.value), {
        name: field.name,
        type: field.type,
        values: [],
        disabled: false,
      }),
    }

    this.updateFilter(updFilter);
  }

  updateField(filter, updatedField, isTextInput) {
    let updFilter = Object.assign({}, filter, {
      value: filter.value.map((field) => {
        return field.name !== updatedField.name ? field : updatedField;
      }),
    });

    this.updateFilter(updFilter, isTextInput);
  }

  deleteField(filterToUpdate, field) {
    const updatedFilter = Object.assign({}, filterToUpdate, {
      value: filterToUpdate.value.filter((filteredField) => {
        return filteredField.name !== field.name;
      }),
    });

    const isLastField = this.isLastFieldLeft(filterToUpdate);

    if (isLastField) {
      this.deleteFilter(updatedFilter);
      return;
    }

    this.updateFilter(updatedFilter);

  }

  async createFilter(newFilter) {
    const createdFilter = await this.store.create(newFilter);

    this.setState({
      isOpen: true,
      errors: this.store.errors,
      currentFilter: {
        ...toJS(createdFilter),
        formatName: translate('crud.filter.userFilter'),
      },
      filters: [].concat(this.state.filters, toJS(createdFilter)),
    });

    this.props.onChange(createdFilter);
  }

  async updateFilter(updatedFilter, isUpdateField = false) {
    const { id } = updatedFilter;

    if (!id) {
      return;
    }

    if (!isUpdateField) {
      await this.store.update(id, updatedFilter);
      this.props.onChange(updatedFilter);
    }

    this.setState({
      errors: this.store.errors,
      currentFilter: updatedFilter,
      filters: this.state.filters.map((filter) => {
        return filter.id === updatedFilter.id ? updatedFilter : filter;
      }),
    });
  }

  getUpdateFilterClick = debounce(async (updatedFilter) => {
    const { id } = updatedFilter;
    if (!id) {
      return;
    }

    await this.store.update(id, updatedFilter);
    /*this.props.onChange(updatedFilter);*/
  }, 750)

  deleteFilter(filterToDelete = {}) {
    confirm(translate('crud.filter.deleteFilterConfirm'), async () => {
      if (!filterToDelete.id) {
        return;
      }

      await this.store.remove(filterToDelete.id);

      this.setState({
        isOpenAvailableFields: false,
        currentFilter: {},
        filters: this.state.filters.filter((filter) => {
          return filter.id !== filterToDelete.id;
        }),
      });

      this.props.onDelete();
    });
  }

  renderFilterFields(filter) {
    const sortedFields = this.sortFieldsByPropsFieldsOrder(
        this.props.fields, filter.value
    );

    return (
        <div className="filter__list">
          {sortedFields.map((field) => {
            const fieldInfo = find(this.state.fields, {
              name: field.name,
            }) || {};

            return (
                <div key={field.name} className="filter__item px-2 py-1">
                  <div className="label">
                    {fieldInfo.label}
                  </div>
                  <div className="select">
                    {this.renderFieldEditor({ filter, field, fieldInfo })}
                  </div>
                  <div className="pl-2">
                    <Button
                        className="close"
                        onClick={() => this.deleteField(filter, field)}
                    >
                    </Button>
                  </div>
                </div>
            );
          })}
        </div>
    );
  }

  render() {
    // const filtersExists = find(this.state.filters, (f) => {
    //   return !isEmpty(f.name);
    // });

    const currentFilter = this.state.currentFilter;
    const targetId = this.state.targetId;

    return (
        <div className="filter mb-3 mt-3">
          {!currentFilter.readOnly ? (
              <div>
                <div className="buttons mb-2">
                  <Button
                      color="info"
                      disabled={!currentFilter.id}
                      onClick={() => this.toggleFilter(currentFilter)}
                  >
                    {translate('crud.filter.title')} {currentFilter.id ? (
                      <FontAwesome
                          className="ml-2"
                          name={`chevron-${this.state.isOpen ? 'up' : 'down'}`}
                      />
                  ) : ''}
                  </Button>
                  <Button
                      type="button"
                      color="info"
                      className="ml-2"
                      id={`availableFields${targetId}`}
                      /*onClick={this.toggleAvailableFilterFields}*/
                  >
                    <FontAwesome name="plus" />
                  </Button>
                  {currentFilter.id ? (
                      <Button
                          className="ml-2"
                          color="danger"
                          onClick={() => this.deleteFilter(currentFilter)}
                      >
                        <FontAwesome name="trash" />
                      </Button>
                  ) : null}
                  {document.getElementById(`availableFields${targetId}`) &&
                  <UncontrolledPopover
                      placement="right"
                      trigger="legacy"
                      /* isOpen={this.state.isOpenAvailableFields}*/
                      target={`availableFields${targetId}`}
                      /* toggle={this.toggleAvailableFilterFields}*/
                  >
                    <PopoverBody>
                      {this.state.fields.map((field) => {
                        const isChecked = !!find(get(currentFilter, 'value'), {
                          name: field.name,
                        });

                        return (
                            <Checkbox
                                key={field.name}
                                label={field.label}
                                checked={isChecked}
                                onChange={() => this.toggleField(
                                    currentFilter,
                                    field,
                                    isChecked
                                )}
                            />
                        );
                      })}
                    </PopoverBody>
                  </UncontrolledPopover>
                  }
                </div>
                <Row>
                  <Col xs={12} lg={8}>
                    <Collapse isOpen={this.state.isOpen}>
                      {currentFilter.id ? (
                          this.renderFilterFields(currentFilter)
                      ) : null}
                      <Error>
                        {this.state.errors.reduce((prevErrors, current) => {
                          return prevErrors + current.errorText;
                        }, '')}
                      </Error>
                      {this.state.currentFilter.id ?
                          <div>
                            <Button
                                type="button"
                                color="info"
                                className="ml-0 mt-2"
                                onClick={()=>{
                                  this.props.onChange(this.state.currentFilter, true);
                                }
                                }
                            >
                              {translate('crudForm.button.apply')}
                            </Button>
                          </div>
                          :null
                      }
                    </Collapse>
                  </Col>
                </Row>
              </div>
          ) : null}
          {/*{filtersExists ? (
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={5}>
              <Select
                clearable
                value={!isEmpty(currentFilter) ? currentFilter : null}
                values={this.state.filters}
                placeholder="crud.filter.selectFilter"
                valueKey="id"
                labelKey="formatName"
                className="crud-select"
                onChange={(selectedFilter) => {
                  const newSelectedFilter = selectedFilter || {};

                  this.setState({
                    currentFilter: newSelectedFilter,
                  });

                  if (newSelectedFilter.name === EXPIRED_TARIFFS_FILTER_NAME) {
                    addHashParamToUrl(newSelectedFilter.name);
                  } else {
                    deleteAllHashParamsFromUrl();
                  }

                  this.props.onChange(newSelectedFilter);
                }}
              />
            </Col>
          </Row>
        ) : null}*/}
        </div>
    );
  }
}

CrudFilters.defaultProps = {
  crudType: 'filter',
};

CrudFilters.propTypes = {
  context: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  crudType: PropTypes.string,
  currentFilter: PropTypes.object,
  filtersWithNames: PropTypes.array,
};
