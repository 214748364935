/* eslint-disable */
import React, {Fragment, useState, useRef, useEffect} from 'react';
import { Switcher } from '../ui/form-control';
import './orders.css';
import { getExistLangValue, getLangInStorage, translate } from '../../utils/utils';
import {Col, Label, Row} from "reactstrap";
import AppStore from "../../stores/appStore";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ORDER_DELIVERY_STATUS } from '../../constants/global';
import {get} from "lodash";

const INDICATOR_WARNING_DAYS = 0;
const INDICATOR_INVOICE_WARNING_DAYS = 0;

const OrderCardPanel = (props) => {
    const cssClasses = !!props.isRelative? 'order-card-panel relative-panel': 'order-card-panel';
    return (
        <div className={cssClasses}>
            {props.is_indicator &&
                <div>{props.indicator_block}</div>
            }
            <h2>{props.title || ''}</h2>
            {props.children}
        </div>
    );
};

const getCargotypeLogoCssClass = (typeId) => {
    switch (typeId) {
        case 1: return 'cargo-type-package-logo';
        case 2: return 'cargo-type-box-logo';
        case 3: return 'cargo-type-box-logo';
        default: return '';
    }
};

const getTranslateCargoType = (typeId)=>{
    switch (typeId) {
        case 1: return <FormattedMessage id={"deliveryOrders.cargoInformation.cargoTypeBox"}/>;
        case 2: return <FormattedMessage id={"deliveryOrders.cargoInformation.cargoTypePackage"}/>;
        case 3: return <FormattedMessage id={"deliveryOrders.cargoInformation.cargoTypeCube"}/>
        default: return '-';
    }
}

const getOrderMiniCardTransportTypeCssClass = (typeId) => {
    switch (typeId) {
        case 1: return 'order-m-card-logo order-m-card-vehicle';
        case 2: return 'order-m-card-logo order-m-card-air';
        case 3: return 'order-m-card-logo order-m-card-sea';
        case 4: return 'order-m-card-logo order-m-card-railway';
        default: return '';
    }
};

const useOutsideAlerter = (ref, act) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(act && typeof act === 'function'){
                    act();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

const translateActionName = (typeName) => {
    let key = null;
    switch (typeName) {
        case 'download':
            key = 'common.documents.downloadActionTitle';
            break;
        case 'send-email':
            key = 'common.documents.sendEmailActionTitle';
            break;
        case 'rename':
            key = 'common.documents.renameActionTitle';
            break;
        case 'remove':
            key = 'common.documents.removeActionTitle';
            break;
        case 'upload':
            key = 'common.documents.uploadActionTitle';
            break;
        case 'details':
            key = 'deliveryOrders.orderBlock.orderActions.details';
            break;
        case 'edit':
            key = 'deliveryOrders.orderBlock.orderActions.edit';
            break;
        case 'subscribe':
            key = 'deliveryOrders.orderBlock.orderActions.getNotifications';
            break;
        case 'editLogistic':
            key = 'deliveryOrders.orderBlock.orderActions.editLogistic';
            break;
        case 'editOwner':
            key = 'deliveryOrders.orderBlock.orderActions.changeOwner';
            break;
        case 'moveToCalculation':
            key = 'deliveryOrders.orderBlock.orderActions.moveToCalculation';
            break;
    }

    return key? translate({ id: key}): '-';
};

const getCargoTypeIdByCargoInfo = (cargoInfo) => {
    if (!!cargoInfo.containers) {
        return 2;
    }
    if (!!cargoInfo.vehicle) {
        return 3;
    }

    return 1;
};

const getFinishDateOfOrder = (item) => {
    let finishDate = null;
    if (!item.trackingInfo) {
        return;
    }
    if (item.trackingInfo.pointOfDestination) {
        if (item.trackingInfo.pointOfDestination.arrival.fact) {
            finishDate = item.trackingInfo.pointOfDestination.arrival.fact;
        } else {
            finishDate = item.trackingInfo.pointOfDestination.arrival.plan;
        }
    } else if (item.trackingInfo.pointOfArrival) {
        if (item.trackingInfo.pointOfArrival.arrival.fact) {
            finishDate = item.trackingInfo.pointOfArrival.arrival.fact;
        } else {
            finishDate = item.trackingInfo.pointOfArrival.arrival.plan;
        }
    }
    return finishDate;
};

const getStartDateOfOrder = (item) => {
    let startDate = null;
    if (!item.trackingInfo) {
        return;
    }
    if (item.trackingInfo.pointOfPickup) {
        if (item.trackingInfo.pointOfPickup.load.fact) {
            startDate = item.trackingInfo.pointOfPickup.load.fact;
        } else {
            startDate = item.trackingInfo.pointOfPickup.load.plan;
        }
    } else if (item.trackingInfo.pointOfOrigin) {
        if (item.trackingInfo.pointOfOrigin.arrival.fact) {
            startDate = item.trackingInfo.pointOfOrigin.arrival.fact;
        } else {
            startDate = item.trackingInfo.pointOfOrigin.arrival.plan;
        }
    }
    return startDate;
};

const getCargoSummary = (cargo, lengthLimit) => {
    const getCargoSummary4generals = () => {
        const cargoSummary = cargo.generals
            .map(item => item.typeId === 3 ?
                `${item.prettyVolume}`
                :
                `${item.amount} x (${item.length} X ${item.width} X ${item.height})`)
            .join(', ');

        const amount = cargo.generals.reduce((prev, item) => {return prev + item.amount}, 0);
        return {
            amount,
            data: cargoSummary,
        };
    };

    const getCargoSummary4containers = () => {
        const twentyContainers = cargo.containers.filter(item => item.type.name.en === "20'");
        const fourtyContainers = cargo.containers.filter(item => item.type.name.en === "40'");
        const fourtyHCContainers = cargo.containers.filter(item => item.type.name.en === "40'HC");
        const getContainerCount = (amount, type, isLast = false) => {
            if (amount === 0) {
                return '';
            }
            if (amount === 1) {
                return (!isLast) ? `${type}, ` : `${type}`;
            }
            return (!isLast) ? `${amount} x ${type}, ` : `${amount} x ${type}`;
        };

        const result = `${getContainerCount(twentyContainers.length, "20'", (fourtyHCContainers.length === 0 && fourtyContainers.length === 0))}${getContainerCount(fourtyContainers.length, "40'", (fourtyHCContainers.length === 0))}${getContainerCount(fourtyHCContainers.length, "40'HC", true)}`;
        return {
            data: result,
            amount: twentyContainers.length + fourtyContainers.length + fourtyHCContainers.length
        };
    };

    const trimSummery = (summary, maxLength) => {
        if(!(Number.isInteger(lengthLimit) && lengthLimit > 0) || summary.data.length < lengthLimit){
            return summary;
        }

        return {
            amount: summary.amount,
            data: `${summary.data.substring(0, maxLength)}...`
        };
    };

    if (cargo.generals) {
        const summary = getCargoSummary4generals();
        return trimSummery(summary, lengthLimit);
    }

    if (cargo.containers) {
        const summary = getCargoSummary4containers();
        return trimSummery(summary, lengthLimit);
    }

    if (cargo.vehicle) {
        return {
            data: cargo.vehicle.type.name[getLangInStorage()],
            amount: 1
        };
    }

    return {
        data: '-',
        amount: 0
    };
};

const getPlaceTypesByCode = (code) => {
    switch (code) {
        case 'AIRPORT_HUB':
            return getExistLangValue({en:"Airport", ru:"Аэропорт", zh:"机场"}) /*translate('constants.placeTypes.airport');*/
        case 'SEA_PORT':
            return getExistLangValue({en:"Sea port", ru:"Морской порт", zh:"海港"}) /*translate('constants.placeTypes.seaPort');*/
        case 'ADDRESS':
            return getExistLangValue({en:"Address", ru:"Адрес", zh:"地址"}) /*translate('constants.placeTypes.address');*/
        case 'RAILWAY_HUB':
            return getExistLangValue({en:"Railway hub", ru:"Ж/д станция", zh:"铁路枢纽"}) /*translate('constants.placeTypes.railwayHub');*/
        case 'TRANSPORT_HUB':
            return getExistLangValue({en:"Transport hub", ru:"Транспортный хаб", zh:"交通枢纽"}) /*translate('constants.placeTypes.transportHub');*/

        default:
            return code;
    }
};

const prepareOrderCardCargoInfoData = (cargoInfo) => {
    const getCargoParams = (data) => {
        if(!data){
            return;
        }

        const res = {
            generals: [],
            containers: [],
            vehicle: []
        };

        function extractGeneralItemsData() {
            if (data.generals && data.generals.length) {
                for (let item of data.generals) {

                    //Определяем тип сборного груза. У старых заказов typeId может быть 0,
                    // typeId:
                    //   1 - Коробки
                    //   2 - Паллеты
                    //   3 - Кубы
                    let typeId = get(item, "typeId", 0);
                    if (typeId === 0) {
                        typeId = get(item, "palletType.id", false) ? 2 : 1;
                    }

                    res.generals.push({
                        weightValue: item.weight,
                        dimensionsValue: `${item.length} X ${item.width} X ${item.height}`,
                        dimensionsUnit: item.sizeUnit.toString().toLowerCase(),
                        amount: item.amount,
                        weight: item.weight,
                        volume: item.prettyVolume,
                        pallet: !!item.palletType,
                        cargoVolumeUnit: item.prettyVolumeUnit,
                        placeAmount: data.generals.reduce((amount, packing) => {
                            const result = amount + packing.amount;
                            return result || 0;
                        }, 0),

                        weightUnit: item.weightUnit.toString().toLowerCase(),
                        typeId: typeId
                    });
                }
            }
        }

        function extractContainerItemsData() {
            if (data.containers) {
                for (let item of data.containers) {
                    const weightUnit = item.weightUnit.toString().toLowerCase();
                    const container = res.containers.find(
                        x => x.weightValue === item.weight &&
                            x.typeId === item.type.id &&
                            x.weightUnit === weightUnit
                    );

                    if (container) {
                        container.amount = container.amount + 1;
                    } else {
                        res.containers.push({
                            weightValue: item.weight,
                            weightUnit: weightUnit,
                            dimensionsValue: `${item.type.size.length} X ${item.type.size.width} X ${item.type.size.height}`,
                            dimensionsUnit: item.type.size.unit.toString().toLowerCase(),
                            typeName: item.type.description[AppStore.userLang],
                            amount: item.amount || 1,
                            typeId: item.type.id
                        });
                    }
                }
            }
        }

        function extractVehicleItemsData() {
            if (data.vehicle && data.vehicle.type.size) {
                res.vehicle.push({
                    dimensionsValue: `${data.vehicle.type.size.length} X ${data.vehicle.type.size.width} X ${data.vehicle.type.size.height}`,
                    maxWeight: data.vehicle.type.maxWeight.value,
                    weightUnit: data.vehicle.type.maxWeight.unit.toString().toLowerCase(),
                    typeName: data.vehicle.type.name[AppStore.userLang],
                    bodyType: translate({id: `report.vehicle.${data.vehicle.bodyType.toString().toLowerCase()}`}),
                    dimensionsUnit: data.vehicle.type.size.unit.toString().toLowerCase()
                });
            }
        }

        extractGeneralItemsData();
        extractContainerItemsData();
        extractVehicleItemsData();

        return res;
    };

    const commonCargoData = getCargoParams(cargoInfo);
    if (!commonCargoData) {
        return;
    }

    const res = {
        generals: [],
        containers: [],
        vehicle: []
    };

    for (let item of commonCargoData.generals) {
        const crnt = {
            cargoTypeId: 1,
            palletType: item.pallet,
            cargoAmount: item.amount,
            cargoDimensionsUnit: item.dimensionsUnit.toLowerCase(),
            cargoDimensionsValue: item.dimensionsValue,
            cargoWeightUnit: item.weightUnit.toLowerCase(),
            cargoWeight: item.weightValue,
            cargoVolumeUnit: item.cargoVolumeUnit.toLowerCase(),
            cargoVolumeDim: '3',
            cargoVolume: item.volume,
            placeAmountHeader: 'Мест',
            placeAmount: item.placeAmount,
            typeId: item.typeId,
        };

        if (AppStore.userLang !== 'en') {
            switch (crnt.cargoVolumeUnit) {
                case 'cm':
                    crnt.cargoVolumeUnit = 'см';
                    break;
                case 'm':
                case 'M':
                    crnt.cargoVolumeUnit = 'м';
                    break;
                case 'm3':
                    crnt.cargoVolumeUnit = 'м. куб.';
                    break;
                case 'ft3':
                    crnt.cargoVolumeUnit = 'фут куб.';
                    break;
            }

            switch (crnt.cargoDimensionsUnit) {
                case 'cm':
                    crnt.cargoDimensionsUnit = 'см';
                    break;
                case 'm':
                case 'M':
                    crnt.cargoDimensionsUnit = 'м';
                    break;
                case 'm3':
                    crnt.cargoDimensionsUnit = 'м. куб.';
                    break;
                case 'ft3':
                    crnt.cargoDimensionsUnit = 'фут куб.';
                    break;
            }

            switch (crnt.cargoWeightUnit) {
                case 'kg':
                    crnt.cargoWeightUnit = 'кг';
                    break;
            }
        }

        res.generals.push(crnt);
    }

    for (let item of commonCargoData.containers){
        const crnt = {
            weightValue: item.weightValue,
            weightUnit: item.weightUnit,
            dimensionsValue: item.dimensionsValue,
            dimensionsUnit: item.dimensionsUnit,
            typeName: item.typeName,
            amount: item.amount
        };

        if (AppStore.userLang !== 'en') {
            switch (crnt.dimensionsUnit) {
                case 'cm':
                    crnt.dimensionsUnit = 'см';
                    break;
                case 'm':
                    crnt.dimensionsUnit = 'м';
                    break;
            }

            switch (crnt.weightUnit) {
                case 'kg':
                    crnt.weightUnit = 'кг';
                    break;
            }
        }

        res.containers.push(crnt);
    }

    for (let item of commonCargoData.vehicle) {
        let crnt = {
            dimensionsValue: item.dimensionsValue,
            maxWeight: item.maxWeight,
            weightUnit: item.weightUnit,
            typeName: item.dimensionsValue,
            bodyType: item.bodyType,
            dimensionsUnit: item.dimensionsUnit
        };

        if (AppStore.userLang !== 'en') {
            switch (crnt.dimensionsUnit) {
                case 'cm':
                    crnt.dimensionsUnit = 'см';
                    break;
                case 'm':
                    crnt.dimensionsUnit = 'м';
                    break;
            }

            switch (crnt.weightUnit) {
                case 'kg':
                    crnt.weightUnit = 'кг';
                    break;
            }
        }

        res.vehicle.push(crnt);
    }

    return res;
};

const AgoraStatusActions = ({actions, itemId, statusId, statusName }) => {
    const [isOpen, setOpenStatus] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setOpenStatus(false));
    const onClick = actions && actions[0]? () => {setOpenStatus(!isOpen)}: () => {};
    return (
      <div className={'agora-actions-btn'}>
          <div className={`status-label status-label-${statusId.toString().toLowerCase()}`} onClick={onClick}>{statusName}</div>
          {isOpen && (<div className='agora-actions agora-status-action' ref={wrapperRef}>
              {actions.map((x, ind) => {
                    const item = <div key={ind}
                                      className="agora-status-action-item"
                                      onClick={() => {
                                          if(x.onClick && typeof x.onClick === 'function'){
                                              setOpenStatus(false);
                                              x.onClick(itemId);

                                          }
                                      }
                                      }>
                        <div className={`agora-action-status agora-action-status-${x.type}`}></div>
                        <div>
                            {x.title}
                        </div>
                    </div>;
                    return  item;
                }
              )}
          </div>)}
      </div>

    );
};


const AgoraDocActions = ({actions, itemId, callback}) => {
    const [isOpen, setOpenStatus] = useState(false);
    const [hoverClass, setHover] = useState(null);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setOpenStatus(false));
    const useToggle = actions.some(x => x.useToggle);
    const onClick = actions && actions[0]? () => {setOpenStatus(!isOpen)}: () => {};
    const actionText = actions && actions[0]? '***': '';
    const getHoverSelectedClass = (type) => {
        if (type === hoverClass) {
            return `agora-action-logo-hover-${hoverClass}`;
        }
    };

    return (
        <div className={'agora-actions-btn'}>
            <div className={'agora-actions-btn_icon'} onClick={onClick}></div>
            {isOpen && (<div className={useToggle? 'agora-actions with-toggle': 'agora-actions'} ref={wrapperRef}>
                {actions.map((x, ind) => {
                    const item = <div key={ind}
                                      onClick={() => {
                                          if(x.onClick && typeof x.onClick === 'function'){
                                              x.onClick(itemId, callback);
                                              setOpenStatus(false);
                                          }
                                      }
                                  }>
                        <div className={`agora-action-logo agora-action-logo-${x.type} ${getHoverSelectedClass(x.type)}`}></div>
                        <div onMouseOver={() => setHover(x.type)} onMouseLeave={() => setHover('')}>
                            {translateActionName(x.type)}
                        </div>
                        {x.useToggle && <div>
                            <Switcher
                                onChange={x.onChange}
                                checked={x.checked}
                                className="mr-3"
                            />
                        </div>}
                    </div>;

                    return x.type !== 'upload'? item: (
                        <Fragment>
                            <input
                                id={'load-file-private-another'}
                                name="file"
                                type="file"
                                className="d-none"
                                onChange={(e) => {
                                    if(x.onCreateDoc && typeof x.onCreateDoc === 'function'){
                                        x.onCreateDoc(e, x.fileKey || 'ANOTHER', '', x.formatName);
                                        setOpenStatus(false);
                                    }
                                }}
                            />
                            <Label
                                for={'load-file-private-another'}
                            >
                                {item}
                            </Label>
                        </Fragment>
                    );
                }
                )}
            </div>)}
        </div>

    );
};

const getOrderStatusNameById = (id) => {
    let key;
    switch (id) {
        case 1:
            key = 'deliveryOrders.orderList.status.bookingReceived';
            break;
        case 2:
            key = 'deliveryOrders.orderList.status.bookingConfirmed';
            break;
        case 3:
            key = 'deliveryOrders.orderList.status.pickedUp';
            break;
        case 4:
            key = 'deliveryOrders.orderList.status.onTheWay';
            break;
        case 5:
            key = 'deliveryOrders.orderList.status.delivered';
            break;
        case 6:
            key = 'deliveryOrders.orderList.status.standby';
            break;
    }

    if (key) {
        translate({id: key})
    }

    return !key? '': translate({id: key});
};

const getOrderStatusName = (id) => {
    return ORDER_DELIVERY_STATUS.has(id) ? translate({ id: ORDER_DELIVERY_STATUS.get(id).title }) : '' ;
};


const prepareMailInfoData  = (requestGroup) => {
    const mailInfoData = {
        customerEmail: requestGroup && requestGroup.orderUserInfo? requestGroup.orderUserInfo.email: '',
        planCost:{
            value:  requestGroup && requestGroup.totalCost? requestGroup.totalCost.value: '',
            currencyName: requestGroup && requestGroup.totalCost? requestGroup.totalCost.currency: ''
        },
        factCost:{
            value: requestGroup && requestGroup.manualCost? requestGroup.manualCost.value: 'N/A',
            currencyName: requestGroup && requestGroup.manualCost? requestGroup.manualCost.currency: ''
        },
        cargoPath: {
            fromName: requestGroup &&requestGroup.requestDetails && requestGroup.requestDetails.departurePlace?
                requestGroup.requestDetails.departurePlace.companyName: '-',
            toName: requestGroup &&requestGroup.requestDetails && requestGroup.requestDetails.arrivalPlace?
                requestGroup.requestDetails.arrivalPlace.companyName: '-',
        },
        remarks: requestGroup && requestGroup.requestDetails &&  requestGroup.requestDetails.remarks ?
            requestGroup.requestDetails.remarks : '',

        internalId: (requestGroup && requestGroup.requestDetails &&  requestGroup.requestDetails.internalId) ?
            requestGroup.requestDetails.internalId :
            requestGroup? requestGroup.internalId : '',

        commentText: requestGroup.manualCostDescription || '',
        cargoName: requestGroup && requestGroup.requestDetails &&  requestGroup.requestDetails.cargoDescription ?
            requestGroup.requestDetails.cargoDescription : '',
        shippingDatePlan: requestGroup && requestGroup.trackingInfo && requestGroup.trackingInfo.pointOfPickup &&
            requestGroup.trackingInfo.pointOfPickup.ready ? requestGroup.trackingInfo.pointOfPickup.ready.plan : '',
        shippingDateFact: requestGroup && requestGroup.trackingInfo && requestGroup.trackingInfo.pointOfPickup &&
            requestGroup.trackingInfo.pointOfPickup.ready ? requestGroup.trackingInfo.pointOfPickup.ready.fact : '',
    };

    return mailInfoData;
};

const prepareTrackingRoutItems = (legs, trackingInfo) => {
    if (!legs) {
        return [];
    }

    const getAchievedPointNumber = () => {
        const {
            pointOfPickup: {
                ready: {
                    fact: pointOfPickupReadyFactDate,
                    plan: pointOfPickupReadyPlanDate,
                } = {},
                departure: {
                    fact: pointOfPickupDepartureFactDate,
                    plan: pointOfPickupDeparturePlanDate,
                } = {},
                load: {
                    fact: pointOfPickupLoadFactDate,
                    plan: pointOfPickupLoadPlanDate,
                } = {},
            } = {},
            pointOfOrigin: {
                arrival: {
                    fact: pointOfOriginArrivalFactDate,
                    plan: pointOfOriginArrivalPlanDate,
                } = {},
                departure: {
                    fact: pointOfOriginDepartureFactDate,
                    plan: pointOfOriginDeparturePlanDate,
                } = {},
            } = {},
            pointOfArrival: {
                arrival: {
                    fact: pointOfArrivalArrivalFactDate,
                    plan: pointOfArrivalArrivalPlanDate,
                } = {},
                departure: {
                    fact: pointOfArrivalDepartureFactDate,
                    plan: pointOfArrivalDeparturePlanDate,
                } = {},
                receiptOfDocuments: {
                    fact: pointOfArrivalReceiptOfDocumentsFactDate,
                    plan: pointOfArrivalReceiptOfDocumentsPlanDate,
                } = {},
            } = {},
            pointOfDestination: {
                arrival: {
                    fact: pointOfDestinationFactDate,
                    plan: pointOfDestinationPlanDate,
                } = {},
            } = {},
            customsClearance: {
                arrival: {
                    fact: customsClearanceArrivalFactDate,
                    plan: customsClearanceArrivalPlanDate,
                } = {},
                departure: {
                    fact: customsClearanceDepartureFactDate,
                    plan: customsClearanceDeparturePlanDate,
                } = {},
            } = {},
        } = trackingInfo;

        const now = moment.now();
        // const ACHIEVED = moment(pointOfPickup.departure.plan) <= now;

        /*
        Это нормальная логика с фактическими датами, а ниже костыль, который просили прикрутить.

         */

        // if (pointOfDestinationFactDate) {
        //     return 5;
        // }
        //
        // if (customsClearanceArrivalFactDate || customsClearanceDepartureFactDate){
        //     return 4;
        // }
        //
        // if (pointOfArrivalArrivalFactDate || pointOfArrivalDepartureFactDate || pointOfArrivalReceiptOfDocumentsFactDate){
        //     return 3;
        // }
        //
        // if (pointOfOriginArrivalFactDate || pointOfOriginDepartureFactDate) {
        //     return 2;
        // }
        //
        // if (pointOfPickupReadyFactDate || pointOfPickupDepartureFactDate || pointOfPickupLoadFactDate) {
        //     return 1;
        // }


        if (pointOfDestinationFactDate && moment(pointOfDestinationFactDate).isBefore(now)) {
            return 5;
        }

        if ((customsClearanceArrivalFactDate && moment(customsClearanceArrivalFactDate).isBefore(now)) ||
            (customsClearanceDepartureFactDate && moment(customsClearanceDepartureFactDate).isBefore(now))){
            return 4;
        }

        if ((pointOfArrivalArrivalFactDate && moment(pointOfArrivalArrivalFactDate).isBefore(now)) ||
            (pointOfArrivalDepartureFactDate && moment(pointOfArrivalDepartureFactDate).isBefore(now)) ||
            (pointOfArrivalReceiptOfDocumentsFactDate && moment(pointOfArrivalReceiptOfDocumentsFactDate).isBefore(now))){
            return 3;
        }

        if ((pointOfOriginArrivalFactDate && moment(pointOfOriginArrivalFactDate).isBefore(now)) ||
            (pointOfOriginDepartureFactDate && moment(pointOfOriginDepartureFactDate).isBefore(now))) {
            return 2;
        }

        if ((pointOfPickupReadyFactDate && moment(pointOfPickupReadyFactDate).isBefore(now)) ||
            (pointOfPickupDepartureFactDate && moment(pointOfPickupDepartureFactDate).isBefore(now)) ||
            (pointOfPickupLoadFactDate && moment(pointOfPickupLoadFactDate).isBefore(now))) {
            return 1;
        }

        return 0;
    };

    const orderRouteItems = [];
    const achievedPointNumber = getAchievedPointNumber();

    if (trackingInfo && trackingInfo.pointOfPickup) {
        orderRouteItems.push({
            title: trackingInfo.pointOfPickup.name[AppStore.userLang],
            label: '',
            arrived: achievedPointNumber >= 1
        });
    }

    if (trackingInfo && trackingInfo.pointOfOrigin) {
        orderRouteItems.push({
            title: getPlaceTypesByCode(trackingInfo.pointOfOrigin.locationType),
            label: trackingInfo.pointOfOrigin.name[AppStore.userLang],
            arrived: achievedPointNumber >= 2
        });
    }

    if (trackingInfo && trackingInfo.pointOfArrival) {
        orderRouteItems.push({
            title: getPlaceTypesByCode(trackingInfo.pointOfArrival.locationType),
            label: trackingInfo.pointOfArrival.name[AppStore.userLang],
            arrived: achievedPointNumber >= 3
        });
    }

    if (trackingInfo && trackingInfo.pointOfDestination) {
        orderRouteItems.push({
            title: trackingInfo.pointOfDestination.name[AppStore.userLang],
            label:  '',
            arrived: achievedPointNumber >= 5
        });
    }

    return orderRouteItems;
};

const getTransportationTypeIconId = (typeName) => {
    switch (typeName) {
        case 'ADDRESS':
            return 1;
        case 'AIRPORT_HUB':
            return 2;
        case 'SEA_PORT':
            return 3;
        case 'RAILWAY_HUB':
            return 4;
    }

    return 0;
};

const prepareOrderAccordionItems = (legs, trackingInfo) => {
    if (!legs) {
        return [];
    }

    const getTransportationTypeId = (typeName) => {
        switch (typeName) {
            case 'ADDRESS':
                return 1;
            case 'AIRPORT_HUB':
                return 2;
            case 'SEA_PORT':
                return 3;
            case 'RAILWAY_HUB':
                return 5;
        }

        return 4;
    };

    const hasMultiCurrencyService = (services) => {
        if (services.length === 1){
            return false;
        }
        for (let i = 1; i < services.length; i++) {
            if (services[i].cost.currency !== services[0].cost.currency){
                return true;
            }
        }
        return false;
    };

    const mapPoints = (item, index) => {
        let itemCounter = 1;
        const points = [];

        // the 1st group
        if (trackingInfo && trackingInfo.pointOfPickup &&
            (
                trackingInfo.pointOfPickup.ready.enabled ||
                trackingInfo.pointOfPickup.load.enabled ||
                trackingInfo.pointOfPickup.departure.enabled
            )
        ){
            if (trackingInfo.pointOfPickup.ready.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.cargoReadyDate' }),
                    datePlan: trackingInfo.pointOfPickup.ready.plan,
                    dateFact: trackingInfo.pointOfPickup.ready.fact
                });
            }

            if (trackingInfo.pointOfPickup.load.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.loadingDate' }),
                    datePlan: trackingInfo.pointOfPickup.load.plan,
                    dateFact: trackingInfo.pointOfPickup.load.fact
                });
            }

            if (trackingInfo.pointOfPickup.departure.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.departureDate' }),
                    datePlan: trackingInfo.pointOfPickup.departure.plan,
                    dateFact: trackingInfo.pointOfPickup.departure.fact
                });
            }
        }

        // the 2nd group
        if (trackingInfo && trackingInfo.pointOfOrigin &&
            (trackingInfo.pointOfOrigin.arrival.enabled || pointOfOrigin.departure.enabled)
        ) {
            if (trackingInfo.pointOfOrigin.arrival.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.arrivalDate' }),
                    datePlan: trackingInfo.pointOfOrigin.arrival.plan,
                    dateFact: trackingInfo.pointOfOrigin.arrival.fact
                });
            }

            if (trackingInfo.pointOfOrigin.departure.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.departureDate' }),
                    datePlan: trackingInfo.pointOfOrigin.departure.plan,
                    dateFact: trackingInfo.pointOfOrigin.departure.fact
                });
            }
        }

        // the 3rd group
        if (trackingInfo && trackingInfo.pointOfArrival &&
            (
                trackingInfo.pointOfArrival.arrival.enabled ||
                trackingInfo.pointOfArrival.receiptOfDocuments.enabled ||
                trackingInfo.pointOfArrival.departure.enabled
            )
        ) {
            if (trackingInfo.pointOfArrival.arrival.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.arrivalDate' }),
                    datePlan: trackingInfo.pointOfArrival.arrival.plan,
                    dateFact: trackingInfo.pointOfArrival.arrival.fact
                });
            }

            if (trackingInfo.pointOfArrival.receiptOfDocuments.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.documentsDate' }),
                    datePlan: trackingInfo.pointOfArrival.receiptOfDocuments.plan,
                    dateFact: trackingInfo.pointOfArrival.receiptOfDocuments.fact
                });
            }

            if (trackingInfo.pointOfArrival.departure.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.export' }),
                    datePlan: trackingInfo.pointOfArrival.departure.plan,
                    dateFact: trackingInfo.pointOfArrival.departure.fact
                });
            }
        }

        // the 4th group
        if (trackingInfo && trackingInfo.customsClearance &&
            (trackingInfo.customsClearance.arrival.enabled || trackingInfo.customsClearance.departure.enabled)
        ) {
            if (trackingInfo.customsClearance.arrival.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.arrivalOrInnings' }),
                    datePlan: trackingInfo.customsClearance.arrival.plan,
                    dateFact: trackingInfo.customsClearance.arrival.fact
                });
            }

            if (trackingInfo.customsClearance.departure.enabled) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.departureOrIssueDate' }),
                    datePlan: trackingInfo.customsClearance.departure.plan,
                    dateFact: trackingInfo.customsClearance.departure.fact
                });
            }
        }

        return points;
    };

    const _mapPointsForPointOfDestination = () => {
        let itemCounter = 1;
        const points = [];

        if (trackingInfo && trackingInfo.pointOfDestination && trackingInfo.pointOfDestination.arrival.enabled) {
            if (trackingInfo.pointOfDestination.locationType) {
                points.push({
                    id: itemCounter++,
                    title: translate({ id: 'logistBlock.arrivalDate' }),
                    datePlan: trackingInfo.pointOfDestination.arrival.plan,
                    dateFact: trackingInfo.pointOfDestination.arrival.fact
                });
            }
        }

        return points;
    };

    const {
        pointOfPickup: {ready: {fact: pointOfPickupFactDate} = {}} = {},
        pointOfOrigin: {arrival: {fact: pointOfOriginFactDate} = {}} = {},
        pointOfArrival: {arrival: {fact: pointOfArrivalFactDate} = {}} = {},
        pointOfDestination: {
            arrival: {
                fact: pointOfDestinationFactDate,
                plan: pointOfDestinationPlanDate,
            } = {}
        } = {},
    } = trackingInfo;

    const result = [];
    /*
    Пока нет четкого понимания, как формировать элементы аккордиона, механизм использования плеч отложен.

     */

    // const result = legs.map((item, index) => {
    //     const firstChildLeg = item.legs && isArray(item.legs)? item.legs[0]: undefined;
    //     const {
    //         to: {
    //             country: {
    //                 name: toCountry
    //             } = {},
    //             hub: {
    //                 country: {
    //                     name: toCountryHab
    //                 } = {}
    //             } = {}
    //         } = {}
    //     } = item;
    //     const {
    //         from: {
    //             country: {
    //                 name: fromCountry
    //             } = {},
    //             hub: {
    //                 country: {
    //                     name: fromCountryHab
    //                 } = {}
    //             } = {}
    //         } = {}
    //     } = item;
    //     const toCountryNode = toCountryHab || toCountry;
    //     const fromCountryNode = fromCountryHab || fromCountry;
    //     const toCountryName = toCountryNode? toCountry[AppStore.userLang]: '';
    //     const fromCountryName = fromCountryNode? fromCountry[AppStore.userLang]: '';
    //
    //     const locationStr = fromCountryName && toCountryName && fromCountryName !== toCountryName?
    //         `(${fromCountryName} - ${toCountryName})`:
    //         `(${toCountryName || fromCountryName})`;
    //     let serviceName = '';
    //
    //     if (firstChildLeg && firstChildLeg.services && isArray(firstChildLeg.services)) {
    //         serviceName = firstChildLeg.services[0].service.name[AppStore.userLang];
    //     }
    //
    //     const commonData = item.legs.map((leg2levelItem) => {
    //         const type = get(leg2levelItem, 'serviceCategory.uid');
    //         const rateName = translate(
    //             { id: (type === 'air_transportation' || type === 'airport_service') ?
    //                     'detailsOfCalculation.fields.general.air' :
    //                     'detailsOfCalculation.fields.general' }
    //         );
    //
    //         const totalCurrency = hasMultiCurrencyService(leg2levelItem.services)?
    //             'USD': leg2levelItem.services[0].cost.currency;
    //
    //         return {
    //             items: leg2levelItem.services.map((s) => {
    //                 const serviceName = s.service && s.service.name? s.service.name[AppStore.userLang]: '-';
    //                 const {
    //                     generalCargoItem: {amount} = {},
    //                     conversionPercent: conversion,
    //                     cost: {currency, value: cost},
    //                     nds: {value: tax}
    //                 } = s;
    //
    //                 return {
    //                     serviceName,
    //                     rateName,
    //                     amount,
    //                     currency,
    //                     cost,
    //                     conversion,
    //                     tax,
    //                 };
    //             }),
    //             total: {
    //                 currencyName: totalCurrency,
    //                 price: leg2levelItem.services.reduce(
    //                     (prev, current) => { return  prev + current.multiCostAfterConversion[totalCurrency].value},
    //                     0)
    //             }
    //         };
    //     });
    //
    //     return {
    //         id: index + 1,
    //         transportationTypeId: getTransportationTypeId(index !== 0? item.to.type: item.from.type),
    //         title: `${serviceName} ${locationStr}`,
    //         datePlan: item.order.shippingDate,
    //         dateFact: null,
    //         isOpen: false,
    //         details:{
    //             steps: mapPoints(item, index),
    //             commonData: commonData
    //         },
    //         useStepPoint: true
    //     };
    // });

    // result.push({
    //     id: legs.length + 1,
    //     transportationTypeId: 4,
    //     title: `${AppStore.userLang === 'ru'? 'Груз доставлен': 'Cargo delivered '} (${legs[legs.length - 1]? legs[legs.length - 1].to.country.name[AppStore.userLang]: ''})`,
    //     datePlan: pointOfDestinationPlanDate,
    //     dateFact: pointOfDestinationFactDate,
    //     isOpen: false,
    //     details:{
    //         steps: _mapPointsForPointOfDestination()
    //     },
    //     useStepPoint: false
    // });

    let ACHIEVED = false;
    const now = moment.now();

    const mapPointsForPointOfPickup = (pointOfPickup) => {
        if (!pointOfPickup){
            return [];
        }

        const points = [];
        let idx = 1;

        if (pointOfPickup.departure.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfPickup.departure.fact;
                ACHIEVED = moment(pointOfPickup.departure.fact).isAfter(now);
            }

            points.push({
                id: `pop-${idx++}`,
                title: <FormattedMessage id="logistBlock.departureDate" />,
                datePlan: pointOfPickup.departure.plan,
                dateFact: pointOfPickup.departure.fact,
                achieved: ACHIEVED
            });
        }

        if (pointOfPickup.load.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfPickup.load.fact;
                ACHIEVED = moment(pointOfPickup.load.fact).isAfter(now);
            }

            points.push({
                id: `pop-${idx++}`,
                title: <FormattedMessage id="logistBlock.loadingDate" />,
                datePlan: pointOfPickup.load.plan,
                dateFact: pointOfPickup.load.fact,
                achieved: ACHIEVED
            });
        }

        if (pointOfPickup.ready.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfPickup.ready.fact;
                ACHIEVED = moment(pointOfPickup.ready.fact).isAfter(now);
            }

            points.push({
                id: `pop-${idx++}`,
                title: <FormattedMessage id="logistBlock.cargoReadyDate" />,
                datePlan: pointOfPickup.ready.plan,
                dateFact: pointOfPickup.ready.fact,
                achieved: ACHIEVED
            });
        }

        return points.reverse();
    };

    const mapPointsForPointOfOrigin = (pointOfOrigin) => {
        if (!pointOfOrigin){
            return [];
        }

        const points = [];
        let idx = 1;

        if (pointOfOrigin.departure.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfOrigin.departure.fact;
                ACHIEVED = moment(pointOfOrigin.departure.fact).isAfter(now);
            }

            points.push({
                id: `poo-${idx++}`,
                title: <FormattedMessage id="logistBlock.departureDate" />,
                datePlan: pointOfOrigin.departure.plan,
                dateFact: pointOfOrigin.departure.fact,
                achieved: ACHIEVED
            });
        }

        if (pointOfOrigin.arrival.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfOrigin.arrival.fact;
                ACHIEVED = moment(pointOfOrigin.arrival.fact).isAfter(now);
            }

            points.push({
                id: `poo-${idx++}`,
                title: <FormattedMessage id="logistBlock.arrivalDate" />,
                datePlan: pointOfOrigin.arrival.plan,
                dateFact: pointOfOrigin.arrival.fact,
                achieved: ACHIEVED
            });
        }

        return points.reverse();
    };

    const mapPointsForPointOfArrival = (pointOfArrival) => {
        if (!pointOfArrival){
            return [];
        }

        const points = [];
        let idx = 1;

        if (pointOfArrival.departure.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfArrival.departure.fact;
                ACHIEVED = moment(pointOfArrival.departure.fact).isAfter(now);
            }

            points.push({
                id: `poa-${idx++}`,
                title: <FormattedMessage id="logistBlock.export" />,
                datePlan: pointOfArrival.departure.plan,
                dateFact: pointOfArrival.departure.fact,
                achieved: ACHIEVED
            });
        }

        if (pointOfArrival.receiptOfDocuments.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfArrival.receiptOfDocuments.fact;
                ACHIEVED = moment(pointOfArrival.receiptOfDocuments.fact).isAfter(now);
            }

            points.push({
                id: `poa-${idx++}`,
                title: <FormattedMessage id="logistBlock.documentsDate" />,
                datePlan: pointOfArrival.receiptOfDocuments.plan,
                dateFact: pointOfArrival.receiptOfDocuments.fact,
                achieved: ACHIEVED
            });
        }

        if (pointOfArrival.arrival.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!pointOfArrival.arrival.fact;
                ACHIEVED = moment(pointOfArrival.arrival.fact).isAfter(now);
            }

            points.push({
                id: `poa-${idx++}`,
                title: <FormattedMessage id="logistBlock.arrivalDate" />,
                datePlan: pointOfArrival.arrival.plan,
                dateFact: pointOfArrival.arrival.fact,
                achieved: ACHIEVED
            });
        }

        return points.reverse();
    };

    const mapPointsForCustomsClearance = (customsClearance) => {
        if (!customsClearance){
            return [];
        }

        const points = [];
        let idx = 1;


        if (trackingInfo.customsClearance.departure.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!trackingInfo.customsClearance.departure.fact;
                ACHIEVED = moment(trackingInfo.customsClearance.departure.fact).isAfter(now);
            }

            points.push({
                id: `poa-${idx++}`,
                title: <FormattedMessage id="logistBlock.departureOrIssueDate" />,
                datePlan: trackingInfo.customsClearance.departure.plan,
                dateFact: trackingInfo.customsClearance.departure.fact,
                achieved: ACHIEVED
            });
        }

        if (trackingInfo.customsClearance.arrival.enabled) {
            if (!ACHIEVED) {
                // ACHIEVED = !!trackingInfo.customsClearance.arrival.fact;
                ACHIEVED = moment(trackingInfo.customsClearance.arrival.fact).isAfter(now);
            }

            points.push({
                id: `poa-${idx++}`,
                title: <FormattedMessage id="logistBlock.arrivalOrInnings" />,
                datePlan: trackingInfo.customsClearance.arrival.plan,
                dateFact: trackingInfo.customsClearance.arrival.fact,
                achieved: ACHIEVED
            });
        }

        return points.reverse();
    };

    const mapPointsForPointOfDestination = (pointOfDestination) => {
        if (!pointOfDestination){
            return [{
                id: 'podest-1',
                title: <FormattedMessage id="logistBlock.arrivalDate" />,
                achieved: false
            }];
        }

        const points = [];

        if (pointOfDestination && pointOfDestination.arrival.enabled) {
            if (pointOfDestination.locationType) {
                if (!ACHIEVED) {
                    // ACHIEVED = !!pointOfDestination.arrival.fact;
                    ACHIEVED = moment(pointOfDestination.arrival.fact).isAfter(now);
                }

                points.push({
                    id: 'podest-1',
                    title: <FormattedMessage id="logistBlock.arrivalDate" />,
                    datePlan: pointOfDestination.arrival.plan,
                    dateFact: pointOfDestination.arrival.fact,
                    achieved: ACHIEVED
                });
            }
        }

        return points;
    };

    if (trackingInfo) {
        const steps = mapPointsForPointOfDestination(trackingInfo.pointOfDestination);
        let datePlan = undefined;
        let dateFact = undefined;

        if (steps && steps[0]) {
            datePlan = steps[0].datePlan;
            dateFact = steps[0].dateFact;
        }

        const title = trackingInfo.pointOfDestination?
            `${trackingInfo.pointOfDestination.name[AppStore.userLang]}`:
            translate({ id: 'logistBlock.arrivalDate' });
        const locationType = trackingInfo.pointOfDestination? trackingInfo.pointOfDestination.locationType: '';

        result.push({
            id: 5,
            transportationTypeId: getTransportationTypeId(locationType),
            title: title,
            datePlan,
            dateFact,
            isOpen: false,
            details:{
                steps
            },
            useStepPoint: false,
            achieved: ACHIEVED
        });
    }

    if (trackingInfo && trackingInfo.customsClearance &&
        (trackingInfo.customsClearance.arrival.enabled || trackingInfo.customsClearance.departure.enabled)
    ) {
        const steps = mapPointsForCustomsClearance(trackingInfo.customsClearance);
        let datePlan = undefined;
        let dateFact = undefined;

        if (steps && steps[0]) {
            datePlan = steps[0].datePlan;
            dateFact = steps[0].dateFact;
        }

        result.push({
            id: 4,
            transportationTypeId: getTransportationTypeId(trackingInfo.customsClearance.locationType),
            title: <FormattedMessage id="form.field.customsClearance" />,
            datePlan,
            dateFact,
            isOpen: false,
            details:{
                steps
            },
            useStepPoint: true
        });
    }

    if (trackingInfo && trackingInfo.pointOfArrival) {
        const steps = mapPointsForPointOfArrival(trackingInfo.pointOfArrival);
        let datePlan = undefined;
        let dateFact = undefined;

        if (steps && steps[0]) {
            datePlan = steps[0].datePlan;
            dateFact = steps[0].dateFact;
        }

        result.push({
            id: 3,
            transportationTypeId: getTransportationTypeId(trackingInfo.pointOfArrival.locationType),
            title: `${getPlaceTypesByCode(trackingInfo.pointOfArrival.locationType)} (${trackingInfo.pointOfArrival.name[AppStore.userLang]})`,
            datePlan,
            dateFact,
            isOpen: false,
            details:{
                steps
            },
            useStepPoint: true
        });
    }

    if (trackingInfo && trackingInfo.pointOfOrigin) {
        const steps = mapPointsForPointOfOrigin(trackingInfo.pointOfOrigin);
        let datePlan = undefined;
        let dateFact = undefined;

        if (steps && steps[0]) {
            datePlan = steps[0].datePlan;
            dateFact = steps[0].dateFact;
        }

        result.push({
            id: 2,
            transportationTypeId: getTransportationTypeId(trackingInfo.pointOfOrigin.locationType),
            title: `${getPlaceTypesByCode(trackingInfo.pointOfOrigin.locationType)} (${trackingInfo.pointOfOrigin.name[AppStore.userLang]})`,
            datePlan,
            dateFact,
            isOpen: false,
            details:{
                steps
            },
            useStepPoint: true
        });
    }

    if (trackingInfo && trackingInfo.pointOfPickup &&
        (
            trackingInfo.pointOfPickup.ready.enabled ||
            trackingInfo.pointOfPickup.load.enabled ||
            trackingInfo.pointOfPickup.departure.enabled
        )
    ){
        const steps = mapPointsForPointOfPickup(trackingInfo.pointOfPickup);
        let datePlan = undefined;
        let dateFact = undefined;

        if (steps && steps[0]) {
            datePlan = steps[0].datePlan;
            dateFact = steps[0].dateFact;
        }

        result.push({
            id: 1,
            transportationTypeId: getTransportationTypeId(trackingInfo.pointOfPickup.locationType),
            title: `${trackingInfo.pointOfPickup.name[AppStore.userLang]}`,
            datePlan,
            dateFact,
            isOpen: false,
            details:{
                steps
            },
            useStepPoint: true
        });
    }

    return result.reverse();
};

const prepareOrderInformationData = (cargoData) => {
    const emptyValue = '';
    const {
        arrivalPlace: {
            address: addressTo = emptyValue,
            companyName: companyTo = emptyValue,
            contactPerson: contactPersonTo = emptyValue,
            phone: phoneTo = emptyValue,
            email: emailTo = emptyValue,
            zipCode: indexTo = emptyValue,
        } = {},
        departurePlace: {
            address: addressFrom = emptyValue,
            companyName: companyFrom = emptyValue,
            contactPerson: contactPersonFrom = emptyValue,
            phone: phoneFrom = emptyValue,
            email: emailFrom = emptyValue,
            zipCode: indexFrom = emptyValue,
        } = {},
        companyName: customerCompanyName = emptyValue
    } = cargoData;

    const shipperAndConsigneeLabel = <FormattedMessage id={'deliveryOrders.orderInfo.shipperAndConsignee'} />;
    const indexLabel = <FormattedMessage id={'deliveryOrders.orderInfo.index'} />;
    const contactPersonLabel = <FormattedMessage id={'deliveryOrders.orderInfo.contactPerson'} />;
    const addressLabel = <FormattedMessage id={'deliveryOrders.orderInfo.address'} />;
    const phoneLabel = <FormattedMessage id={'deliveryOrders.orderInfo.phone'} />;
    const emailLabel = <FormattedMessage id={'deliveryOrders.orderInfo.email'} />;

    const notificationList = [];

    const crudZipCodeTitle = <FormattedMessage id={'crud.field.zipCode.title'} />;
    const crudContactPersonTitle = <FormattedMessage id={'crud.field.contactPerson.title'} />;
    const crudAddressTitle = <FormattedMessage id={'crud.field.address.title'} />;
    const crudPhoneTitle = <FormattedMessage id={'crud.field.phone.title'} />;
    const crudEmailTitle = <FormattedMessage id={'crud.field.email.title'} />;

    if (cargoData && cargoData.notifyDetailList) {
        for (let x of cargoData.notifyDetailList) {
            notificationList.push({
                notifiablePersonName: x.companyName,
                notifiablePersonData: [
                    {name: crudZipCodeTitle, value: x.zipCode, key: 'index'},
                    {name: crudContactPersonTitle, value: x.contactPerson, key: 'contactPerson'},
                    {name: crudAddressTitle, value: x.address, key: 'address'},
                    {name: crudPhoneTitle, value: x.phone, key: 'phone'},
                    {name: crudEmailTitle, value: x.email, formater: (x) => <a href={x}>{x}</a>, key: 'email'},
                ],
            });
        }
    }

    const departurePlaceData = {
        notifiablePersonName: companyFrom,
        notifiablePersonData: [
            {name: crudZipCodeTitle, value: indexFrom, key: 'index'},
            {name: crudContactPersonTitle, value: contactPersonFrom, key: 'contactPerson'},
            {name: crudAddressTitle, value: addressFrom, key: 'address'},
            {name: crudPhoneTitle, value: phoneFrom, key: 'phone'},
            {name: crudEmailTitle, value: emailFrom, formater: (x) => <a href={x}>{x}</a>, key: 'email'},
        ],
    };

    const arrivalPlaceData = {
        notifiablePersonName: companyTo,
        notifiablePersonData: [
            {name: crudZipCodeTitle, value: indexTo, key: 'index'},
            {name: crudContactPersonTitle, value: contactPersonTo, key: 'contactPerson'},
            {name: crudAddressTitle, value: addressTo, key: 'address'},
            {name: crudPhoneTitle, value: phoneTo, key: 'phone'},
            {name: crudEmailTitle, value: emailTo, formater: (x) => <a href={x}>{x}</a>, key: 'email'},
        ],
    };

    const data = {
        cargoTableData: [
            {
                name: shipperAndConsigneeLabel,
                fromValue: companyFrom,
                toValue: companyTo
            },{
                name: indexLabel,
                fromValue: indexFrom,
                toValue: indexTo
            }, {
                name: contactPersonLabel,
                fromValue: contactPersonFrom,
                toValue: contactPersonTo
            }, {
                name: addressLabel,
                fromValue: addressFrom,
                toValue: addressTo
            },
            {
                name: phoneLabel,
                fromValue: phoneFrom,
                toValue: phoneTo
            }, {
                name: emailLabel,
                fromValue: emailFrom,
                toValue: emailTo,
                formater: (x) => <a href={x}>{x}</a>
            },
        ],
        notificationList: notificationList,
        arrivalPlace: arrivalPlaceData,
        departurePlace: departurePlaceData,
        customerCompanyName,
    };

    return data;
};

//  getIndicator
const getIndicator = (trackingInfo, statusId) => {
    const neutral = 'transparent';
    const green = 'green';
    const red = 'red';
    const yellow = 'yellow';
    const gray = 'gray';
    const result = [];
    if (statusId === 'ARCHIVED') {
        return [{color: gray, type:0, id:'deliveryOrders.status.ARCHIVED', description: <FormattedMessage id={'deliveryOrders.status.ARCHIVED'}/>}];
    }

    if (statusId === 'STANDBY') {
        return [{color: gray, type:0, id:'deliveryOrders.status.STANDBY', description: <FormattedMessage id={'deliveryOrders.status.STANDBY'}/>}];
    }
    if (statusId === 'DONE') {
        return [{color: green, type:0, id:'deliveryOrders.status.DELIVERED', description: <FormattedMessage id={'deliveryOrders.status.deliveryOrders.status.DELIVERED'}/>}];
    }
    // result.push({color: neutral,  type: -1, id:'Ok', description:'Ok'});
    if (!trackingInfo) {
        return result;
    }
    const {
        invoiceDate: {
            date: {
                date: invoiceDate,
            } = {},
        } = {},

        invoicePaymentDate: {
            date: {
                date: invoicePaymentDate,
            } = {},
        } = {},
        pointOfPickup: {
            ready: {
                plan: pointOfPickupReadyPlanDate,
                fact: pointOfPickupReadyFactDate,
                enabled: pointOfPickupReadyEnabled
            } = {},
            departure: {
                plan: pointOfPickupDeparturePlanDate,
                fact: pointOfPickupDepartureFactDate,
                enabled: pointOfPickupDepartureEnabled
            } = {},
            load: {
                plan: pointOfPickupLoadPlanDate,
                fact: pointOfPickupLoadFactDate,
                enabled: pointOfPickupLoadEnabled
            } = {},
        } = {},
        pointOfOrigin: {
            arrival: {
                plan: pointOfOriginArrivalPlanDate,
                fact: pointOfOriginArrivalFactDate,
                enabled: pointOfOriginArrivalEnabled
            } = {},
            departure: {
                plan: pointOfOriginDeparturePlanDate,
                fact: pointOfOriginDepartureFactDate,
                enabled: pointOfOriginDepartureEnabled
            } = {},
        } = {},
        pointOfArrival: {
            arrival: {
                plan: pointOfArrivalArrivalPlanDate,
                fact: pointOfArrivalArrivalFactDate,
                enabled: pointOfArrivalArrivalEnabled
            } = {},
            receiptOfDocuments: {
                plan: pointOfArrivalReceiptOfDocumentsPlanDate,
                fact: pointOfArrivalReceiptOfDocumentsFactDate,
                enabled: pointOfArrivalReceiptOfDocumentsEnabled
            } = {},
            departure: {
                plan: pointOfArrivalDeparturePlanDate,
                fact: pointOfArrivalDepartureFactDate,
                enabled: pointOfArrivalDepartureEnabled
            } = {},
        } = {},
        pointOfDestination: {
            arrival: {
                plan: pointOfDestinationPlanDate,
                fact: pointOfDestinationFactDate,
                enabled: pointOfDestinationEnabled
            } = {},
        } = {},
        customsClearance: {
            arrival: {
                plan: customsClearanceArrivalPlanDate,
                fact: customsClearanceArrivalFactDate,
                enabled: customsClearanceArrivalEnabled
            } = {},
            departure: {
                plan: customsClearanceDeparturePlanDate,
                fact: customsClearanceDepartureFactDate,
                enabled: customsClearanceDepartureEnabled
            } = {},
        } = {},

        deliveryToRecipientDate: {
            date: {
                plan: deliveryToRecipientPlanDate,
                fact: deliveryToRecipientFactDate
            } = {}
        } = {},
    } = trackingInfo;

    const now = moment.now();
    // const ACHIEVED = moment(pointOfPickup.departure.plan) <= now;

    if (deliveryToRecipientPlanDate && !deliveryToRecipientFactDate) {
        if (moment(deliveryToRecipientPlanDate).startOf('day').isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.deliveryToRecipientDate', description:  <FormattedMessage id={'indicator.deliveryToRecipientDate'}/>});
        } else {
            if (moment(deliveryToRecipientPlanDate).startOf('day').diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.deliveryToRecipientDate',
                    description: <FormattedMessage id={'indicator.deliveryToRecipientDate'}/>
                });
            }
        }
    }

    // pointOfDestination
    if (pointOfDestinationPlanDate && !pointOfDestinationFactDate) {
        if (moment(pointOfDestinationPlanDate).startOf('day').isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfDestination.arrival', description: <FormattedMessage id={'indicator.pointOfDestination.arrival'}/>});
        } else {
            if (moment(pointOfDestinationPlanDate).startOf('day').diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfDestination.arrival',
                    description: <FormattedMessage id={'indicator.pointOfDestination.arrival'}/>
                });
            }
        }
    }

    // customsClearance
    if (customsClearanceDeparturePlanDate && !customsClearanceDepartureFactDate){
        if (moment(customsClearanceDeparturePlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.customsClearance.departure', description: <FormattedMessage id={'indicator.customsClearance.departure'}/>});
        } else {
            if (moment(customsClearanceDeparturePlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.customsClearance.departure',
                    description: <FormattedMessage id={'indicator.customsClearance.departure'}/>
                });
            }
        }
    }
    if (customsClearanceArrivalPlanDate && !customsClearanceArrivalFactDate) {
        if (moment(customsClearanceArrivalPlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.customsClearance.arrival', description: <FormattedMessage id={'indicator.customsClearance.arrival'}/>});
        } else {
            if (moment(customsClearanceArrivalPlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.customsClearance.arrival',
                    description: <FormattedMessage id={'indicator.customsClearance.arrival'}/>
                });
            }
        }
    }

    // pointOfArrival
    if (pointOfArrivalDeparturePlanDate && !pointOfArrivalDepartureFactDate) {
        if (moment(pointOfArrivalDeparturePlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfArrival.departure', description: <FormattedMessage id={'indicator.pointOfArrival.departure'}/>});
        } else {
            if (moment(pointOfArrivalDeparturePlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfArrival.departure',
                    description: <FormattedMessage id={'indicator.pointOfArrival.departure'}/>
                });
            }
        }
    }
    if (pointOfArrivalReceiptOfDocumentsPlanDate && !pointOfArrivalReceiptOfDocumentsFactDate) {
        if (moment(pointOfArrivalReceiptOfDocumentsPlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.receiptOfDocuments', description: <FormattedMessage id={'indicator.receiptOfDocuments'}/>});
        } else {
            if (moment(pointOfArrivalReceiptOfDocumentsPlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.receiptOfDocuments',
                    description: <FormattedMessage id={'indicator.receiptOfDocuments'}/>
                });
            }
        }
    }
    if (pointOfArrivalArrivalPlanDate && !pointOfArrivalArrivalFactDate) {
        if (moment(pointOfArrivalArrivalPlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfArrival.arrival', description: <FormattedMessage id={'indicator.pointOfArrival.arrival'}/>});
        } else {
            if (moment(pointOfArrivalArrivalPlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfArrival.arrival',
                    description: <FormattedMessage id={'indicator.pointOfArrival.arrival'}/>
                });
            }
        }
    }

    // pointOfOrigin
    if (pointOfOriginDeparturePlanDate && !pointOfOriginDepartureFactDate) {
        if (moment(pointOfOriginDeparturePlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfOrigin.departure', description: <FormattedMessage id={'indicator.pointOfOrigin.departure'}/>});
        } else {
            if (moment(pointOfOriginDeparturePlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfOrigin.departure',
                    description: <FormattedMessage id={'indicator.pointOfOrigin.departure'}/>
                });
            }
        }
    }

    if (pointOfOriginArrivalPlanDate &&!pointOfOriginArrivalFactDate) {
        if (moment(pointOfOriginArrivalPlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfOrigin.arrival', description: <FormattedMessage id={'indicator.pointOfOrigin.arrival'}/>});
        } else {
            if (moment(pointOfOriginArrivalPlanDate).startOf('day').diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfOrigin.arrival',
                    description: <FormattedMessage id={'indicator.pointOfOrigin.arrival'}/>
                });
            }
        }
    }

    // invoicePaymentDate
    if (invoiceDate && !invoicePaymentDate) {
        const diff = moment(invoiceDate).diff(now,'days')
        if (diff<0 && diff < -INDICATOR_INVOICE_WARNING_DAYS) {
            result.push({color: red, type: 1, id:'indicator.invoicePaymentDate', description: <FormattedMessage id={'indicator.invoicePaymentDate'}/>});
        } else {
            if (diff < 0 && diff >= -INDICATOR_INVOICE_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.invoicePaymentDate',
                    description: <FormattedMessage id={'indicator.invoicePaymentDate'}/>
                });
            }
        }
    }

    // invoiceDate
    if (!invoiceDate && pointOfPickupDepartureFactDate) {
        result.push({color: red, type: 1, id:'indicator.invoiceDate', description: <FormattedMessage id={'indicator.invoiceDate'}/>});
        /*
                    if (moment(invoiceDate).isSameOrBefore(now)) {
                        return {color: red, type: 1, id:'indicator.invoiceDate', description: translate('indicator.invoiceDate')};
                    }
                    if (moment(invoiceDate).diff(now,'days') <= INDICATOR_WARNING_DAYS) {
                        return {color: red, type: 2, id:'indicator.invoiceDate', description: translate('indicator.invoiceDate')};
                    }
        */
    }

    // pointOfPickup
    if (pointOfPickupDeparturePlanDate && pointOfPickupDepartureEnabled && !pointOfPickupDepartureFactDate) {
        if (moment(pointOfPickupDeparturePlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfPickup.departure', description: <FormattedMessage id={'indicator.pointOfPickup.departure'}/>});
        } else {
            if (moment(pointOfPickupDeparturePlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfPickup.departure',
                    description: translate('indicator.pointOfPickup.departure')
                });
            }
        }
    }
    if (pointOfPickupLoadPlanDate && pointOfPickupLoadEnabled && !pointOfPickupLoadFactDate) {
        if (moment(pointOfPickupLoadPlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfPickup.load', description: <FormattedMessage id={'indicator.pointOfPickup.load'}/>});
        } else {
            if (moment(pointOfPickupLoadPlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfPickup.load',
                    description: <FormattedMessage id={'indicator.pointOfPickup.load'}/>
                });
            }
        }
    }
    if (pointOfPickupReadyPlanDate && pointOfPickupReadyEnabled && !pointOfPickupReadyFactDate) {
        if (moment(pointOfPickupReadyPlanDate).isSameOrBefore(now)) {
            result.push({color: red, type: 1, id:'indicator.pointOfPickup.ready', description: <FormattedMessage id={'indicator.pointOfPickup.ready'}/>});
        } else {
            if (moment(pointOfPickupReadyPlanDate).diff(now, 'days') <= INDICATOR_WARNING_DAYS) {
                result.push({
                    color: yellow,
                    type: 2,
                    id: 'indicator.pointOfPickup.ready',
                    description: <FormattedMessage id={'indicator.pointOfPickup.ready'}/>
                });
            }
        }
    }

    return result;
};

// getIndicatorById
const getIndicatorById = (indicator, id, type=0)=>{
  if (_.isEmpty(indicator)) {
      return false;
  }
  let filterFunc;
  if (type > 0) {
      filterFunc = (i) => (i.id === id && i.type === type);
  } else {
      filterFunc = (i) => (i.id === id);
  }
  const result = indicator.filter(filterFunc);
  if ( result.length > 0 ) {
      return result[0];
  } else {
      return false;
  }
}

const getContainerNumber = (item) => {
    if (item.cargoInfo.containers && item.cargoInfo.containers[0].containerId) {
        return item.cargoInfo.containers[0].containerId;
    }
    if (item.transportDocument && item.transportDocument.containerNumber) {
        return item.transportDocument.containerNumber;
    }
    return 'N/A';
};

export {
    OrderCardPanel
    , getCargotypeLogoCssClass
    , getTranslateCargoType
    , getOrderMiniCardTransportTypeCssClass
    , AgoraDocActions
    , AgoraStatusActions
    , getCargoTypeIdByCargoInfo
    , getFinishDateOfOrder
    , getStartDateOfOrder
    , prepareOrderCardCargoInfoData
    , getCargoSummary as getCargoSummaryForOrder
    , getOrderStatusNameById
    , getOrderStatusName
    , prepareMailInfoData
    , prepareTrackingRoutItems
    , prepareOrderAccordionItems
    , prepareOrderInformationData
    , getTransportationTypeIconId
    , getIndicator
    , getIndicatorById
    , getContainerNumber
};
