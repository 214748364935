import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button, FormGroup } from 'reactstrap';
import { cloneDeep, get, find, map } from 'lodash';
import { Select, NumberInput } from '../ui/form-control';
import { addFormatName } from '../../utils/utils';
import DictionaryService from '../../services/dictionaryService';
import ToggleDropdown from './ToggleDropdown';

const INSURANCE_FIELDS = {
  cargoCost: {
    currency: 'RUB',
    value: '',
  },
  interestTypeId: '',
};

export class InsuranceForm extends Component {
  state = {
    isWaiting: false,
    interestTypes: [],
    currencies: [],
  };

  async UNSAFE_componentWillMount() {
    this.setState({
      isWaiting: true,
    });

    const promises = [
      DictionaryService.getCurrencies(),
      DictionaryService.getInterestTypes(),
    ];

    Promise.all(promises).then((res) => {
      this.setState({
        isWaiting: false,
        currencies: addFormatName(res[0]),
        interestTypes: res[1],
      });
    });
  }

  addRange() {
    const { onChange } = this.props;
    const cargoInsurances = cloneDeep(this.props.cargoInsurances);
    cargoInsurances.push(INSURANCE_FIELDS);

    this.setState({
      tabIndex: cargoInsurances.length,
    });
    onChange(cargoInsurances, 'cargoInsurances');
  }

  removeRange(index) {
    const { onChange } = this.props;
    const cargoInsurances = cloneDeep(this.props.cargoInsurances);
    cargoInsurances.splice(index, 1);

    this.setState({
      tabIndex: index > 0 ? index : 1,
    });
    onChange(cargoInsurances, 'cargoInsurances', `cargoInsurances[${index}]`, index);
  }

  getTabsContent() {
    const { currencies, interestTypes } = this.state;
    const { cargoInsurances, errors } = this.props;

    return map(cargoInsurances, (item, index) => {
      const currency = find(currencies, element => element.code === item.cargoCost.currency);

      const handleRemoveItem = () => {
        this.removeRange(index);
      };

      return (
        <div className="f-inline align-items-center mb-2" key={index}>
          <Row style={{ width: '100%' }}>
            <Col xs={12} sm={6} md={4} className="pl-0">
              <FormGroup>
                <Select
                  value={get(item, 'interestTypeId')}
                  values={addFormatName(interestTypes)}
                  valueKey="id"
                  labelKey="formatName"
                  onChange={value => this.onChangeFields(value.id, 'interestTypeId', index)}
                  label="wizard.cargo.form.insurance.interest"
                  error={get(errors, `cargoInsurances[${index}].interestTypeId`)}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <FormGroup>
                <NumberInput
                  label="wizard.insuranceForm.value"
                  placeholder="wizard.insuranceForm.value"
                  step={1}
                  min={1}
                  value={get(item, 'cargoCost.value')}
                  onChange={value => this.onChangeFields(value, 'cargoCost.value', index)}
                  error={get(errors, `cargoInsurances[${index}].cargoCost.value`)}
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={2}>
              <FormGroup>
                <Select
                  placeholder="common.currency"
                  label="common.currency"
                  labelKey="code"
                  valueKey="code"
                  value={currency}
                  values={currencies}
                  clearable={false}
                  onChange={value => this.onChangeFields(value.code, 'cargoCost.currency', index)}
                />
              </FormGroup>
            </Col>
            <Col xs={6} md={1} style={{ display: 'flex', alignItems: 'center', paddingTop: 23 }}>
              { cargoInsurances.length > 1 &&
                <Button
                  className="btn-sm btn-remove"
                  color="default"
                  onClick={handleRemoveItem}
                />
              }
            </Col>
          </Row>
        </div>
      );
    });
  }

  onChangeFields = (value, name, index) => {
    const { onChange } = this.props;
    onChange(value, `cargoInsurances[${index}].${name}`, `cargoInsurances[${index}].${name}`);
  };

  render() {
    const { isIncluded, switchInclude } = this.props;

    return (
      <div className="block-margin-top mb-4">
        <ToggleDropdown
          isShown={isIncluded}
          onChange={switchInclude}
          label={<FormattedMessage id="form.wizard.title.insurance" />}
        >
          {this.getTabsContent()}
          <Row>
            <Col xs={12} className="pl-0">
              <Button color="link" className="btn-sm add-container-btn pl-0 ml-0" onClick={() => this.addRange()}>
                +<FormattedMessage id="wizard.insuranceForm.addRange" />
              </Button>
            </Col>
          </Row>
        </ToggleDropdown>
      </div>
    );
  }
}

InsuranceForm.propTypes = {
  cargoInsurances: PropTypes.array,
  isIncluded: PropTypes.bool,
  errors: PropTypes.object,
  switchInclude: PropTypes.func,
  onChange: PropTypes.func,
};
