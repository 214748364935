/* eslint-disable */

import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {isEmpty, cloneDeep, set, get} from 'lodash';
import {Button, Form, Row, Col} from 'reactstrap';
import FlashStore from '../../stores/flashStore';
import DictionaryService from '../../services/dictionaryService';
import OrderDeliveryService from '../../services/orderDeliveryService';
import {Modal} from './Modal';
import AppStore from '../../stores/appStore';
import {DATE_FORMAT} from '../../constants/global';
import {TextInput, NumberInput, Select, DateInput} from './form-control';
import {translate, addFormatNameToConstant, copyToBuffer} from '../../utils/utils';
import {WEIGHT_UNITS} from '../../constants/staticData';
import FontAwesome from "react-fontawesome";
import UserStore from "../../stores/userStore";

export default class LogisticModal extends Component {
    state = {
        isShown: false,
        currency: this.props.manualCost ? this.props.manualCost.currency : 'USD',
        currencies: [],
        price: this.props.manualCost ? this.props.manualCost.value : 0,
        comment: this.props.manualCostDescription,
        remarks: this.props.remarks || '',
        internalId: this.props.internalId,
        driverMobile: this.props.driverMobile,
        driverName: this.props.driverName,
        truckNumber: this.props.truckNumber,
        sealines: [],
        sealine: this.props.sealine,
        bol: this.props.bol,
        awb: this.props.awb,
        containersTypes: [],
        containers: this.props.containers,
        generals: this.props.generals,
        vehicle: this.props.vehicle?[this.props.vehicle]:[],
        documentContainer: this.props.documentContainer,
        transportDocument: this.props.transportDocument,
        containerId: this.props.containerId,
        trackingNumber: this.props.trackingNumber,
        railwayNumber: this.props.railwayNumber,
        shippingDatePlan: this.props.shippingDatePlan,
        shippingDateFact: this.props.shippingDateFact,
        item: this.props.item,
    };

    updateState(newState, callback=()=>{}){
        setTimeout(
          ()=>{this.setState(newState, callback)}
        )
    }

    componentDidUpdate(prevProps) {
        /* if (this.props.shippingDatePlan !== prevProps.shippingDatePlan) {
             // eslint-disable-next-line react/no-did-update-set-state
             this.updateState({
                 shippingDatePlan: this.props.shippingDatePlan,
                 shippingDateFact: this.props.shippingDateFact,
             });
         }*/
        if(this.props.item !== prevProps.item){
            this.updateState({
                item: this.props.item
            });
        }
        if(this.props.item.createDate !== prevProps.item.createDate)
            this.updateState({
                item: this.props.item
            });
    }

    async UNSAFE_componentWillMount() {
        DictionaryService.getCurrencies()
          .then((response) => {
              if (isEmpty(response)) {
                  return;
              }

              this.updateState({
                  currencies: response,
              });
          });

        DictionaryService.getSeaLines()
          .then((response) => {
              if (isEmpty(response)) {
                  return;
              }

              this.updateState({
                  sealines: response,
              });
          });

        DictionaryService.getCargoContainerTypes('COMMON')
          .then((response) => {
              if (isEmpty(response)) {
                  return;
              }

              this.updateState({
                  containersTypes: response,
              });
          });
    }

    onChange = (ev, name, index, cargoType) => {
        if (index !== undefined) {
            if (cargoType === 'generals') {
                const cloned = cloneDeep(this.state.generals);
                if (name === 'trackingNumber') set(cloned, `[${index}].${name}`, ev);
                else set(cloned, `[${index}].${name}`, +ev);
                this.updateState({
                    generals: cloned,
                });
            } else {
                const cloned = cloneDeep(this.state.containers);
                if (name === 'type') {
                    set(cloned, `[${index}].${name}`, this.state.containersTypes.find(container => container.id === ev));
                } else {
                    set(cloned, `[${index}].${name}`, ev);
                }
                console.log("cloned", cloned);
                this.updateState({
                    containers: cloned,
                });
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (name === 'transportationDocument' || name === 'homeTransportationDocument') {
                this.updateState({
                    transportDocument: {
                        ...this.state.transportDocument,
                        [name]: ev,
                    },
                });
            } else {
                this.updateState({
                    [name]: ev,
                });
            }
        }
    };

    onChangeTrackingDate = (name, value)=>{
        let datePlan = new Date(value);
        let orderDate = new Date(this.state.item.createDate.split(' ')[0]);
        let delta = datePlan.getTime() - orderDate.getTime();
        if(delta >= 0) {
            this.updateState({
                [name]: value
            }, ()=>{
                const pointOfPickupReady = {
                    plan: this.state.shippingDatePlan,
                };
                let id = this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1]);
                if (id.toString().endsWith('#not-complete')) {
                    id = id.replace('#not-complete', '');
                }
                OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady).then(response=>{
                    if ((response.status === 'success')) {
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);
                        if (this.props.onUpdateTrackingInfo && typeof this.props.onUpdateTrackingInfo === 'function') {
                            console.log("UPDATED TRACKING INFO", response.data.trackingInfo)
                            this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                        }
                    }
                });
            });
        }else{
            FlashStore.errorNow(translate({id: 'tracking.fields.error.datePlanError'}), true);
        }

    }

    async onSave() {
        let id = this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1]);
        if (id.toString().endsWith('#not-complete')) {
            id = id.replace('#not-complete', '');
        }

        const manualCost = {
            currency: this.state.currency,
            value: this.state.price === 0 ? undefined : this.state.price,
        };
        const manualCostDescription = this.state.comment ? this.state.comment : '';
        const {internalId, remarks, trackingNumber} = this.state;

        const containers = this.state.containers && this.state.containers.map((item) => {
            const container = {
                containerId: item.containerId,
                typeId: item.type.id,
                weight: item.weight,
                weightUnit: item.weightUnit,
                deliveryTruckDetail: item.deliveryTruckDetail,
                cargoReadyDate: item.cargoReadyDate,
            };
            return container;
        });

        const generals = this.state.generals && this.state.generals.map((item) => {
            const general = {
                amount: item.amount,
                height: item.height,
                length: item.length,
                weight: item.weight,
                width: item.width,
                sizeUnit: item.sizeUnit,
                cubeVolume: item.cubeVolume,
                typeId: item.typeId,
            };
            return general;
        });

        const deliveryTruckDetail = {
            driverMobile: this.state.driverMobile,
            driverName: this.state.driverName,
            truckNumber: this.state.truckNumber,
        };

        const pointOfPickupReady = {
            fact: this.state.shippingDateFact,
            plan: this.state.shippingDatePlan,
        };

        const containerNumber = this.state.containerId;

        const transportDocument = {
            transportationDocument: this.state.documentContainer.mainDocument && this.state.transportDocument.transportationDocument,
            carrierCode: this.state.sealine ? this.state.sealine : undefined,
            homeTransportationDocument: this.state.documentContainer.homeDocument ? this.state.transportDocument.homeTransportationDocument : '',
            containerNumber,
        };

        const cargoInfo = {
            containers,
            generals,
        };

        const data = {
            manualCost,
            manualCostDescription,
            deliveryTruckDetail,
            transportDocument,
            cargoInfo,
            internalId,
            trackingNumber,
            remarks,
            pointOfPickupReady,
        };
        OrderDeliveryService.sendOrderTracking(id, data)
          .then((response) => {
              if ((response.status === 'success')) {
                  FlashStore.successNow(translate({id: 'common.save.success'}), true);
                  this.props.onClose(true);
                  if (this.props.onUpdateTrackingInfo && typeof this.props.onUpdateTrackingInfo === 'function') {
                      this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                  }
              }
          });
    }

    getContainerNumber = () => {
        if (this.state.containers && this.state.containers[0].containerId) {
            return this.state.containers[0].containerId;
        }
        if (this.state.transportDocument && this.state.transportDocument.containerNumber) {
            return this.state.transportDocument.containerNumber;
        }
        return 'N/A';
    };

    async onChangeCreateDate(value) {
        const item = cloneDeep(this.state.item);
        set(item, 'createDate', value);
        this.updateState({item});
        let res = await OrderDeliveryService.updateCreateDate(item.id, value)
        this.props.onChangeCreateDate(res.data.createDate);
        this.onChangeTrackingDate('shippingDatePlan', value)
    }

    renderContent() {

        const docsBlockReadOnly = (<Fragment>
            <Row>
                <Col xs={12}>
                    <p className={"logistic-modal__header-item"}><FormattedMessage id="common.documents"/></p>
                </Col>
            </Row>
            <Row>

                {this.state.documentContainer.mainDocument &&
                  <Col xs={12}>
                      <div className={"logistic-modal__document-block"}>
                          <Row>
                              <Col xs={"auto"} className={"px-0"}>
                                  <p className="document-label">
                                      <FormattedMessage
                                        id={`${this.state.documentContainer.mainDocument.typeDescription}`}/>
                                  </p>
                              </Col>
                              <Col xs={"auto"} className={"px-0"}>
                                        <span className={"value-document"}>
                                            {this.state.documentContainer.mainDocument ? this.state.mainDocument : 'N/A '}
                                        </span>
                              </Col>
                              <Col xs={12} md={"auto"} className={"px-0"}>
                                        <span
                                          onClick={() => copyToBuffer(this.state.documentContainer.mainDocument ? this.state.mainDocument : 'N/A')}
                                          className={"document-copy"}>
                                            <span className={"icon-copy"}/>
                                            <span className={"text-copy"}>Скопировать в буфер обмена</span>
                                        </span>
                              </Col>
                          </Row>
                      </div>
                  </Col>
                }
                {this.state.documentContainer.homeDocument &&
                  <Col xs={12}>
                      <div className={"logistic-modal__document-block"}>
                          <Row>
                              <Col xs={"auto"} className={"px-0"}>
                                  <p className="document-label">
                                      <FormattedMessage
                                        id={`${this.state.documentContainer.homeDocument.typeDescription}`}/>
                                  </p>
                              </Col>
                              <Col xs={"auto"} className={"px-0"}>
                                        <span className={"value-document"}>
                                            {this.state.documentContainer.homeDocument ? this.state.homeDocument : 'N/A'}
                                        </span>
                              </Col>
                              <Col xs={12} md={"auto"} className={"px-0"}>
                                        <span
                                          onClick={() => copyToBuffer(this.state.documentContainer.homeDocument ? this.state.homeDocument : 'N/A')}
                                          className={"document-copy"}>
                                            <span className={"icon-copy"}/>
                                            <span className={"text-copy"}>Скопировать в буфер обмена</span>
                                        </span>
                              </Col>
                          </Row>
                      </div>
                  </Col>
                }
                {/* {this.state.item.transportDocument && this.state.item.transportDocument.railwayNumber &&
                            // <div style={{ display: 'flex', alignItems: 'center' }}>
                            //   <p className="label" style={{ minWidth: '140px' }} ><b><FormattedMessage id="RAILWAY" /></b><br />{this.state.item.transportDocument.railwayNumber}</p>
                            //   <FontAwesome name="copy" style={{ marginTop: '8px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.copyToBuffer(this.state.item.transportDocument.railwayNumber)} />
                            // </div>} */}
                {(this.getContainerNumber() !== 'N/A') &&
                  <Col xs={12}>
                      <div className={"logistic-modal__document-block"}>
                          <Row>
                              <Col xs={"auto"} className={"px-0"}>
                                  <p className="document-label">
                                      <FormattedMessage id="orderForm.numberOfFirstContainer"/>
                                  </p>
                              </Col>
                              <Col xs={"auto"} className={"px-0"}>
                                  <span className={"document-label value-document"}>{this.getContainerNumber()}</span>
                              </Col>
                              <Col xs={12} md={"auto"} className={"px-0"}>
                                        <span onClick={() => copyToBuffer(this.getContainerNumber())}
                                              className={"document-copy"}>
                                            <span className={"icon-copy"}/>
                                            <span className={"text-copy"}>Скопировать в буфер обмена</span>
                                        </span>
                              </Col>
                          </Row>
                      </div>
                  </Col>
                }
            </Row>
        </Fragment>);


        const docBlock = (
          <Fragment>
              <hr/>
              <p style={{fontSize: '20px'}}><FormattedMessage id="common.documents"/></p>
              {(this.state.documentContainer.homeDocument &&
                  (this.state.documentContainer.homeDocument.typeDescription === 'HOME_BOL')) &&
                <Row>
                    <Col xs={12} md={6}>
                        <Select
                          placeholder="crudForm.field.seaLines"
                          labelKey="code"
                          valueKey="code"
                          value={this.state.sealine}
                          values={this.state.sealines}
                          onChange={value => this.onChange(value.code, 'sealine')}
                        />
                    </Col>
                </Row>
              }
              <Row>
                  {this.state.documentContainer.mainDocument &&
                    <Col xs={12} md={6}>
                        <p style={{marginBottom: '0.3rem'}}><b><FormattedMessage
                          id={`${this.state.documentContainer.mainDocument.typeDescription}`}/></b></p>
                        <TextInput
                          placeholder={`${this.state.documentContainer.mainDocument.typeDescription}`}
                          value={this.state.transportDocument.transportationDocument}
                          onChange={ev => this.onChange(ev.target.value, 'transportationDocument')}
                          type="text"
                        />
                    </Col>
                  }
                  {this.state.documentContainer.homeDocument &&
                    <Col xs={12} md={6}>
                        <p style={{marginBottom: '0.3rem'}}><b><FormattedMessage
                          id={`${this.state.documentContainer.homeDocument.typeDescription}`}/></b></p>
                        <TextInput
                          placeholder={`${this.state.documentContainer.homeDocument.typeDescription}`}
                          onChange={ev => this.onChange(ev.target.value, 'homeTransportationDocument')}
                          value={this.state.transportDocument.homeTransportationDocument}
                          type="text"
                        />
                    </Col>
                  }
              </Row>
              {this.state.generals && this.state.documentContainer.homeDocument &&
                (
                  this.state.documentContainer.homeDocument.typeDescription === 'HOME_BOL' ||
                  this.state.documentContainer.homeDocument.typeDescription === 'HOME_RAILWAY'
                ) &&
                <Row>
                    <Col xs={12} md={6}>
                        <p style={{marginBottom: '0.3rem'}}><b><FormattedMessage id="orderForm.idContainer"/></b>
                        </p>
                        <TextInput
                          placeholder="orderForm.idContainer"
                          onChange={ev => this.onChange(ev.target.value, 'containerId')}
                          value={this.state.containerId}
                          type="text"
                        />
                    </Col>
                </Row>
              }
              <hr/>
          </Fragment>
        );

        const checkIsExistFactDate = (type="") =>{
            const stages = [
                "pointOfPickup.ready", "pointOfPickup.load", "pointOfPickup.departure",
                "pointOfOrigin.arrival", "pointOfOrigin.departure",
                "pointOfArrival.arrival", "pointOfArrival.receiptOfDocuments", "pointOfArrival.departure",
                "customsClearance.arrival", "customsClearance.departure",
                "pointOfDestination.arrival",
                "deliveryToRecipientDate.date"
            ]
            const indexElem = stages.findIndex(value => value===type);
            if(indexElem===-1)
                return false

            for(let i=indexElem; i<stages.length; i++){
                let elem = stages[i]
                let value = get(this.state.item.trackingInfo, elem, null)
                if(value!==null){
                    elem += ".fact"
                    value= get(this.state.item.trackingInfo, elem, null)
                    if(value!==null){
                        if(value.length>0)
                            return true
                    }
                }
            }
            return false
        }

        const checkIsExistTrackingId = (type) => {

            if (type === "idContainer") {
                const containerId = get(this.state.item.cargoInfo, "containers[0].containerId", null);
                if (containerId !== null) {
                    if (containerId.length > 0) {
                        return true;
                    } else return false;
                } else {
                    return false;
                }
            } else if (type === "trackingNumber") {
                const trackingNumber = get(this.state.item.requestDetails, "trackingNumber", null);
                if (trackingNumber !== null) {
                    if (trackingNumber.length > 0) {
                        return true;
                    } else return false;
                } else {
                    return false;
                }
            }
        }

        const isOutPutAwbNumber = () => {
            return this.state.item.orderCalculation && this.state.item.orderCalculation.transportationType && this.state.item.orderCalculation.transportationType === "AIR";
        }

        const mainBlock = (<Fragment>
            <Col xs={12} md={12}>
                <p className={"logistic-modal__header-item"}><FormattedMessage id="crud.field.price.title"/></p>
                <Row>
                    <div className="logistic-modal__inputs">
                        <Row>
                            <Col xs={12} md={4} lg={4} className={"pl-0"}>
                                <p className="logistic-modal__inputs_fact-price"><FormattedMessage
                                  id="orderForm.estPrice"/> {this.props.estCost.value} {this.props.estCost.currency}
                                </p>
                            </Col>
                            <Col xs={12} md={"auto"} lg={8} className={"d-flex"}>
                                <Row>
                                    <Col xs={8} md={"auto"} className={"pl-0"}>
                                        <div className={"input-price"}>
                                            <NumberInput
                                              disabled={!this.props.canEditCommercialData}
                                              leftAddon={<FormattedMessage id="orderForm.actPrice"/>}
                                              placeholder="orderForm.actPrice"
                                              value={this.state.price}
                                              onChange={value => this.onChange(value, 'price')}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={4} md={"auto"} className={"pr-0"}>
                                        <div className={"select-money"}>
                                            <Select
                                              disabled={!this.props.canEditCommercialData}
                                              placeholder="crudForm.field.currency"
                                              labelKey="code"
                                              valueKey="code"
                                              value={this.state.currency}
                                              values={this.state.currencies}
                                              className="crud-select"
                                              onChange={value => this.onChange(value.code, 'currency')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Row>
                <Row>
                    <Col xs={12} className={"pl-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="orderForm.modal.comment"/>
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <TextInput
                          className={"logistic-modal__text-area"}
                          placeholder="orderForm.commentPrice"
                          onChange={ev => this.onChange(ev.target.value, 'comment')}
                          value={this.state.comment}
                          type="textarea"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"pl-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.remarks"/>
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <TextInput
                          className={"logistic-modal__text-area"}
                          placeholder="form.order.remarks"
                          onChange={ev => this.onChange(ev.target.value, 'remarks')}
                          value={this.state.remarks}
                          type="textarea"
                        />
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} className={"pl-0 pr-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.internalId"/>
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <TextInput
                          className={"logistic-modal__text-input"}
                          placeholder="form.order.internalId"
                          onChange={ev => this.onChange(ev.target.value, 'internalId')}
                          value={this.state.internalId}
                          type="text"
                        />
                    </Col>
                </Row>
                <Row className={"date-tracking"}>
                    <Col xs={12} className={"pl-0 pr-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="crud.field.createDate.title"/>
                        </p>
                    </Col>
                    <Col xs={12} md={4} lg={4} className={"pl-0"}>
                        <DateInput
                          disabled={checkIsExistFactDate("pointOfPickup.ready") || !UserStore.isAdminOrganization() }
                          placeholder="form.order.selectDate"
                          value={this.state.item.createDate}
                          dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                          icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                          className={'tracking-item-date'}
                          onChange={value => this.onChangeCreateDate(value)}
                          onChangeOnDelete
                        />
                    </Col>
                </Row>
                <Row className={"date-tracking"}>
                    <Col xs={12} className={"pl-0 pr-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.shippingDatePlan"/>
                        </p>
                    </Col>
                    <Col xs={12} md={4} lg={4} className={"pl-0"}>
                        <DateInput
                          placeholder="form.order.selectDate"
                          value={this.state.shippingDatePlan}
                          dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                          icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                          className={'tracking-item-date'}
                          onChange={value => this.onChangeTrackingDate('shippingDatePlan', value)}
                          onChangeOnDelete
                          disabled={checkIsExistFactDate("pointOfPickup.ready")}
                        />
                    </Col>
                </Row>

                { isOutPutAwbNumber(this.state) &&
                  <Row>
                      <Col  xs={12} className={"pl-0 pr-0"}>
                          <p className={"logistic-modal__header-item"}>
                              <FormattedMessage id = "orderForm.trackingNumber" />
                          </p>
                      </Col>
                      <Col xs={12} md={4} lg={4} className={"pl-0"}>
                          <TextInput
                            placeholder="orderForm.trackingNumber"
                            onChange={ev => this.onChange(ev.target.value, 'trackingNumber')}
                            value={this.state.trackingNumber}
                            disabled={checkIsExistTrackingId('trackingNumber')}
                          />
                      </Col>
                  </Row>
                }

            </Col>
        </Fragment>);

        const deliveryBlock = (<Fragment>
            {(this.props.isShowDelivery && !this.state.containers) && <div>
                <Row>
                    <Col xs={12} md={12} className={"pr-0"}>
                        <p className={"logistic-modal__header-item"}><FormattedMessage id="deliveryOrders.delivery"/>
                        </p>
                    </Col>
                </Row>
                <Row className={"logistic-modal__delivery_block"}>
                    <Col xs={12} md={6} lg={4} className={"item-padding"}>
                        <p><FormattedMessage id="orderForm.modal.stateTruckNumber"/></p>
                        <TextInput
                          placeholder="orderForm.modal.truckNumber"
                          onChange={ev => this.onChange(ev.target.value, 'truckNumber')}
                          value={this.state.truckNumber}
                          type="text"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className={"item-padding"}>
                        <p><FormattedMessage id="orderForm.modal.driver"/></p>
                        <TextInput
                          placeholder="orderForm.modal.driverName"
                          onChange={ev => this.onChange(ev.target.value, 'driverName')}
                          value={this.state.driverName}
                          type="text"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className="item-padding align-self-end">
                        <TextInput
                          placeholder="orderForm.modal.driverPhone"
                          onChange={ev => this.onChange(ev.target.value, 'driverMobile')}
                          value={this.state.driverMobile}
                          type="text"
                        />
                    </Col>
                </Row>
            </div>}
        </Fragment>);

        const cargoParamsBlock = (<Fragment>
            <Row>
                <Col xs={12} md={12} className={"pr-0"}>
                    <p className={"logistic-modal__header-item"}><FormattedMessage
                      id="orderForm.modal.cargoParameters"/></p>
                </Col>
            </Row>
            {this.state.containers &&
              <Row>
                  <Col xs={12}>
                      <div className={"logistic-modal__table-cargo"}>
                          {/*<Row className={"d-none d-md-flex"}>
                                <Col xs={12} md={1} className={"px-small-0"}>
                                    <p>№</p>
                                </Col>
                                <Col xs={6} md={4} className={"pl-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="orderForm.idContainer"/></p>
                                </Col>
                                <Col xs={6} md={3} className={"pr-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="crud.filter.cargoContainerType"/></p>
                                </Col>
                                <Col xs={12} md={4} className={"px-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="orderForm.modal.cargoWeight"/></p>
                                </Col>
                            </Row>*/}
                          {this.state.containers.map((item, index) => {
                              // const containerType = this.state.containersTypes.find(container => container.id === item.type.id);
                              const weightUnits = addFormatNameToConstant(WEIGHT_UNITS);
                              return (
                                <div
                                  style={index !== this.state.containers.length - 1 ? {marginBottom: "20px"} : {marginBottom: "0"}}
                                  className={"logistic-modal__table-cargo_cargo-block-container"}
                                  key={this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1])}>
                                    <Row>
                                        <Col xs={12} md={1} className={"px-small-0"}>
                                            <p style={{marginBottom:"0.3rem"}}>№</p>
                                            <p className="number-cargo">{index + 1}</p>
                                        </Col>
                                        <Col xs={6} md={4} className={"pl-small-0 cargo-container-field"}>
                                            <p className={"d-block d-md-block"}>
                                                <FormattedMessage
                                                  id="orderForm.idContainer"/>
                                            </p>
                                            <TextInput
                                              placeholder="orderForm.idContainer"
                                              onChange={ev => this.onChange(ev.target.value, 'containerId', index, 'containers')}
                                              value={item.containerId}
                                              type="text"
                                              disabled={checkIsExistTrackingId("idContainer")}
                                            />
                                        </Col>
                                        <Col xs={6} md={3} className={"pr-small-0 cargo-container-field"}>
                                            <p className={"d-block d-md-block"}><FormattedMessage
                                              id="crud.filter.cargoContainerType"/></p>
                                            <Select
                                              disabled={true}
                                              placeholder="crud.filter.cargoContainerType"
                                              labelKey="formatName"
                                              valueKey="id"
                                              value={item.type}
                                              values={this.state.containersTypes}
                                              clearable={false}
                                              onChange={value => this.onChange(value.id, 'type', index, 'containers')}
                                            />
                                        </Col>
                                        <Col xs={12} md={4} className={"px-small-0 cargo-container-field"}>
                                            <p className={"d-block d-md-block"}><FormattedMessage
                                              id="orderForm.modal.cargoWeight"/></p>
                                            <div className={"d-flex"}>
                                                <NumberInput
                                                  disabled={true}
                                                  placeholder="orderForm.modal.cargoWeight"
                                                  onChange={ev => this.onChange(ev, 'weight', index, 'containers')}
                                                  value={item.weight}
                                                />
                                                <Select
                                                  disabled={true}
                                                  values={weightUnits}
                                                  clearable={false}
                                                  labelKey="code"
                                                  valueKey="code"
                                                  value={item.weightUnit}
                                                  className={'cargo-unit-select ml-1'}
                                                  onChange={ev => this.onChange(ev.code, 'weightUnit', index, 'containers')}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {/*
                                            <Row>
                                                <Col xs={12} md={1} className={"px-small-0"}>
                                                    <p className="number-cargo d-none">{index + 1}</p>
                                                </Col>
                                                <Col xs={12} md={4} className={"pl-small-0 cargo-container-field mb-md-3"}>
                                                    <p className={"d-block d-md-block"}><FormattedMessage
                                                        id="deliveryOrders.orderList.headerTable.dateReady"/> </p>
                                                    <DateInput
                                                        placeholder="deliveryOrders.orderList.headerTable.dateReady"
                                                        value={item.cargoReadyDate}
                                                        dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                        icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                        className={'tracking-item-date'}
                                                        onChange={value => this.onChange(value,'cargoReadyDate', index, 'containers')}
                                                        disabled={checkIsExistFactDate("pointOfPickup.ready")}
                                                    />
                                                </Col>
                                                <Col xs={0} md={4}></Col>
                                            </Row>
                                        */}
                                    <Row >
                                        <Col xs={12} md={1} className={"px-small-0"}>
                                            <p className="number-cargo d-none">{index + 1}</p>
                                        </Col>
                                        <Col xs={12} md={4} lg={4} className={"pl-small-0 cargo-container-field"}>
                                            <p className={"d-block d-md-block"}><FormattedMessage id="orderForm.modal.stateTruckNumber"/></p>
                                            <TextInput
                                              placeholder="orderForm.modal.truckNumber"
                                              onChange={ev => this.onChange(ev.target.value, 'deliveryTruckDetail.truckNumber', index, 'containers')}
                                              value={item.deliveryTruckDetail!==undefined?item.deliveryTruckDetail.truckNumber:''}
                                              type="text"
                                            />
                                        </Col>
                                        <Col xs={12} md={3} lg={3} className={"pr-small-0 cargo-container-field"}>
                                            <p className={"d-block d-md-block"}><FormattedMessage id="orderForm.modal.driver"/></p>
                                            <TextInput
                                              placeholder="orderForm.modal.driverName"
                                              onChange={ev => this.onChange(ev.target.value, 'deliveryTruckDetail.driverName', index, 'containers')}
                                              value={item.deliveryTruckDetail?item.deliveryTruckDetail.driverName:''}
                                              type="text"
                                            />
                                        </Col>
                                        <Col xs={12} md={4} lg={4} className={"px-small-0 cargo-container-field"}>
                                            <p className={"d-block d-md-block"}><FormattedMessage id="orderForm.modal.driverPhone"/></p>
                                            <TextInput
                                              placeholder="orderForm.modal.driverPhone"
                                              onChange={ev => this.onChange(ev.target.value, 'deliveryTruckDetail.driverMobile', index, 'containers')}
                                              value={item.deliveryTruckDetail?item.deliveryTruckDetail.driverMobile:''}
                                              type="text"
                                            />
                                        </Col>
                                    </Row>
                                    {index!== this.state.containers.length-1? <hr />:'' }
                                </div>
                              );
                          })
                          }
                      </div>
                  </Col>
              </Row>
            }
            {this.state.generals &&
              <Row>
                  <Col xs={12}>
                      <div className={"logistic-modal__table-cargo"}>
                          {this.state.generals.map((item, index) => {
                              const weightUnits = addFormatNameToConstant(WEIGHT_UNITS);
                              return (
                                <div
                                  style={index !== this.state.generals.length - 1 ? {marginBottom: "20px"} : {marginBottom: "0"}}
                                  className={"logistic-modal__table-cargo_cargo-block-container"}
                                  key={this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1])}>
                                    <Row className={"d-none d-md-flex"}>
                                        <Col xs={12} md={1} className={"px-small-0"}>
                                            <p>№</p>
                                        </Col>
                                        <Col xs={6} md={item.typeId === 3 ?5: 2} className={"pl-small-0 cargo-container-field"}>
                                            <p><FormattedMessage id={item.typeId === 3 ? "wizard.cargo.form.size.totalVolume" : "wizard.cargo.form.size.length"}/></p>
                                        </Col>
                                        <Col xs={6} md={item.typeId === 3 ?5: 2} className={"pr-small-0 cargo-container-field"}>
                                            <p><FormattedMessage id={item.typeId === 3 ? "orderForm.modal.cargoWeight" : "wizard.cargo.form.size.width"}/></p>
                                        </Col>
                                        {item.typeId !== 3 &&
                                          <Col xs={6} md={2} className={"pr-small-0 cargo-container-field"}>
                                              <p><FormattedMessage id="wizard.cargo.form.size.height"/></p>
                                          </Col>}
                                        {item.typeId !== 3 &&
                                          <Col xs={12} md={2} className={"px-small-0 cargo-container-field"}>
                                              <p><FormattedMessage id="orderForm.modal.cargoWeight"/></p>
                                          </Col>}
                                        {item.typeId !== 3 &&
                                          <Col xs={12} md={3} className={"px-small-0 cargo-container-field"}>
                                              <p><FormattedMessage id="orderForm.modal.numberOfPlaces"/></p>
                                          </Col>}
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={1} className={"px-small-0"}>
                                            <p className="number-cargo">{index + 1}</p>
                                        </Col>
                                        {item.typeId === 3 ?
                                          <Col xs={6} md={5} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="wizard.cargo.form.size.totalVolume"/></p>
                                              <TextInput
                                                placeholder="wizard.cargo.form.size.totalVolume"
                                                onChange={ev => this.onChange(ev.target.value, 'cubeVolume', index, 'generals')}
                                                value={item.cubeVolume}
                                                disabled={true}
                                                type="text"
                                              />
                                          </Col>
                                          :
                                          <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="wizard.cargo.form.size.length"/></p>
                                              <TextInput
                                                disabled={true}
                                                placeholder="wizard.cargo.form.size.length"
                                                onChange={ev => this.onChange(ev.target.value, 'length', index, 'generals')}
                                                value={item.length}
                                                type="text"
                                              />
                                          </Col>}
                                        {item.typeId === 3 ?
                                          <Col xs={6} md={4} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="orderForm.modal.cargoWeight"/></p>
                                              <TextInput
                                                disabled={true}
                                                placeholder="orderForm.modal.cargoWeight"
                                                onChange={ev => this.onChange(ev.target.value, 'weight', index, 'generals')}
                                                value={item.weight}
                                                type="text"
                                              />
                                          </Col>
                                          :
                                          <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="wizard.cargo.form.size.length"/></p>
                                              <TextInput
                                                disabled={true}
                                                placeholder="wizard.cargo.form.size.length"
                                                onChange={ev => this.onChange(ev.target.value, 'length', index, 'generals')}
                                                value={item.length}
                                                type="text"
                                              />
                                          </Col>
                                        }
                                        {item.typeId === 3 ?
                                          <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                              <Select
                                                disabled={true}
                                                values={weightUnits}
                                                clearable={false}
                                                labelKey="code"
                                                valueKey="code"
                                                value={item.weightUnit}
                                                className={'cargo-unit-select ml-1'}
                                                onChange={ev => this.onChange(ev.code, 'weightUnit', index, 'generals')}
                                              />
                                          </Col>
                                          :
                                          null
                                        }
                                        {item.typeId !== 3 ?
                                          <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="wizard.cargo.form.size.height"/></p>
                                              <TextInput
                                                disabled={true}
                                                placeholder="wizard.cargo.form.size.height"
                                                onChange={ev => this.onChange(ev.target.value, 'height', index, 'generals')}
                                                value={item.height}
                                                type="text"
                                              />
                                          </Col>
                                          :
                                          null}
                                        {item.typeId !== 3 ?
                                          <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="orderForm.modal.cargoWeight"/></p>
                                              <TextInput
                                                disabled={true}
                                                placeholder="orderForm.modal.cargoWeight"
                                                onChange={ev => this.onChange(ev.target.value, 'weight', index, 'generals')}
                                                value={item.weight}
                                                type="text"
                                              />
                                          </Col>
                                          :
                                          null
                                        }
                                        {item.typeId !== 3 ?
                                          <Col xs={6} md={3} className={"pl-small-0 cargo-container-field"}>
                                              <p className={"d-block d-md-none"}><FormattedMessage
                                                id="orderForm.modal.numberOfPlaces"/></p>
                                              <TextInput
                                                disabled={true}
                                                placeholder="orderForm.modal.numberOfPlaces"
                                                onChange={ev => this.onChange(ev.target.value, 'amount', index, 'generals')}
                                                value={item.amount}
                                                type="text"
                                              />
                                          </Col>
                                          :
                                          <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}></Col>}
                                    </Row>
                                    {index!== this.state.generals.length-1? <hr />:'' }
                                </div>
                              );
                          })}
                      </div>
                  </Col>
              </Row>
            }
            {this.state.vehicle && this.state.vehicle.length > 0 &&
              <Row>
                  <Col xs={12}>
                      <div className={"logistic-modal__table-cargo"}>
                          <Row className={"d-none d-lg-flex"}>
                              <Col xs={12} md={12} lg={1} className={"px-small-0"}>
                                  <p>№</p>
                              </Col>
                              <Col xs={12} md={4} lg={4} className={"pl-small-0 pr-small-0 cargo-container-field vehicle"}>
                                  <p><FormattedMessage id="detailsOfCalculation.orderTable.typeOfCargo"/></p>
                              </Col>
                              <Col xs={6} md={2} lg={1} className={"pr-small-0 cargo-container-field"}>
                                  <p><FormattedMessage id="deliveryOrders.cargoInformation.cargoAmountTitle"/></p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className={"px-small-0 cargo-container-field"}>
                                  <p>
                                      <FormattedMessage id="deliveryOrders.cargoInformation.cargoSizeTitle"/>

                                  </p>
                              </Col>
                              <Col xs={12} md={6} lg={3} className={"px-small-0 cargo-container-field"}>
                                  <p>
                                      <FormattedMessage id="deliveryOrders.cargoInformation.cargoWeightTitle"/>
                                  </p>
                              </Col>
                          </Row>
                          {this.state.vehicle.map((item, index) => {
                                const weightUnits = addFormatNameToConstant(WEIGHT_UNITS);
                                const sizeCar = item.type.size.length +" x "+ item.type.size.width + " x " + item.type.size.height +" "+ item.type.size.unit
                                const units = [{code: "М"}, {code: "M"}, {code: "KG"}, {code: "КГ"}];
                                return (
                                  <div
                                    key={this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1])}
                                    style={{marginBottom: "20px"}}
                                    className={"logistic-modal__table-cargo_cargo-block-container"}
                                  >
                                      <Row>
                                          <Col xs={12} md={12} lg={1} className={"px-small-0"}>
                                              <p className="number-cargo">{index + 1}</p>
                                          </Col>
                                          <Col xs={12} md={4} lg={4} className={"px-small-0 pr-0 cargo-container-field vehicle"}>
                                              <p className={"d-block d-lg-none"}>
                                                  <FormattedMessage id="detailsOfCalculation.orderTable.typeOfCargo"/>
                                              </p>
                                              <TextInput
                                                disabled
                                                value={translate("constants.vehicleBodyType."+(item.bodyType.toLowerCase()))}
                                                type="text"
                                              />
                                          </Col>
                                          <Col xs={6} md={2} lg={1} className={"pr-small-0 cargo-container-field"}>
                                              <p className={"d-block d-lg-none"}><FormattedMessage
                                                id="deliveryOrders.cargoInformation.cargoAmountTitle"/></p>
                                              <TextInput
                                                disabled
                                                value={1}
                                                type="text"
                                              />
                                          </Col>
                                          <Col xs={6} md={6} lg={3} className={"px-small-0 cargo-container-field size"}>
                                              <p className={"d-block d-lg-none"}>
                                                  <FormattedMessage
                                                    id="deliveryOrders.cargoInformation.cargoSizeTitle"/>
                                              </p>
                                              <div className={"d-flex"}>
                                                  <TextInput
                                                    disabled
                                                    value={sizeCar}
                                                    type="text"
                                                  />
                                              </div>
                                          </Col>
                                          <Col xs={12} md={6} lg={3} className={"px-small-0 cargo-container-field"}>
                                              <p className={"d-block d-lg-none"}>
                                                  <FormattedMessage
                                                    id="deliveryOrders.cargoInformation.cargoWeightTitle"/>
                                              </p>
                                              <div className={"d-flex"}>
                                                  <TextInput
                                                    disabled
                                                    value={item.type.maxWeight.value}
                                                    type="text"
                                                  />
                                                  <Select
                                                    disabled
                                                    values={units}
                                                    labelKey="code"
                                                    valueKey="code"
                                                    value={item.type.maxWeight.unit}
                                                    className={'cargo-unit-select ml-1'}
                                                    clearable={false}
                                                  />
                                              </div>
                                          </Col>
                                      </Row>
                                  </div>)
                            }
                          )}
                      </div>
                  </Col>
              </Row>
            }
        </Fragment>);
        return (
          <Form>
              {mainBlock}
              {/*{docsBlockReadOnly}*/}
              {deliveryBlock}
              {cargoParamsBlock}

              <Row className="no-gutters logistic-modal__btn-manage"
                   style={{justifyContent: 'flex-start', width: '100%', flexDirection: 'row-reverse'}}>
                  <Col xs={12} className={"d-flex"}>
                      <div className={"ml-auto"} style={{width:"fit-content"}}>
                          <Button
                            color="primary"
                            onClick={() => this.onSave()}
                            style={{marginLeft: '1em'}}
                          >
                              <FormattedMessage id="detailsOfCalculation.save"/>
                          </Button>
                      </div>
                      <div className={"ml-2"} style={{width:"fit-content"}}>
                          <Button color="secondary" onClick={() => {
                              this.props.onClose(false);
                          }}>
                              <FormattedMessage id="crudForm.button.cancel"/>
                          </Button>
                      </div>
                  </Col>
              </Row>
          </Form>
        );
    }

    render() {
        const {isShow, onClose, isModal} = this.props;
        if (isModal === true) {
            return (
              <Modal
                isShow={isShow}
                className="logistic-modal edit-order-modal"
                title={translate({id: 'crudForm.field.edit'})}
                onClose={() => {
                    onClose(false);
                }}
                top
              >
                  {this.renderContent()}
              </Modal>
            );
        } else {
            return (<div className={"logistic-modal content"}>
                {this.renderContent()}
            </div>)
        }
    }
}

LogisticModal.defaultProps = {
    isModal: false,
    onChangeCreateDate: () => {}
}

LogisticModal.propTypes = {
    isModal: PropTypes.bool,
    isShow: PropTypes.bool,
    onClose: PropTypes.func,
    onUpdateTrackingInfo: PropTypes.func,
    bol: PropTypes.string,
    awb: PropTypes.string,
    containerId: PropTypes.string,
    trackingNumber: PropTypes.string,
    manualCost: PropTypes.object,
    manualCostDescription: PropTypes.string,
    estCost: PropTypes.object,
    containers: PropTypes.array,
    generals: PropTypes.array,
    id: PropTypes.number,
    driverMobile: PropTypes.string,
    driverName: PropTypes.string,
    truckNumber: PropTypes.string,
    documentContainer: PropTypes.object,
    transportDocument: PropTypes.object,
    isShowDelivery: PropTypes.bool,
    // item: PropTypes.object,
    railwayNumber: PropTypes.string,
    sealine: PropTypes.string,
    item: PropTypes.object,
    onChangeCreateDate: PropTypes.func
};
