import {
    keyColumn,
    DynamicDataSheetGrid
} from 'react-datasheet-grid';

import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import 'react-datasheet-grid/dist/style.css'
import Select from "react-select";
import dictionaryService from "../../../services/dictionaryService";
import {API} from "../../../constants/api";
import {cloneDeep, set} from "lodash";
import {getExistLangValue, translate} from "../../../utils/utils";
import {FormattedMessage} from "react-intl";
import {Button, FormGroup, Row} from "reactstrap";
import tableTariffsService from "../../../services/tableTariffsService";
import moment from "moment";
import {DATE_FORMAT, LANG_CODE_FULL} from "../../../constants/global";
import DatePicker from "react-datepicker";
import AppStore from "../../../stores/appStore";
import FlashStore from "../../../stores/flashStore";
import {ContextMenu} from "./ContextMenu";
import {toJS} from "mobx";
import UserStore from "../../../stores/userStore";
import {
    isError,
    prepareExternalData,
    formatError,
    formatValues,
    formatSurchargesValues,
    onCopyValueSelect,
    onCopyValueText,
    onPasteValueSelect,
    onPasteValueText,
    onCopyValueDate,
    onPasteValueSelectSurcharges,
    createAddRowsComponent,
    createParam4Deleted,
    onPasteValueDate,
    patchRow,
    onDuplicate,
    renderOptions,
    renderFreight,
    emptyRowsFilter
} from "./TableTariffsUtils";
import { use } from 'i18next';


const createTariffItem = () => ({
    id: null,
    dateFrom: null,
    dateTo: null,
    departurePortId: null,
    destinationPortId: null,
    seaLineId: null,
    freightId: null,
    freightCondition: null, //FIFO, FILO LIFO, LILO
    transitTime: null,
    demurrage: null,
    detention: null,
    documentDeadline: null,
    currency: null,
    weightUnit: null,
    freight20: null,
    freight40: null,
    freight40HC: null
});


const TableTariffs = ({endpoint, type, onAfterSave, dataTariffsEdit, onCancel}) => {

    const [errors, setErrors] = useState({})
    const [isShow, setIsShow] = useState(true)
    const [currincies, setCurrincies] = useState([
        {value: 'USD', label: 'USD'},
        {value: 'EUR', label: 'EUR'},
        {value: 'CNY', label: 'CNY'},
        {value: 'RUB', label: 'RUB'},
        {value: 'GBP', label: 'GBP'},
    ])
    const [ports, setPorts] = useState([])
    const [seaLines, setSeaLines] = useState([])
    const [seaTariffSurcharges, setSeaTariffSurcharges] = useState([])
    const [typeFreight, setTypeFreight] = useState([
        {value: 'FIFO', label: 'FIFO'},
        {value: 'FILO', label: 'FILO'},
        {value: 'LIFO', label: 'LIFO'},
        {value: 'LILO', label: 'LILO'},
    ])
    const [rerender, setRerender] = useState(false)
    const [data, setData] = useState(
      () => {
          let def = []
          for (let i = 0; i < 10; i++)
              def.push(createTariffItem())
          return def;
      })
    const [arrayDeleteId, setArrayDeleteId] = useState([])
    const [isLoad, setIsLoad] = useState(false)

    const checkAccessAllowPaste = (rowIndex) => {
        const rowData = data[rowIndex];
        if (rowData === null || rowData === undefined)
            return true;
        if (rowData.id === null || rowData.id === "-") {
            return true;
        }
        if (rowData.organizationId && rowData.organizationId !== UserStore.user.organization.id) {
            return false;
        }
        return true;
    }

    const checkAccessDeleteRow = (rowIndex) => {
        return checkAccessAllowPaste(rowIndex);
    }

    const checkDisabledField = (rowIndex) => {

        return !checkAccessAllowPaste(rowIndex);
    }

    useEffect(() => {
        setIsLoad(true)
        if (dataTariffsEdit && dataTariffsEdit.length > 0) {
            let dataJs = toJS(dataTariffsEdit)
            setData(prepareExternalData(dataJs))
        }
        setIsLoad(false)
    }, [dataTariffsEdit])


    const init = async () => {
        setIsLoad(true)
        dictionaryService.getDictionary(API.DICTIONARIES.SEA_LINES()).then(res => {
            setSeaLines(formatValues(res))
        })
        dictionaryService.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS()).then(res => {
            setPorts(formatValues(res))
        })
        dictionaryService.getContainerFreightSurchargesAll({}).then((res) => {
            setSeaTariffSurcharges(formatSurchargesValues(res))
        })
    }

    useEffect(() => {
        init()
    }, []);

    useEffect(() => {
        if (data.length < 10) {
            let diff = 10 - data.length;
            let arrData = []
            for (let i = 0; i < diff; i++) {
                arrData.push(createTariffItem())
            }
            setData([...data, ...arrData])
        }
    }, [data])


    useEffect(() => {
        if (Object.keys(errors).length > 0)
            setIsShow(false)
    }, [errors])

    useEffect(() => {
        if (isShow === false)
            setIsShow(true)
    }, [isShow]);

    const onChange = (value, operations) => {
        let resultData = []
        let deletedRows = [...arrayDeleteId]
        for (const operation of operations) {
            const fromIndex = operation.fromRowIndex;
            const toIndex = operation.toRowIndex;
            const createArrIndexes = () => {
                let r = []
                for (let i = fromIndex; i < toIndex; i++) {
                    r.push(i)
                }
                return r
            };
            if (operation.type === 'CREATE') {
                resultData = value;
                setRerender(true)
            }
            if (operation.type === 'UPDATE') {
                resultData = value;
            }
            if (operation.type === 'DELETE') {
                const arrDeleteIndexes = createArrIndexes();
                const checkDelete = (index) => {
                    if (arrDeleteIndexes.includes(index)) {
                        return !checkAccessDeleteRow(index)
                    }
                    return true;
                }

                data.forEach((value, index) => {
                    if (arrDeleteIndexes.includes(index) && checkAccessDeleteRow(index)) {
                        if (value && value.id !== null && value.id !== undefined) {
                            deletedRows.push(value.id)
                        }
                    }
                })
                setArrayDeleteId(deletedRows);

                resultData = data.filter((row, index) => checkDelete(index))
                setRerender(true)
            }
        }

        setData(resultData)
    }

    const onSave = async () => {
        setIsLoad(true)
        const saveArr = emptyRowsFilter(cloneDeep(data));
        let patchedArr = []
        for (let row of saveArr) {
            patchedArr.push(patchRow(row))
        }
        let createArr = []

        for (let row of patchedArr) {
            createArr.push(row)
        }

        let updateArr = []
        for (let row of patchedArr) {
            if (row.id !== null && row.id !== undefined) {
                if (row.organizationId !== null && row.organizationId === UserStore.user.organization.id) {
                    updateArr.push(toJS(row, true))
                }
            }

        }

        let deleteArr = createParam4Deleted(cloneDeep(arrayDeleteId))


        console.log("CRT ARR: ", createArr)

        let createSuccess = true;
        let updateSuccess = true;
        let deleteSuccess = true;


        if (createArr.length > 0) {
            const responseCreate = await tableTariffsService.uploadTariffs(endpoint, {tariffs: createArr}).then(res => {
                if (res.code === 200) {
                }

                if (res.code === 400) {
                    FlashStore.errorNow(translate("common.errorEmptyFields"), true)
                    const dataClone = cloneDeep(res.data.errors)
                    setErrors(formatError(dataClone))
                }
                return res
            });
            createSuccess = responseCreate.code === 200;
            updateSuccess = responseCreate.code === 200;
            console.log("CREATE AND UPDATE DONE.", responseCreate)
        }

        /*if (updateArr.length > 0) {
            console.log("UPD ARR: ", {tariffs: updateArr})
            const responseUpdate = await tableTariffsService.updateTariffs(endpoint, {tariffs: updateArr}).then(res => {
                if (res.code === 200) {
                }

                if (res.code === 400) {
                    FlashStore.errorNow(translate("common.errorEmptyFields"), true)
                    const dataClone = cloneDeep(res.data.errors)
                    setErrors(formatError(dataClone))
                }
                return res;
            });
            updateSuccess = responseUpdate.code === 200;
            console.log("UPDATE DONE.", responseUpdate)
        }*/

        if (deleteArr.length > 0) {
            console.log("DLT ARR: ", createParam4Deleted(cloneDeep(arrayDeleteId)))
            const responseDelete = await tableTariffsService.deleteTariffs(endpoint, {tariffs: deleteArr}).then(res => res)
            deleteSuccess = responseDelete.code === 200;
            console.log("DELETE DONE.", responseDelete)
        }


        setIsLoad(false)
        if (createSuccess === true && updateSuccess === true && deleteSuccess === true)
            onAfterSave();

    }

    //Select column components

    const DictionarySelect = React.memo((props) => {
        const {
            url,
            optionsRenderer,
            valuesDefault = [],
            rowData,
            setRowData,
            placeholder = '',
            rowIndex,
            name,
            focus,
            stopEditing,
            disabled,
            additionalValues,
            multi,
            active
        } = props
        const [values, setValues] = useState([])
        const [error, setError] = useState(false)


        const selectRef = useRef()

        const [disabledField, setDisabledField] = useState(false)

        useEffect(() => {
            if (name === "id") {
                setDisabledField(true)
            } else {
                setDisabledField(disabled)
            }
        }, [data, disabled])


        useEffect(() => {
            setError(isError(errors, rowIndex, name))
        }, [errors]);

        const formatValues = (values = []) => {
            let result = []
            for (let val of values) {
                result.push({value: val.id, label: getExistLangValue(val.name)})
            }
            setValues(result)
        }

        useEffect(() => {
            if (url && typeof url === 'function')
                dictionaryService.getDictionary(url(), false).then(response => {
                    formatValues(response)
                })
        }, [url])


        useEffect(() => {
            if (valuesDefault && valuesDefault.length > 0)
                setValues(valuesDefault)
        }, [valuesDefault])


        const defaultRenderer = (option) => {
            if (option)
                return <div>{option.label}</div>
            return "-"
        }

        const onSearch = (option, input) => {
            if (input && input.length > 1) {
                return option.label.toLowerCase().includes(input.toLowerCase())
            }
            return true
        }

        const onChange = (value) => {
            if (value) {
                setRowData(value)
                setTimeout(() => stopEditing({nextRow: false}), 200)
                if (name === "seaLineId")
                    setRerender(true)
            }
        }

        useLayoutEffect(() => {
            if (selectRef && selectRef.current) {
                if (focus) {
                    selectRef.current.focus()
                } else {
                    selectRef.current.blur()
                }
            }
        }, [focus])

        useLayoutEffect(() => {
            if (selectRef && selectRef.current) {
                if(active) {
                    selectRef.current.blur()
                }
            }
        }, [active])

        const filterValues = (options, n) => {
            switch (n) {
                case "freightId": {
                    return options.filter(v => data[rowIndex] && data[rowIndex].seaLineId && data[rowIndex].seaLineId.value === v.seaLine)
                }
                default:
                    return options;
            }
        }

        let options = filterValues(values, name)

        return (
          <Select
            isMulti={multi}
            classNamePrefix={"Select5V"}
            ref={selectRef}
            menuIsOpen={focus}
            menuPortalTarget={document.body}
            placeholder={<FormattedMessage id={"form.select.defaultPlaceholder"}/>}
            className={"w-100 text-left Select5V no-border small"}
            filterOption={onSearch}
            noOptionsMessage={() => <FormattedMessage id={"form.select.noResultText"}/>}
            options={options}
            value={rowData}
            isDisabled={disabledField}
            isClearable={false}
            onChange={onChange}
            onMenuClose={() => setTimeout(e => stopEditing({nextRow: false}), 200)}
            formatOptionLabel={optionsRenderer ? optionsRenderer : defaultRenderer}
            styles={{
                menuPortal: base => ({...base, zIndex: 9999}),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: error ? 'rgba(255,119,119,0.2)' : 'transparent',
                }),
            }}
          />
        )
    })

    const SelectDeparturePortIdPortHubComponent = (data) => {
        return <DictionarySelect {...data} name={"departurePortId"} optionsRenderer={renderOptions}
                                 valuesDefault={ports}/>
    }

    const SelectDestinationPortIdPortHubComponent = (data) => {
        return <DictionarySelect {...data} name={"destinationPortId"} optionsRenderer={renderOptions}
                                 valuesDefault={ports}/>
    }

    const SelectSeaLineComponent = (data) => {
        return <DictionarySelect {...data} name={"seaLineId"} valuesDefault={seaLines}/>
    }

    const SelectSeaSurchargesComponent = (data) => {
        return <DictionarySelect {...data} optionsRenderer={renderFreight} multi={true} name={"freightId"}
                                 valuesDefault={seaTariffSurcharges}/>
    }

    const SelectFreightConditionComponent = (data) => {
        return <DictionarySelect {...data} name={"freightCondition"} valuesDefault={typeFreight}/>
    }

    const SelectCurrencyComponent = (data) => {
        return <DictionarySelect {...data} name={"currency"} valuesDefault={currincies}/>
    }

    //!Select column components

    //Integer column components

    const IntegerColumnInput = React.memo((props) => {
        const {columnData, rowData, setRowData, placeholder = '', rowIndex, name, className, focus, disabled} = props

        const inputRef = useRef();

        const [error, setError] = useState(false)

        const [disabledField, setDisabledField] = useState(false)

        useEffect(() => {
            if (name === "id") {
                setDisabledField(true)
            } else {
                setDisabledField(disabled)
            }
        }, [data, disabled])

        const onChange = (columnData) => {
            setRowData(columnData)
        }

        useEffect(() => {
            setError(isError(errors, rowIndex, name))
        }, [errors]);

        useEffect(() => {
            if (inputRef && inputRef.current)
                if (focus === true)
                    inputRef.current.focus();
                else
                    inputRef.current.blur();
        }, [focus]);


        return <span className="react-numeric-input">
        <input
          type={"number"}
          value={rowData}
          disabled={disabledField}
          placeholder={name === "id" ? translate({id: "crudForm.field.id.placeholder"}) : translate({id: "common.placeholder"})}
          onChange={(ev) => onChange(ev.target.value)}
          className="form-control"
          ref={inputRef}
          style={error ? {backgroundColor: "rgba(255,119,119,0.2)", border: "none"} : {
              border: "none",
              backgroundColor: "transparent"
          }}
        />
      </span>
    })

    const IntColumn = (data) => {
        return <IntegerColumnInput {...data} name={data.name}/>
    }

    const IdColumn = (data) => {
        set(data, 'name', "id")
        return <IntColumn {...data} name={"id"}/>
    }

    const TransitTimeColumn = (data) => {
        set(data, 'name', "transitTime")
        return <IntColumn {...data} name={"transitTime"}/>
    }

    const DemurrageTimeColumn = (data) => {
        set(data, 'name', "demurrage")
        return <IntColumn {...data} name={"demurrage"}/>
    }

    const DetentionTimeColumn = (data) => {
        set(data, 'name', "detention")
        return <IntColumn {...data} name={"detention"}/>
    }

    const DocumentDeadlineTimeColumn = (data) => {
        set(data, 'name', "documentDeadline")
        return <IntColumn {...data} name={"documentDeadline"}/>
    }

    const Freight20Column = (data) => {
        set(data, 'name', "cargoContainerRates")
        return <IntColumn {...data} name={"cargoContainerRates[0].price"}/>
    }

    const Freight40Column = (data) => {
        set(data, 'name', "cargoContainerRates")
        return <IntColumn {...data} name={"cargoContainerRates[1].price"}/>
    }

    const Freight40HCColumn = (data) => {
        set(data, 'name', "cargoContainerRates")
        return <IntColumn {...data} name={"cargoContainerRates[2].price"}/>
    }

    //!Integer column components

    //Date column components
    const DateInputColumn = (props) => {
        const {
            columnData,
            rowData,
            setRowData,
            placeholder = '',
            rowIndex,
            name = '',
            className,
            focus,
            stopEditing,
            disabled
        } = props

        const [error, setError] = useState(false)

        const [disabledField, setDisabledField] = useState(false)

        useEffect(() => {
            if (name === "id") {
                setDisabledField(true)
            } else {
                setDisabledField(disabled)
            }
        }, [data, disabled])

        const onChangeValue = (val, operations) => {
            if (val) {
                setRowData(val.format(Array.isArray(DATE_FORMAT.ru) ? DATE_FORMAT.ru[0] : DATE_FORMAT.ru));
                stopEditing({nextRow: true})
            } else
                setRowData(null)
        }

        useEffect(() => {
            setError(isError(errors, rowIndex, name))
        }, [errors]);

        return <div className={`f-element f-element-date no-border ${error ? 'error' : ''}`}>
            <DatePicker
              disabled={disabledField}
              autoFocus={false}
              locale={LANG_CODE_FULL[AppStore.userLang]}
              name={name}
              onChange={onChangeValue}
              placeholderText={translate({id: 'common.selectDate'})}
              selected={rowData != null ? moment(rowData, 'YYYY-MM-DD') : null}
              dateFormat={DATE_FORMAT[AppStore.userLang]}
              autoComplete={'off'}
              style={error ? {backgroundColor: "rgba(255,119,119,0.2)"} : {backgroundColor: "transparent"}}
              dropdownMode="select"
              popperModifiers={{
                  preventOverflow: {
                      enabled: true,
                  },
              }}
            />
        </div>
    }

    const DateFromColumn = (data) => {
        return <DateInputColumn {...data} name={"dateFrom"}/>
    }

    const DateToColumn = (data) => {
        return <DateInputColumn {...data} name={"dateTo"}/>
    }

    //!Date column components

    const [columns, setColumns] = useState([])

    const columnsByTariff = {
        SEA_TARIFFS: [
            keyColumn(
              'departurePortId',
              {
                  component: SelectDeparturePortIdPortHubComponent,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.departurePort.title"}/>,
                  minWidth: 250,
                  keepFocus: true,
                  disableKeys: true,
                  copyValue: ({rowData}) => onCopyValueSelect(rowData, ports),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, ports, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'destinationPortId',
              {
                  component: SelectDestinationPortIdPortHubComponent,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.destinationPort.title"}/>,
                  minWidth: 250,
                  keepFocus: true,
                  disableKeys: true,
                  copyValue: ({rowData}) => onCopyValueSelect(rowData, ports),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, ports, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'seaLineId',
              {
                  component: SelectSeaLineComponent,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.seaLine.title"}/>,
                  minWidth: 250,
                  keepFocus: true,
                  disableKeys: true,
                  copyValue: ({rowData}) => onCopyValueSelect(rowData, seaLines),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, seaLines, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'freightId',
              {
                  component: SelectSeaSurchargesComponent,
                  headerClassName: "",
                  title: <FormattedMessage id={"crudForm.field.surcharges"}/>,
                  minWidth: 250,
                  keepFocus: true,
                  disableKeys: true,
                  copyValue: ({rowData, rowIndex}) => onCopyValueSelect(rowData, seaTariffSurcharges),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelectSurcharges(value, seaTariffSurcharges, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'freightCondition',
              {
                  component: SelectFreightConditionComponent,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.freightCondition.title"}/>,
                  minWidth: 150,
                  keepFocus: true,
                  disableKeys: true,
                  copyValue: ({rowData}) => onCopyValueSelect(rowData, typeFreight),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, typeFreight, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'transitTime',
              {
                  component: TransitTimeColumn,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.transitTime.title"}/>,
                  minWidth: 160,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'demurrage',
              {
                  component: DemurrageTimeColumn,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.demurrage.title"}/>,
                  minWidth: 160,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'detention',
              {
                  component: DetentionTimeColumn,
                  title: <FormattedMessage id={"crudForm.field.detention"}/>,
                  minWidth: 160,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'documentDeadline',
              {
                  component: DocumentDeadlineTimeColumn,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.documentDeadline.title"}/>,
                  minWidth: 200,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'currency',
              {
                  component: SelectCurrencyComponent,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crud.field.currency.title"}/>,
                  minWidth: 150,
                  keepFocus: true,
                  disableKeys: true,
                  copyValue: ({rowData}) => onCopyValueSelect(rowData, currincies),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, currincies, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'freight20',
              {
                  component: Freight20Column,
                  title: <FormattedMessage id={"crud.field.freight20.title"}/>,
                  minWidth: 150,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'freight40',
              {
                  component: Freight40Column,
                  title: <FormattedMessage id={"crud.field.freight40.title"}/>,
                  minWidth: 150,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
            keyColumn(
              'freight40HC',
              {
                  component: Freight40HCColumn,
                  title: <FormattedMessage id={"crud.field.freight40HC.title"}/>,
                  minWidth: 150,
                  copyValue: ({rowData}) => onCopyValueText(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }
            ),
        ]
    }

    useEffect(() => {
        if (seaLines.length > 0 && currincies.length > 0 && ports.length > 0 && typeFreight.length > 0) {
            let defaultColumnsArray = []
            if (data.filter(d => d.id !== null).length > 0) {
                defaultColumnsArray.push(
                  keyColumn(
                    'id',
                    {
                        component: IdColumn,
                        headerClassName: "",
                        title: <FormattedMessage id={"crud.field.id.title"}/>,
                        minWidth: 100,
                        copyValue: ({rowData}) => onCopyValueText(rowData),
                        disabled: () => true
                    }
                  ))
            }

            defaultColumnsArray.push(keyColumn('dateFrom', {
                  component: DateFromColumn,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crudForm.field.from"}/>,
                  minWidth: 150,
                  copyValue: ({rowData}) => onCopyValueDate(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueDate(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }),
              keyColumn('dateTo', {
                  component: DateToColumn,
                  headerClassName: "cell-required",
                  title: <FormattedMessage id={"crudForm.field.to"}/>,
                  minWidth: 150,
                  copyValue: ({rowData}) => onCopyValueDate(rowData),
                  pasteValue: ({
                      rowData,
                      value,
                      rowIndex
                  }) => checkAccessAllowPaste(rowIndex) ? onPasteValueDate(value, rowData, rowIndex) : rowData,
                  disabled: ({rowIndex}) => checkDisabledField(rowIndex)
              }),)

            setColumns([
                ...defaultColumnsArray,
                ...columnsByTariff[type]
            ])
            console.log("INIT DONE")
            setIsLoad(false)
            setRerender(false)
        }
    }, [seaLines, currincies, ports, typeFreight, errors, rerender, seaTariffSurcharges]);

    useEffect(()=>{
        console.log("IS LOAD: ", isLoad)
    },[isLoad])


    return (
      <div className={"table-tariffs"}>
          {(isLoad === false) &&
            <DynamicDataSheetGrid
              addRowsComponent={createAddRowsComponent()}
              value={data}
              onChange={onChange}
              columns={columns}
              height={800}
              contextMenuComponent={ContextMenu}
              createRow={() => createTariffItem()}
              duplicateRow={({rowData}) => (onDuplicate(rowData))}
            />
          }
          {isLoad === true &&
            <div style={{minHeight: "500px"}} className={"d-flex align-items-center justify-content-center"}>
                <div className="fa-1x">
                    <i className="fa fa-spinner fa-spin" style={{fontSize: "36px"}}/>
                </div>
            </div>
          }
          <FormGroup className="btn-controls mt-3">
              <Row className="no-gutters" style={{justifyContent: 'flex-start'}}>
                  <Button disabled={isLoad} className={"btn-primary-new"} onClick={onSave}>

                      <div className={"d-flex"}>
                          {isLoad === true && <div className="fa-1x">
                              <i className="fa fa-spinner fa-spin" style={{color: '#fff'}}/>
                          </div>}

                          <div className={`${isLoad === true ? 'ml-2' : ''}`}><FormattedMessage
                            id="crudForm.button.save"/></div>
                      </div>
                  </Button>
                  <Button disabled={isLoad} className={"btn-outline-primary-new"} onClick={onCancel}><FormattedMessage
                    id="crudForm.button.cancel"/></Button>
              </Row>
          </FormGroup>
      </div>
    )
}

export default TableTariffs;
