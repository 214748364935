import rest from './restService';
import { en_lang } from './langs/messages_en';
import { ru_lang } from './langs/messages_ru';
import { zh_lang } from './langs/messages_zh';
import HttpProvider from './httpProvider';
import { API } from '../constants/api';


class L10nService {
  constructor() {
    this.rest = rest;
  }

  async messages(langCode) {
    if(langCode==="ru")
      return ru_lang
    if(langCode==="en")
      return en_lang
    if(langCode==="zh")
      return zh_lang
    return en_lang
  }

  async languages() {
    return await HttpProvider.get(API.APP.LANGS(), true);
  }
}

export default new L10nService();
