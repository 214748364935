/* eslint-disable */
/* eslint-disable import/no-duplicates */
/* eslint-disable quote-props */
/* eslint-disable arrow-parens */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React, {Component, Fragment, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import { Button, Col, Row, Table, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { forEach, forOwn, get, find, cloneDeep, isFunction, isArray, isNumber, isBoolean, compact, round } from 'lodash';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { camelCaseToTile, confirm, keyGenerator, translate, getLangInStorage, getExistLangValue } from '../../../utils/utils';
import { Checkbox } from '../form-control';
import CrudPagination from './CrudPagination';
import { Preloader } from '../../ui/Preloader';
import { WaitingStatus } from '../../common/Statuses';
import FlashStore from '../../../stores/flashStore';
import FileService from '../../../services/fileService';
import { API } from '../../../constants/api';
import { ADMIN_ORDERS, CLIENT_ORDERS, PARTNER_ORDERS, CLIENT_DELIVERY_ORDERS, PARTNER_DELIVERY_ORDERS,
  ADMIN_DELIVERY_ORDERS, PUBLIC_ORDER_CALCULATION } from '../../../constants/routes';
import { SORT_ICON_NAME } from '../../../constants/global';
import LogisticModal from '../../ui/LogisticModal';
import LogisticBlock from '../../ui/LogisticBlock';
import userStore from '../../../stores/userStore';
import UserStore from '../../../stores/userStore';
import airIcon from '../../../assets/img/icons/blue/air-icon.svg';
import seaIcon from '../../../assets/img/icons/blue/sea-icon.svg';
import trainIcon from '../../../assets/img/icons/blue/train-icon.svg';
import truckIcon from '../../../assets/img/icons/blue/truck-icon.svg';
import generalIcon from '../../../assets/img/icons/blue/generalCargo.svg';
import containerIcon from '../../../assets/img/icons/blue/containerCargo.svg';
import vehicleIcon from '../../../assets/img/icons/blue/vehicleCargo.svg';
import { ErrorsModal } from '../ErrorsModal';
import { rgba } from 'polished';
import {OrderMiniCard} from '../../Orders/OrderMiniCard';
import {
  getCargoTypeIdByCargoInfo, getFinishDateOfOrder, getStartDateOfOrder,
  getCargoSummaryForOrder, prepareOrderCardCargoInfoData, getOrderStatusName, getIndicator, prepareOrderAccordionItems,
} from '../../Orders/OrderCommon';
import OrderDocs from "../../Orders/OrderCardOrderDocs";
import orderDeliveryService from '../../../services/orderDeliveryService';
import {Modal} from "../Modal";
import LogisticPopupModal from "../LogisticPopupModal";
import ChangeOwnerOrderModal from '../../Orders/ChangeOwnerOrderModal';
import CreateFakeOrderForm from "../ModalOrder/CreateFakeOrderForm";
import ModalTableTariffInput from '../TableTariffs/ModalTableTariffInput';



@observer
class CrudListDesktop extends Component {
  state = {
    loaded: false,
    isShow: false,
    item: null,
    isAllChecked: false,
    isTrackingBlockView: localStorage.getItem('isTrackingBlockView') !== 'false',
    isLogisticBlockView: UserStore.isOperator() || (UserStore.user.organization ? UserStore.user.organization.id === 6 : false),
    isAllLogisticBlocksExpanded: false,
    modalIsOpen: false,
    logisticPopupModalIsOpen: false,
    currentIndex: 0,
    searchString: '',
    isFileUploading: false,
    isErrorsModalOpen: false,
    errorsArray: [],
    documentModalClosed: false,
    // isPopoverOpen: false,
    showModalWarning: false,
    showChangeOwnerOrderModal: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.isLoaded !== prevProps.isLoaded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loaded: this.props.isLoaded });
    }
    if(this.props.isUpdated === true){
      const {store} = this.props;
      store.reloadList();
      this.props.isUpdateFn()
    }
  }


  componentDidMount() {
    this.loadItems();
    this.props.store.bulkStore.setEditItems([]);
  }

  async handleEdit(item, readOnly) {
    const { store } = this.props;

    await store.edit(item ? item.id : null, readOnly);
  }

  setStatus =  (id, status) => {
    orderDeliveryService.setStatus(id, status)
        .then( async (response) => {
          if (response.status === 'success') {
            FlashStore.successNow(translate({ id: 'common.save.success' }), true);
            debugger;
            const {store} = this.props;
            await store.reloadList();
          } else {
            FlashStore.errorNow(translate({ id: 'common.documents.error.fieldsWithoutFile' }), true);
          }
        });
  };

  async handleBulkOperations() {
    const { store } = this.props;

    await store.bulkStore.setEditItems(store.bulkStore.bulkEditItems);
    await store.bulkStore.startBulkOperations();
  }

  async handleDelete(item) {
    const { store, enableNotifications, customRemoveMessage } = this.props;

    confirm(translate({ id: customRemoveMessage?customRemoveMessage:'crud.remove.confirm.text' }), async () => {
      const deletedItem = await store.remove(item.id);

      await store.reloadList();
      if(this.props.isManageSubscriptionPage){
        this.props.onReloadData()
      }

      if (enableNotifications && deletedItem.response.status === 'success') {
        FlashStore.successNow(translate({ id: 'crud.remove.success' }), true);
      } else if (isArray(deletedItem.response.data.errors)) {
        deletedItem.response.data.errors.forEach((err) => {
          if (enableNotifications) {
            FlashStore.errorNow(translate({ id: err.errorText }), true);
          }
        });
      } else if (enableNotifications) {
        FlashStore.errorNow(translate({ id: 'crud.remove.failed' }), true);
      }

      //setTimeout(() => FlashStore.clearNow(), 2000);
    });
  }

  async handleColumnClick(column) {
    const { store } = this.props;

    await store.switchColumnSorting(window.location.pathname.split('/').join(','), column.name);
  }

  async loadItems() {
    const { store } = this.props;

    if (store.hasService()) {
      await store.loadCollectionView(window.location.pathname.split('/').join(','));
      await store.list();
    } else {
      console.warn('CrudList with static data!');
    }

    this.setState({ loaded: true });
  }

  openModal = (index) => {
    this.setState({
      currentIndex: index,
      modalIsOpen: true,
    });
  };

  closeModal = async (isSave) => {
    if (isSave) {
      const { store } = this.props;
      await store.reloadList();
      this.setState({
        modalIsOpen: false,
      });
    } else {
      this.setState({
        modalIsOpen: false,
      });
    }
  };

  openLogisticPopupModal = (index) => {
    console.log('openLogisticPopupModal - ',index);
    this.setState({
      currentIndex: index,
      logisticPopupModalIsOpen: true,
    });
  };

  closeLogisticPopupModal = async (isSave) => {
    if (isSave) {
      const { store } = this.props;
      await store.reloadList();
      this.setState({
        logisticPopupModalIsOpen: false,
      });
    } else {
      this.setState({
        logisticPopupModalIsOpen: false,
      });
    }
  };


  openChangeOwnerModal = (index) => {
    this.setState({
      currentIndex: index,
      showChangeOwnerOrderModal: true,
    });
  };

  closeChangeOwnerModal = async (isSave) => {
    if (isSave) {
      const { store } = this.props;
      await store.reloadList();
      this.setState({
        showChangeOwnerOrderModal: false,
      });
    } else {
      this.setState({
        showChangeOwnerOrderModal: false,
      });
    }
  };

  onClickNavToOrder=(item)=>{
    if(item && item.order && item.orderCalculation) {
      let role = "";
      if (UserStore.isClient())
        role = "client"
      if (UserStore.isPartner())
        role = "partner"
      if (UserStore.isOperator())
        role = "admin"
      window.location.assign(`/${role}/orders/${item.order.id}#${item.orderCalculation.id}`);
    }
  }

  fetchColumns() {
    const { store } = this.props;
    let { children } = this.props;

    const columnHead = [];
    const columns = [];

    children = compact(children); // remove nulls and undefined from children.

    React.Children.map(children, (child, key) => {
      const { props } = child;

      const { name, width, unsortable, virtual } = props;
      let { title } = props;

      if (!title && name) {
        title = title !== ''?
            translate({ id: `crud.field.${name}.title`, defaultMessage: camelCaseToTile(name) }): '';
      } else {
        title = translate({ id: title, defaultMessage: title });
      }


      if (props.isShow) {
        columns[name] = props;
      }

      if (!unsortable && !virtual) {
        const sorting = get(store.collectionView, 'sorting', []);
        const columnSorting = find(sorting, el => el.name === name);

        const icon = SORT_ICON_NAME[(get(columnSorting, 'type'))] || null;

        title = (<span onClick={() => this.handleColumnClick(props)} className="title">
          {title} <FontAwesome name={!icon ? 'sort' : icon} />
        </span>);
      }

      if (props.isShow) {
        columnHead.push(
            <th className={`cell-${name}`} key={key} width={width || 'auto'}>
              {title}
            </th>
        );
      }
    });

    return { columns, columnHead };
  }

  renderDeleteBtn(item) {
    if(this.props.isManageSubscriptionPage){
      if(item.status === "DECLINE" || item.status === "ACCEPT")
        return null;
    }
    if(this.props.isDelete===false)
      return null

    if (item.deletable === undefined || item.deletable) {
      return (
          <Button
              size="sm"
              color="danger"
              onClick={() => this.handleDelete(item)}
              className="btn-remove-crud-list"
          />
      );
    }

    return null;
  }

  async downloadDocument(item) {
    const isOrderToLogist = item.kind.uid === 'ORDER_TO_LOGISTICIAN';
    const url = isOrderToLogist
        ? API.DOCUMENTS.GENERATE_AND_DOWNLOAD_FILE({ item })
        : API.DOCUMENTS.DOWNLOAD_FILE({ item });
    await FileService.downloadFile(url, item.file.filename);
  }

  async sendDocument(item) {
    const { store } = this.props;
    const isOrderToLogist = item.kind.uid === 'ORDER_TO_LOGISTICIAN';
    const res =  isOrderToLogist ? await store.generateAndSendEmail(item.id) : await store.sendEmail(item.id);
    if (res === undefined) {
      FlashStore.successNow(translate({ id: 'documents.email.success' }), true);
    }
  }

  onChangeFile = async (e, item) => {
    const { store } = this.props;
    let file = get(e, 'target.files[0]', null);

    if (file && file.size > 20000000) {
      FlashStore.errorNow(translate({ id: 'common.documents.error.file' }), true);
      file = null;
      this.fileInput.value = '';
    }
    if (file) {
      if (userStore.isOperator() || userStore.user.id === +item.userId) {
        confirm(translate({ id: 'documents.replace.doc' }), async () => {
          await store.updateFile(item.id, file);
          if (item.type === 'ANOTHER') {
            await store.update(item.id, item, file.name);
          }
          FlashStore.successNow(translate({ id: 'trunking.form.uploadSuccess' }), true);
          await store.reloadList();
        });
      }
    }
  };

  onCreateDoc = async (file, type, title) => {
    const { store } = this.props;
    //let file = get(e, 'target.files[0]', null);
    const orderDeliveryRequestGroupId = (window.location.href.split('/')[window.location.href.split('/').length - 1]);

    if (file && file.size > 20000000) {
      FlashStore.errorNow(translate({ id: 'common.documents.error.file' }), true);
      file = null;
      this.fileInput.value = '';
    }

    await store.create({
      title: title,
      type: 'CUSTOM',
      kind: type,
      file,
      orderDeliveryRequestGroupId,
    }, true)
        .then( response => response.json() )
        .then((resp) => {
          if(resp.code >= 400){
            FlashStore.errorNow(translate({id: 'reports.alert.fail'}), true);
          } else {
            FlashStore.successNow(translate({ id: 'trunking.form.uploadSuccess' }), true);
          }
        })
        .catch(err => console.error(err));

    this.setState({
      documentModalClosed: true,
    });
    await store.reloadList();
  };

  makeBody(columns, items) {
    const body = [];

    const actions = (item) => {
      if (isFunction(this.props.renderActionsButtons)) {
        return (
            <td key={'-'} className="cell-action d-flex justify-content-center">
              {this.props.renderActionsButtons({ item, store: this.props.store })}
            </td>
        );
      }

      if (isNumber(this.props.renderActionsButtons)) {
        if (item.organizationId !== this.props.renderActionsButtons) {
          return (
              <td key={'-'} className="cell-action d-flex justify-content-center">
                <Button
                    size="sm"
                    onClick={() => this.props.store.edit(item ? item.id : null, true)}
                    className="btn-view-crud-list mr-2"
                />
              </td>
          );
        }
      }

      if (isBoolean(this.props.isAdminOnlyView)) {
        if (this.props.isAdminOnlyView) {
          return (
              <td key={'-'} className="cell-action d-flex justify-content-center">
                <Button
                    size="sm"
                    onClick={() => this.props.store.edit(item ? item.id : null, true)}
                    className="btn-view-crud-list mr-2"
                />
              </td>
          );
        }
      }
      if (this.props.isDocs && userStore.isPartner()) {
        // eslint-disable-next-line no-param-reassign
        item.deletable = userStore.user.id === +item.userId;
        // eslint-disable-next-line no-param-reassign
        item.canEdit = userStore.user.id === +item.userId;
      } else {
        // eslint-disable-next-line no-param-reassign
        item.canEdit = true;
      }
      if (this.props.isDocs) {
        return (
            <td key={'-'} className="cell-action d-flex justify-content-center">
              { this.props.isDocs &&
              <Button
                  size="sm"
                  onClick={() => this.downloadDocument(toJS(item))}
                  color="info"
                  className="btn-download-crud-list mr-2"
              />
              }
              { this.props.isDocs &&
              <Button
                  size="sm"
                  onClick={() => this.sendDocument(item)}
                  color="success"
                  className="btn-email-crud-list mr-2"
              />
              }
              { this.props.isDocs && userStore.user && (userStore.isOperator() || userStore.user.id === +item.userId) &&
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={`load-file-private-${item.id}`}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onChangeFile(e, item)}
                />
                <Label
                    for={`load-file-private-${item.id}`}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
              }
              { this.props.isDocs && userStore.user && (userStore.isOperator() || userStore.user.id === +item.userId) &&
              <Button
                  size="sm"
                  onClick={() => (this.props.onEdit ? this.props.onEdit(item) : this.handleEdit(item, false))}
                  color="success"
                  className="btn-view-crud-list mr-2"
              />
              }
              { (userStore.user && (userStore.isOperator() || userStore.user.id === +item.userId)) && this.renderDeleteBtn(item) }
            </td>
        );
      }
      return (
          <td key={'-'} className="cell-action">
            <div className={"d-flex justify-content-end"}>
            {item.needUpdate === true &&
                <div>
                  <Button
                      size="sm"
                      onClick={() => this.props.onView(item, true)}
                      color="danger"
                      className="btn-update-crud-list mr-2"
                  />
                </div>
            }
            {!this.props.isOnlyDelAction && this.props.onView && item.canEdit && this.props.isEdit===true &&
                <div>
                  <Button
                      size="sm"
                      onClick={() => this.props.onView(item)}
                      color="success"
                      className="btn-view-crud-list mr-2"
                  />
                </div>
            }
            {!this.props.isOnlyDelAction && !this.props.onView && item.canEdit && this.props.isEdit===true &&
                <div>
                  <Button
                      size="sm"
                      onClick={() => (this.props.onEdit ? this.props.onEdit(item) : this.handleEdit(item, false))}
                      color="info"
                      className="btn-view-crud-list mr-2"
                  />
                </div>
            }

            {this.renderDeleteBtn(item)}
            </div>
          </td>
      );
    };

    // массовые операции
    const checkboxes = (item) => {
      return (
          <td key={keyGenerator} className="cell-ckeckbox">
            <Checkbox
                name="bulk-operations"
                checked={!!this.props.store.bulkStore.bulkEditItems.find(el => el === item.id)}
                onChange={() => this.handleCheckBulkOperations(item)}
            />
          </td>
      );
    };
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'INVOICE').length > 0) {
      body.push(
          <tr
              className="cell-action"
              key="invoice"
          >
            <td className="cell-file" key="file">
              <b>Инвойс</b>
            </td>
            <td className="cell-created" key="created">
              -
            </td>
            <td className="cell-userName" key="userName">
              -
            </td>
            <td className="cell-action d-flex justify-content-center" key="-">
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-invoice'}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onCreateDoc(e, 'INVOICE')}
                />
                <Label
                    for={'load-file-private-invoice'}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            </td>
          </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'PACKING_LIST').length > 0) {
      body.push(
          <tr
              className="cell-action"
              key="packing-list"
          >
            <td className="cell-file" key="file">
              <b>Упаковочный лист</b>
            </td>
            <td className="cell-created" key="created">
              -
            </td>
            <td className="cell-userName" key="userName">
              -
            </td>
            <td className="cell-action d-flex justify-content-center" key="-">
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-packing-list'}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onCreateDoc(e, 'PACKING_LIST')}
                />
                <Label
                    for={'load-file-private-packing-list'}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            </td>
          </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && this.props.documentContainer.mainDocument && !items.filter(item => item.type === 'BILL').length > 0) {
      body.push(
          <tr
              className="cell-action"
              key="conosament"
          >
            <td className="cell-file" key="file">
              <b><FormattedMessage id={`${this.props.documentContainer.mainDocument.typeDescription}_WITHOUT_SYMBOL`} /></b>
            </td>
            <td className="cell-created" key="created">
              -
            </td>
            <td className="cell-userName" key="userName">
              -
            </td>
            <td className="cell-action d-flex justify-content-center" key="-">
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-conosament'}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onCreateDoc(e, 'BILL', this.props.documentContainer.mainDocument.typeDescription)}
                />
                <Label
                    for={'load-file-private-conosament'}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            </td>
          </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && this.props.documentContainer.homeDocument && !items.filter(item => item.type === 'HOME_BILL').length > 0) {
      body.push(
          <tr
              className="cell-action"
              key="home-conosament"
          >
            <td className="cell-file" key="file">
              <b><FormattedMessage id={`${this.props.documentContainer.homeDocument.typeDescription}_WITHOUT_SYMBOL`} /></b>
            </td>
            <td className="cell-created" key="created">
              -
            </td>
            <td className="cell-userName" key="userName">
              -
            </td>
            <td className="cell-action d-flex justify-content-center" key="-">
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-home-conosament'}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onCreateDoc(e, 'HOME_BILL', this.props.documentContainer.homeDocument.typeDescription)}
                />
                <Label
                    for={'load-file-private-home-conosament'}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            </td>
          </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'FREIGHT_INVOICE').length > 0) {
      body.push(
          <tr
              className="cell-action"
              key="freight"
          >
            <td className="cell-file" key="file">
              <b>Счет за фрахт</b>
            </td>
            <td className="cell-created" key="created">
              -
            </td>
            <td className="cell-userName" key="userName">
              -
            </td>
            <td className="cell-action d-flex justify-content-center" key="-">
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-freight'}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onCreateDoc(e, 'FREIGHT_INVOICE')}
                />
                <Label
                    for={'load-file-private-freight'}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            </td>
          </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'CERTIFICATE').length > 0) {
      body.push(
          <tr
              className="cell-action"
              key="sertificate"
          >
            <td className="cell-file" key="file">
              <b>Сертификат</b>
            </td>
            <td className="cell-created" key="created">
              -
            </td>
            <td className="cell-userName" key="userName">
              -
            </td>
            <td className="cell-action d-flex justify-content-center" key="-">
              <div>
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-sertificate'}
                    name="file"
                    type="file"
                    className="d-none"
                    onChange={(e) => this.onCreateDoc(e, 'CERTIFICATE')}
                />
                <Label
                    for={'load-file-private-sertificate'}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            </td>
          </tr>
      );
    }

    forEach(items, (item, key) => {
      const rowItems = [];

      forOwn(columns, (data, name) => {
        const value = data.virtual ? item : get(item, name);
        let formattedValue = value;

        if (data.format) {
          // Позволяет избежать багов в реализации format функций.
          formattedValue = value !== undefined ? data.format(value, data, { item, store: this.props.store }) : '-';
        } else if (!formattedValue) {
          formattedValue = '-';
        }

        // Добавление store в пропсы там, где он нужен
        let newFormattedValue = formattedValue;
        if (data.needStore) {
          newFormattedValue = React.cloneElement(
              formattedValue,
              {
                store: this.props.store,
              },
          );
        }

        rowItems.push(
            <td
                className={`cell-${name}`}
                key={name}
                // style={{ textAlign: data.align ? data.align : this.props.align }}
            >{newFormattedValue}</td>,
        );
      });

      rowItems.push(actions(item));

      // массовые операции
      if (this.props.bulkOperations) {
        rowItems.unshift(checkboxes(item));
      }

      // const doubleClickHandler = () => (this.props.onAdd ? this.props.onAdd() : this.handleEdit(null, false));
      if (item.valid === false) {
        body.push(
            <tr
                className="cell-action expired"
                // onDoubleClick={doubleClickHandler}
                key={key}
            >
              {rowItems}
            </tr>
        );
      } else {
        body.push(
            <tr
                className={`cell-action ${item.expired ? 'expired' : ''}`}
                // onDoubleClick={doubleClickHandler}
                key={key}
            >
              {rowItems}
            </tr>
        );
      }
    });

    return body;
  }

  uploadCSVFile = (e) => {
    const file = get(e, 'target.files[0]', null);
    e.target.value = '';
    if (file) {
      this.setState({ isFileUploading: true }, () => {
        let url = API.BASE(`${this.props.endpoint}`);
        if(this.props.charging){
          url = API.BASE(`${this.props.endpoint}`)+`?charging=${this.props.charging}`;
        }
        FileService.createFile(url, file)
            .then(async (res) => {
              if (res.status === 200) {
                const response = await res.json();
                FlashStore.successNow(translate({ id: 'trunking.form.uploadSuccess' }), true);
                this.setState({
                  ...this.state,
                  isFileUploading: false,
                });
                await this.props.store.reloadList();
                // обновляем таблицу, если ответ 200
              } else if (res.status === 400) {
                const response = await res.json();
                FlashStore.errorNow(translate({ id: 'trunking.form.uploadError' }), true);
                this.setState({ isFileUploading: false, isErrorsModalOpen: true, errorsArray: response.data.errors });
                // если ответ 400, надо будет вывести попап с ошибками
              } else {
                FlashStore.errorNow(translate({ id: 'trunking.form.uploadError' }), true);
                this.setState({ isFileUploading: false });
                console.log('Uploading fetch error');
                // если ответ 500, то что-то пошло не так на сервере при парсинге
              }
            }).catch((err) => {
          this.setState({ isFileUploading: false });
          FlashStore.errorNow(translate({ id: 'trunking.form.uploadError' }), true);
          console.error(err);
        });
      });
    }
  };

  async downloadFile(endpoint) {
    const url = API.BASE(`${endpoint}`);
    let filename = `${endpoint.split('/')[2]}-for-${endpoint.split('/')[1]}.xlsx`;
    if (endpoint.split('/')[2] === 'excel') {
      filename = 'zip-code-range-for-road-transport.xlsx';
    }
    await FileService.downloadFile(url, filename);
  }

  onSearch = (e) => {
    this.setState({
      ...this.state,
      searchString: e.target.value,
    });
  };

  searchKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.searchAction(e);
    }
  };

  searchAction = () => {
    this.setState({ loaded: false });
    const { store } = this.props;
    store.defaultListParams.searchValue = this.state.searchString;
    store.loadCollectionView(window.location.pathname.split('/').join(','));
    store.list(false, false, true).then(() => this.setState({ loaded: true }));
  };

  getTopControlButtonsForTrackingBlock = (isLogisticBlocks, totalAmount = 0) => {
    const activeModeColor = rgba(73, 150, 255, 0.15);
    const passiveModeColor = rgba(214, 214, 214, 0.1);
    const thColor = isLogisticBlocks? passiveModeColor: activeModeColor;
    const listColor = isLogisticBlocks? activeModeColor: passiveModeColor;

    return <Fragment>
      <div style={{
        display: 'flex',
        marginTop: '48px'
      }}>
        <h2 style={{fontSize: '32px', lineHeight: '32px'}}>
          {translate('person.delivery')} <span style={{
          fontSize: '14px', lineHeight: '24px', color: '#777777'}}>({totalAmount})</span>
        </h2>
      </div>
      <CreateFakeOrderForm onCreate={()=>{}}/>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '32px',
        marginTop: '32px'
      }}>

        <div style={{position: 'relative', display: 'flex', flex: '1 1 80%'}}>

          <input
              className="search-bar"
              onChange={e => this.onSearch(e)}
              onKeyDown={e => this.searchKeyDown(e)}
              value={this.state.searchString}
              placeholder={translate('deliveryOrders.orderList.searchPlaceholder')}
              style={{
                padding: '12px 12px 12px 45px',
                height: '48px',
                borderRadius: '3px',
              }}
          />
          <FontAwesome name="search" style={{position: 'absolute', top: '15px', left: '18px', cursor: 'pointer'}}
                       onClick={e => this.searchAction(e)}/>
          <Button
              onClick={e => this.searchAction(e)}
              style={{
                backgroundColor: '#3AB0D8',
                color: '#FFFFFF',
                width: '108px',
                textTransform: 'upperCase',
                borderRadius: '3px',
                marginLeft: '8px',
                fontWeight: 'boild',
                fontSize: '11px',
                letterSpacing: '0.03em',
              }}
          >
            {translate('deliveryOrders.orderList.searchBtnTitle')}
          </Button>
          <Button
              id="view-btn-3"
              color={this.state.isTrackingBlockView ? 'primary' : 'secondary'}
              onClick={() => {
                this.setState({isTrackingBlockView: true}, () => {
                  localStorage.setItem('isTrackingBlockView', `${this.state.isTrackingBlockView}`);
                });
              }}
              style={{
                marginLeft: '24px',
                width: '48px',
                height: '48px',
                borderRadius: '3px',
                color: '#C4C4C4',
                backgroundColor: thColor,
                border: '1px solid #D3D8E0',
                boxSizing: 'border-box',
              }}
          >
            <FontAwesome name="th"/>
          </Button>
          <Button
              id="view-btn-4"
              color={this.state.isTrackingBlockView ? 'primary' : 'secondary'}
              onClick={() => {
                this.setState({isTrackingBlockView: false}, () => {
                  localStorage.setItem('isTrackingBlockView', `${this.state.isTrackingBlockView}`);
                });
              }}
              style={{
                marginLeft: '8px',
                width: '48px',
                height: '48px',
                borderRadius: '3px',
                color: '#C4C4C4',
                backgroundColor: listColor,
                border: '1px solid #D3D8E0',
                boxSizing: 'border-box',
              }}
          >
            <FontAwesome name="list"/>
          </Button>
        </div>

        {/*<Button*/}
        {/*    disabled*/}
        {/*    // color="success"*/}
        {/*    className={'excel-report'}*/}
        {/*    onClick={() => console.log('download')}*/}
        {/*    style={{*/}
        {/*      padding: '12px 20px',*/}
        {/*      marginLeft: '24px',*/}
        {/*      border: '1px solid #62CFA8',*/}
        {/*      textTransform: 'upperCase',*/}
        {/*      borderRadius: '3px',*/}
        {/*      fontWeight: 'boild',*/}
        {/*      fontSize: '11px',*/}
        {/*      color: '#62CFA8',*/}
        {/*      letterSpacing: '0.03em',*/}
        {/*      backgroundColor: rgba(98, 207, 168, 0.15),*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <span*/}
        {/*        style={{*/}
        {/*          fontSize: '16px',*/}
        {/*          paddingRight: '12px'*/}
        {/*        }}*/}
        {/*    >*/}
        {/*      <FontAwesome name='file-excel-o'/>*/}
        {/*    </span>*/}
        {/*  { translate({ id: 'deliveryOrders.orderList.excelReport' }) }*/}
        {/*</Button>*/}
      </div>
    </Fragment>
  };

  getTopControlButtonsForLogisticBlocks = () =>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
        <Button
            id="view-btn"
            color={this.state.isTrackingBlockView ? 'primary' : 'secondary'}
            onClick={() => {
              this.setState({ isTrackingBlockView: !this.state.isTrackingBlockView }, () => {
                localStorage.setItem('isTrackingBlockView', `${this.state.isTrackingBlockView}`);
              });
            }}
        >
          <FontAwesome name="th" />
        </Button>
        <div style={{ position: 'relative' }} >
          <input className="search-bar" onChange={e => this.onSearch(e)} style={{ width: '39rem' }} onKeyDown={e => this.searchKeyDown(e)} value={this.state.searchString} />
          <FontAwesome name="search" style={{ position: 'absolute', top: '10px', left: '6px', cursor: 'pointer' }} onClick={e => this.searchAction(e)} />
        </div>
        <Button
            disabled
            color="success"
            onClick={() => console.log('download')}
        >
          <FormattedMessage id="logisticBlocks.inExcel" />
        </Button>
        <div>
          <FontAwesome name="minus-square" className="manage-all" onClick={() => this.setState({ isAllLogisticBlocksExpanded: false })} />
          <FontAwesome name="plus-square" className="manage-all" onClick={() => this.setState({ isAllLogisticBlocksExpanded: true })} />
        </div>
      </div>;


  getTopControlsButton(isLogisticBlocks = false) {
    const { isAdd, isDocs, bulkOperations, downloadCSV, endpoint, isTrackingBlock, uploadCSV,uploadTable, store: {total}, exampleUrl, tableTariffsType, tableTariffsEndpoint } = this.props;

    if (isTrackingBlock && isLogisticBlocks) {
      return this.getTopControlButtonsForTrackingBlock(isLogisticBlocks, total);
      // return this.getTopControlButtonsForLogisticBlocks();
    }

    if (isTrackingBlock) {
      return this.getTopControlButtonsForTrackingBlock(isLogisticBlocks, total);
    }

    if (!isAdd && isDocs) {
      return (
          <div className="crud-list__inner_actions">
            <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-another'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'ANOTHER')}
            />
            <Label
                for={'load-file-private-another'}
            >
              <div className="new-doc-btn"><FormattedMessage id="crud.add.record" /></div>
            </Label>
          </div>
      );
    }

    if (!isAdd) {
      return null;
    }
    const tooltipStyle = {
      visibility:this.state.hover ? 'visible' : 'hidden',
      display: 'block',
      top:this.state.topPos-this.state.heightTooltip-20,
      left: this.state.leftPos

    }
    const handleMouseIn = (el) => {
      this.setState({ hover: true })
      this.setState({ topPos: this.btnFileInput.getBoundingClientRect().top});
      this.setState({ leftPos: this.btnFileInput.getBoundingClientRect().left});
      this.setState({ heightTooltip: this.tooltipFileInput.offsetHeight});
      console.log("H",this.tooltipFileInput.offsetHeight)
    }
    const showWarningModalDownload = () =>{
      this.setState({showModalWarning:true})
    }

    const handleMouseOut = (el) => {
      this.setState({ hover: false })
    }
    return (
        <div className="crud-list__inner_actions">
          <Preloader isShow={this.state.isFileUploading} />
          <ErrorsModal isShown={this.state.isErrorsModalOpen} errors={this.state.errorsArray} onClose={() => this.setState({ isErrorsModalOpen: false })} />
          <Button
              color="primary"
              onClick={() => (this.props.onAdd ? this.props.onAdd() : this.handleEdit(null, false))}
          >
            {
              (window.location.pathname === ADMIN_ORDERS ||
                  window.location.pathname === PARTNER_ORDERS ||
                  window.location.pathname === CLIENT_ORDERS ||
                  window.location.pathname === PUBLIC_ORDER_CALCULATION) ?
                  <FormattedMessage id="crud.add.newOrder" />
                  : <FormattedMessage id="crud.add.record" />
            }

          </Button>{' '}
          {
            uploadCSV ?
                <div style={{ display: 'contents' }}>
                  <div onMouseOver={handleMouseIn.bind(this)} onMouseOut={handleMouseOut.bind(this)} style={{ display: 'contents' }}>
                    <input
                        ref={(ref) => { this.fileInput = ref; }}
                        id="load-file"
                        name="file"
                        type="file"
                        className="d-none"
                        onChange={this.uploadCSVFile}
                        accept=".xls, .xlsx"
                    />

                    <div ref={(ref)=>{this.tooltipFileInput = ref;}} className="tooltip-load" style={tooltipStyle}>
                      <FormattedMessage id="crud.uploadFromExcel.tooltip" />
                    </div>
                    <Label for="load-file" className="btn btn-primary" style={{ margin: '0' }} >
                      <div
                          ref={(ref)=>{this.btnFileInput = ref;}}
                      />
                      <FormattedMessage id="crud.uploadFromExcel" />
                    </Label>
                  </div>
                  <Modal title={<FormattedMessage id="crud.uploadFromExcel.example.header" />} isModal={true} isShow={this.state.showModalWarning} onClose={()=>this.setState({showModalWarning:false})}>
                    <div className="mx-auto text-center"><FormattedMessage id="crud.uploadFromExcel.example.body.text"/></div>
                    <div className="d-flex">
                      <div onClick={()=> {
                        this.setState({showModalWarning: false});
                        window.open(exampleUrl, '_blank');
                      }} className="btn btn-primary mt-2 mx-auto" >
                        <FormattedMessage id="crud.uploadFromExcel.example.body.btn.download" />
                      </div>
                    </div>
                  </Modal>
                  <div onClick={()=>showWarningModalDownload()} className="btn btn-primary" style={{ marginLeft: '5px' }}>
                    <FormattedMessage id="crud.uploadFromExcel.example" />
                  </div>

                </div>: null
          }{' '}
          {uploadTable ?
            <ModalTableTariffInput bulkEditItems={this.props.store.bulkStore.bulkEditItems}
                                   tableTariffsEndpoint={tableTariffsEndpoint}
                                   items={this.props.store.items}
                                   tableTariffsType={tableTariffsType}
                                   onAfterSave={() => {
                                     this.props.store.reloadList();
                                   }}
            /> : null}{' '}
          {
            bulkOperations ?
                <Button
                    disabled={this.props.store.bulkStore.bulkEditItems.length === 0}
                    color="secondary"
                    onClick={() => this.handleBulkOperations()}
                    style={{marginTop: "4px"}}

                >
                  <span>{ translate({ id: 'crud.edit.bulkOperation' }) }{' '}({this.props.store.bulkStore.bulkEditItems.length})</span>
                </Button> : null
          }{' '}
          {
            downloadCSV ?
                <Button
                    disabled={this.props.store.items.length === 0}
                    color="success"
                    onClick={() => this.downloadFile(endpoint)}
                    style={{ marginTop: uploadCSV ? '4px' : '0' }}
                >
                  <span>{ translate({ id: 'crud.edit.downloadCSV' }) }</span>
                </Button> : null
          }
        </div>
    );
  }

  startPagination = () => {
    if (this.state.loaded) {
      this.setState({ loaded: false });
    }
  };

  finishPagination = () => {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  };


  getPagination() {
    const { isPagination, store, isTrackingBlock } = this.props;

    return isPagination ?
        <CrudPagination
            store={store}
            isOrdersList={isTrackingBlock}
            startPagination={() => this.startPagination()}
            finishPagination={() => this.finishPagination()}
        /> :
        null;
  }

  handleCheckBulkOperations = (item) => {
    const checked = toJS(this.props.store.bulkStore.bulkEditItems);
    const found = checked.find(el => el === item.id);

    if (found) {
      this.props.store.bulkStore.setEditItems(checked.filter(el => el !== found));
    } else {
      const fields = cloneDeep(checked);

      fields.push(item.id);
      this.props.store.bulkStore.setEditItems(fields);
    }
  };

  handleCheckAll(allItemsOnPage) {
    const { isAllChecked } = this.state;

    if (isAllChecked) {
      this.setState({
        isAllChecked: !isAllChecked,
      });
      this.props.store.bulkStore.setEditItems([]);
    } else {
      this.setState({
        isAllChecked: !isAllChecked,
      });
      this.props.store.bulkStore.setEditItems(toJS(allItemsOnPage).map(el => el.id));
    }
  }

  prepareOrderDocsData = (data) => {
    const findDocByType = (data, typeName) => {
      const docs = data.filter(x => x.type === typeName);
      if (docs){
        return docs[0];
      }
    };

    const docsData = [];

    for (let item of data.filter(x => x)) {
      docsData.push({
        id: item.id,
        doctypeId: item.kind ? item.kind.uid : '',
        documentType: item.kind ? getExistLangValue(item.kind.name ) : '',
        name: item.title,
        creationData: item.created,
        authorName: item.userName,
        fileName: item.file ? item.file.filename : null,
        authorPost: null,
        onDelete: () => this.handleDelete(item),
        onDownload: () => this.downloadDocument(toJS(item)),
        onRename: () => this.props.store.edit(item ? item.id : null, false),
        onSend: () => this.sendDocument(item)
      });
    }

    return docsData;
  };

  render() {
    if (!this.state.loaded) {
      return <WaitingStatus />;
    }

    // TODO: Возможно стоит вынести в UNSAFE_componentWillMount чтобы не устраивать постоянный перебор свойств.
    const { store, isTrackingBlock, trackingInfo } = this.props;
    const { columns, columnHead } = this.fetchColumns();

    if (this.props.isDocs && this.props.orderDocs) {
      return <OrderDocs
          adminMode={this.props.adminMode}
          data={this.prepareOrderDocsData(store.items)}
          onCreateDoc={this.onCreateDoc}
          documentModalClosed={this.state.documentModalClosed}
          trackingInfo = {this.props.trackingInfo}
          contacts={this.props.contacts} />;
    }

    const body = this.makeBody(columns, store.items);
    const { title } = this.props;

    if (isTrackingBlock && this.state.isTrackingBlockView) {
      const items = store.items;
      const getIcon = (type) => {
        if (type === 'ADDRESS') {
          return 'map-marker';
        }
        if (type === 'SEA_PORT') {
          return 'anchor';
        }
        if (type === 'AIRPORT_HUB') {
          return 'plane';
        }
        if (type === 'RAILWAY_HUB') {
          return 'subway';
        }
        return '';
      };

      const getLink = () => {
        if (userStore.isClient()) {
          return CLIENT_DELIVERY_ORDERS;
        }
        if (userStore.isPartner()) {
          return PARTNER_DELIVERY_ORDERS;
        }
        return ADMIN_DELIVERY_ORDERS;
      };

      const getStatus = (trackingDetails) => {
        if (trackingDetails.lastCompleted) {
          if (trackingDetails.lastCompleted.placeName) {
            return `${trackingDetails.lastCompleted.placeName} ${trackingDetails.lastCompleted.name}`;
          }
          return `${trackingDetails.lastCompleted.name}`;
        }
        return 'N/A';
      };

      const getTransportTypeIcon = (orderCalculation) => {
        if (orderCalculation.transportationType === 'AIR') {
          return (
              <img src={airIcon} alt="" />
          );
        }
        if (orderCalculation.transportationType === 'SEA') {
          return (
              <img src={seaIcon} alt="" />
          );
        }
        if (orderCalculation.transportationType === 'RAILWAY') {
          return (
              <img src={trainIcon} alt="" style={{ width: '48px' }} />
          );
        }
        if (orderCalculation.transportationType === 'ROAD') {
          return (
              <img src={truckIcon} alt="" />
          );
        }
        return '';
      };

      const getCargoTypeIcon = (cargo) => {
        if (cargo.general) {
          return (
              <img src={generalIcon} alt="" />
          );
        }
        if (cargo.container) {
          return (
              <img src={containerIcon} alt="" />
          );
        }
        if (cargo.vehicleCargo) {
          return (
              <img src={vehicleIcon} style={{ height: '46px' }} alt="" />
          );
        }
        return '';
      };

      const getCargoSummary = (cargo) => {
        if (cargo.generals) {
          const cargoSummary = cargo.generals.map(item => `${item.amount} x (${item.length}, ${item.width}, ${item.height})`).join(', ');
          return cargoSummary;
        }
        if (cargo.containers) {
          const twentyContainers = cargo.containers.filter(item => item.type.name.en === "20'");
          const fourtyContainers = cargo.containers.filter(item => item.type.name.en === "40'");
          const fourtyHCContainers = cargo.containers.filter(item => item.type.name.en === "40'HC");
          const getContainerCount = (amount, type, isLast = false) => {
            if (amount === 0) {
              return '';
            }
            if (amount === 1) {
              return (!isLast) ? `${type}, ` : `${type}`;
            }
            return (!isLast) ? `${amount} x ${type}, ` : `${amount} x ${type}`;
          };
          const result = `${getContainerCount(twentyContainers.length, "20'", (fourtyHCContainers.length === 0 && fourtyContainers.length === 0))}${getContainerCount(fourtyContainers.length, "40'", (fourtyHCContainers.length === 0))}${getContainerCount(fourtyHCContainers.length, "40'HC", true)}`;
          return result;
        }
        if (cargo.vehicle) {
          return cargo.vehicle.type.name[getLangInStorage()];
        }
        return '-';
      };

      const getDaysLeftWord = (item) => {
        const number = Math.round(((moment(getFinishDate(item))) - (moment().set({ 'h': 0, 'm': 0, 's': 0, 'ms': 0 }))) / 86000000);
        const cases = [2, 0, 1, 1, 1, 2];
        const titles = (getLangInStorage() === 'ru') ? ['день', 'дня', 'дней'] : ['day', 'days', 'days'];
        return (number > 0) ? `${titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]}` : '';
      };

      const getDaysLeft = (item) => {
        const result = Math.round(((moment(getFinishDate(item))) - (moment().set({ 'h': 0, 'm': 0, 's': 0, 'ms': 0 }))) / 86000000);
        return (result > 0) ? result : '-';
      };

      const getStartDate = (item) => {
        let startDate = null;
        if (!item.trackingInfo) {
          return '-';
        }
        if (item.trackingInfo.pointOfPickup) {
          if (item.trackingInfo.pointOfPickup.load.fact) {
            startDate = item.trackingInfo.pointOfPickup.load.fact;
          } else {
            startDate = item.trackingInfo.pointOfPickup.load.plan;
          }
        } else if (item.trackingInfo.pointOfOrigin) {
          if (item.trackingInfo.pointOfOrigin.arrival.fact) {
            startDate = item.trackingInfo.pointOfOrigin.arrival.fact;
          } else {
            startDate = item.trackingInfo.pointOfOrigin.arrival.plan;
          }
        }
        return startDate;
      };

      const getFinishDate = (item) => {
        let finishDate = null;
        if (!item.trackingInfo) {
          return '-';
        }
        if (item.trackingInfo.pointOfDestination) {
          if (item.trackingInfo.pointOfDestination.arrival.fact) {
            finishDate = item.trackingInfo.pointOfDestination.arrival.fact;
          } else {
            finishDate = item.trackingInfo.pointOfDestination.arrival.plan;
          }
        } else if (item.trackingInfo.pointOfArrival) {
          if (item.trackingInfo.pointOfArrival.arrival.fact) {
            finishDate = item.trackingInfo.pointOfArrival.arrival.fact;
          } else {
            finishDate = item.trackingInfo.pointOfArrival.arrival.plan;
          }
        }
        return finishDate;
      };

      const isPlanStartDate = (item) => {
        if (!item.trackingInfo) {
          return '-';
        }
        if (item.trackingInfo.pointOfPickup) {
          if (item.trackingInfo.pointOfPickup.load.fact) {
            return false;
          }
          return true;
        } else if (item.trackingInfo.pointOfOrigin) {
          if (item.trackingInfo.pointOfOrigin.arrival.fact) {
            return false;
          }
          return true;
        }
        return true;
      };

      const isPlanFinishDate = (item) => {
        if (!item.trackingInfo) {
          return '-';
        }
        if (item.trackingInfo.pointOfDestination) {
          if (item.trackingInfo.pointOfDestination.arrival.fact) {
            return false;
          }
          return true;
        } else if (item.trackingInfo.pointOfArrival) {
          if (item.trackingInfo.pointOfArrival.arrival.fact) {
            return false;
          }
          return true;
        }
        return true;
      };

      const getProgressBar = (item) => {
        if (!item.trackingInfo) {
          return 0;
        }
        if (item.trackingInfo && (item.trackingInfo.pointOfPickup || item.trackingInfo.pointOfOrigin) && (item.trackingInfo.pointOfDestination || item.trackingInfo.pointOfArrival)) {
          let startPoint = null;
          let finishPoint = null;
          if (item.trackingInfo.pointOfDestination && item.trackingInfo.pointOfDestination.arrival.fact) {
            return 100;
          }
          if (!item.trackingInfo.pointOfDestination && item.trackingInfo.pointOfArrival.arrival.fact) {
            return 100;
          }
          if (item.trackingInfo.pointOfPickup && item.trackingInfo.pointOfPickup.load.fact) {
            startPoint = item.trackingInfo.pointOfPickup.load.fact;
          } else if (item.trackingInfo.pointOfOrigin && item.trackingInfo.pointOfOrigin.arrival.fact) {
            startPoint = item.trackingInfo.pointOfOrigin.arrival.fact;
          } else {
            return 0;
          }
          if (item.trackingInfo.pointOfDestination && item.trackingInfo.pointOfDestination.arrival.plan) {
            finishPoint = item.trackingInfo.pointOfDestination.arrival.plan;
          } else {
            finishPoint = item.trackingInfo.pointOfArrival.arrival.plan;
          }
          if (startPoint) {
            const result = (((moment().set({ 'h': 0, 'm': 0, 's': 0, 'ms': 0 }) - moment(startPoint)) / (moment(finishPoint) - moment(startPoint))) * 100);
            if (result < 0) {
              return 0;
            }
            if (result > 100) {
              return 100;
            }
            return result;
          }
          return 0;
        }
        return 0;
      };

      const getClientName = (userInfo) => {
        if (userInfo) {
          if (userInfo.organizationName) {
            return userInfo.organizationName;
          }
          if (userInfo.userName.trim()) {
            return userInfo.userName;
          }
          if (userInfo.email) {
            return userInfo.email;
          }
        }
        return '-';
      };

      const lang = getLangInStorage();
      const ruLang = 'ru';

      const trackingBlocks = items.map((item, index) => {
        const loadingDate = getStartDateOfOrder(item);
        const deliveryDate = getFinishDateOfOrder(item);
        const lengthLimit = 100;

        const cargoDimensionsValue = getCargoSummaryForOrder(item.cargoInfo, lengthLimit);
        const cargoInfo = prepareOrderCardCargoInfoData(item.cargoInfo);

        const firstItem = cargoInfo.generals[0] || cargoInfo.containers[0] || cargoInfo.vehicle[0];
        if(!firstItem){
          return;
        }

        const weightUnits = Array.from(new Set(cargoInfo.generals.map(x =>  x.cargoWeightUnit || x.weightUnit)
            .concat(cargoInfo.containers.map(x =>  x.cargoWeightUnit || x.weightUnit))
            .concat(cargoInfo.vehicle.map(x =>  x.cargoWeightUnit || x.weightUnit))
        ));

        const useOneWeightUnit = weightUnits.length === 1;

        const calculateWeight = (arr) => {
          return  arr.reduce((prev, item) => {
            const amount = item.cargoAmount || item.amount || 1;
            const weight = item.cargoWeight || item.weightValue;
            const isKg = (item.weightUnit === 'kg' || item.weightUnit === 'кг' ||
                item.cargoWeightUnit === 'kg' || item.cargoWeightUnit === 'кг');

            if (useOneWeightUnit || isKg) {
              return prev + amount * weight;
            }

            return prev + +round((amount * weight / 2.205), 2);
          }, 0);
        };

        const generalsWeight = calculateWeight(cargoInfo.generals);
        const containersWeight = calculateWeight(cargoInfo.containers);
        const vehicleWeight = calculateWeight(cargoInfo.vehicle);

        let cargoTypeName = firstItem.bodyType? firstItem.bodyType: firstItem.typeName;
        if (!cargoTypeName && firstItem.cargoTypeId === 1) {
          cargoTypeName = translate({ id: 'crud.field.GENERAL_CARGO'});
        }

        const {
          trackingInfo: {
            pointOfDestination: {arrival: {fact: arrivalDateFact} = {}} = {}
          } = {}
        } = item;
        // const statusId = !arrivalDateFact? 1: 2;
        /* временно всем заказам проставлен этот статус */
        const statusId =  item.status;

        const from1stLevel = item.order.fromLocation.city.parent;
        const from2ndLevel = item.order.fromLocation.city.parent.parent;
        const to1stLevel = item.order.toLocation.city.parent;
        const to2ndLevel = item.order.toLocation.city.parent.parent;

        const fromSubTitle = from2ndLevel? `${lang === ruLang? from1stLevel.name.ru: from1stLevel.name.en}, ${lang === ruLang? from2ndLevel.name.ru: from2ndLevel.name.en}`:
            lang === ruLang? from1stLevel.name.ru: from1stLevel.name.en;

        const toSubTitle = to2ndLevel? `${lang === ruLang? to1stLevel.name.ru: to1stLevel.name.en}, ${lang === ruLang? to2ndLevel.name.ru: to2ndLevel.name.en}`:
            lang === ruLang? to1stLevel.name.ru: to1stLevel.name.en;

        let fromTitle = lang === ruLang? item.order.fromLocation.city.name.ru: item.order.fromLocation.city.name.en;
        let commaIndex = fromTitle.indexOf(',');
        if(commaIndex){
          fromTitle = fromTitle.substr(0, commaIndex);
        }
        let toTitle = lang === ruLang? item.order.toLocation.city.name.ru: item.order.toLocation.city.name.en;
        commaIndex = toTitle.indexOf(',');
        if(commaIndex){
          toTitle = toTitle.substr(0, commaIndex);
        }

        const {requestDetails:  {
          companyName: customerCompanyName,
        } = {}} = item;

        const itemData = {
              orderNumber: item.id,
              orderDate: item.createDate,
              loadingDate: loadingDate,
              deliveryDate: deliveryDate,
              price: item.totalCost.value,
              currency: item.totalCost.currency,
              internalId: (item.internalId) ? item.internalId : '',
              shippingDate: (item.trackingInfo && item.trackingInfo.pointOfPickup && item.trackingInfo.pointOfPickup.ready && item.trackingInfo.pointOfPickup.ready.plan) ?
                  item.trackingInfo.pointOfPickup.ready.plan : null,
              cargoWeight: generalsWeight + containersWeight + vehicleWeight,
              cargoWeightUnit: useOneWeightUnit ? weightUnits[0] : lang !== ruLang ? 'kg' : 'кг',
              cargoDimensionsUnit: firstItem.cargoDimensionsUnit || firstItem.dimensionsUnit,
              cargoTypeId: getCargoTypeIdByCargoInfo(item.cargoInfo),
              cargoTypeName: cargoTypeName,
              cargoAmount: cargoDimensionsValue.amount,
              unitName: '',
              cargoDimensionsValue: cargoDimensionsValue.data,
              statusId: statusId,
              statusName: getOrderStatusName(statusId),
              isSubscribed: false,
              fromTitle: fromTitle,
              fromSubtitle: fromSubTitle,
              fromTransportType: 1,
              toTitle: toTitle,
              toSubtitle: toSubTitle,
              toTransportType: 2,
              arrivalDate: (item.trackingInfo && item.trackingInfo.pointOfDestination && item.trackingInfo.pointOfDestination.arrival &&
                  item.trackingInfo.pointOfDestination.arrival.plan) ? item.trackingInfo.pointOfDestination.arrival.plan : null,
              customerCompanyName: `${translate({id: 'orderList.companyName'})}: ${customerCompanyName}`,
              indicator: getIndicator(item.trackingInfo, statusId),
        };

        return (
            <Fragment key={index}>
              <OrderMiniCard
                  {...itemData}
                  isShortenInfo={false}
                  canEdit={this.state.isLogisticBlockView}
                  onEditClick={(id) => {this.openModal(index);}}
                  onStatusEditClick={this.setStatus}
                  onPopupEditClick={()=>{this.openLogisticPopupModal(item.id)}}
                  indicator={itemData.indicator}
                  onPopupChangeOwnerClick={()=>{this.openChangeOwnerModal(index)}}
                  onClickToNavOrder={()=>{this.onClickNavToOrder(item)}}
              />
              <ChangeOwnerOrderModal
                id={item.id}
                isShow={this.state.showChangeOwnerOrderModal && this.state.currentIndex === index}
                onClose={this.closeChangeOwnerModal}

              />
              <LogisticModal
                  isModal={true}
                  isShow={this.state.modalIsOpen && this.state.currentIndex === index}
                  onClose={() => this.closeModal()}
                  bol={(item.cargoInfo && item.cargoInfo.transportDocument) ? item.cargoInfo.transportDocument.bol : ''}
                  awb={(item.cargoInfo && item.cargoInfo.transportDocument) ? item.cargoInfo.transportDocument.awb : ''}
                  sealine={item.cargoInfo ? item.cargoInfo.carrierCode : ''}
                  containerId={item.transportDocument ? item.transportDocument.containerNumber : ''}
                  manualCost={item.manualCost}
                  manualCostDescription={item.manualCostDescription}
                  estCost={item.totalCost}
                  containers={item.cargoInfo ? toJS(item.cargoInfo.containers) : []}
                  generals={item.cargoInfo ? toJS(item.cargoInfo.generals) : []}
                  vehicle={item.cargoInfo ? toJS(item.cargoInfo.vehicle) : []}
                  id={item.id}
                  internalId={item.internalId}
                  driverMobile={item.deliveryTruckDetail ? item.deliveryTruckDetail.driverMobile : ''}
                  onUpdateTrackingInfo= {() => this.props.store.reloadList()}
                  driverName={item.deliveryTruckDetail ? item.deliveryTruckDetail.driverName : ''}
                  truckNumber={item.deliveryTruckDetail ? item.deliveryTruckDetail.truckNumber : ''}
                  documentContainer={item.documentContainer ? item.documentContainer : {}}
                  transportDocument={item.transportDocument ? item.transportDocument : {}}
                  isShowDelivery={item.order.toLocation.type === 'ADDRESS'}
                  railwayNumber={item.transportDocument ? item.transportDocument.railwayNumber : ''}
                  canEditCommercialData={this.props.adminMode}
                  shippingDatePlan={  item.trackingInfo && item.trackingInfo.pointOfPickup && item.trackingInfo.pointOfPickup.ready.plan || null}
                  shippingDateFact={  item.trackingInfo && item.trackingInfo.pointOfPickup && item.trackingInfo.pointOfPickup.ready.fact || null}
                  item={item}
              />
              <LogisticPopupModal
                  isModal={true}
                  isShow={this.state.logisticPopupModalIsOpen && this.state.currentIndex === item.id}
                  onClose={() => this.closeLogisticPopupModal()}
                  onUpdateTrackingInfo= {() => this.props.store.reloadList()}
                  dataTracking={prepareOrderAccordionItems(item.requests, item.trackingInfo)}
                  item={item}
                  trackingStatus={item.trackingDetails.lastCompleted ? item.trackingDetails.lastCompleted.name : ''}
                  indicator={itemData.indicator}
              />

            </Fragment>
        );
      });

      return (
          <div className="crud-list">
            {
              title && <div><h2>{translate({ id: this.props.title, defaultMessage: this.props.title })}</h2><hr /></div>
            }

            <div className="crud-list__inner">
              <Row className="align-items-center">
                <Col className="order-list">
                  {this.getTopControlsButton()}
                </Col>
              </Row>
              {trackingBlocks}

              {store.items.length === 0 || <hr />}
              {store.items.length === 0 ? null : this.getPagination()}
            </div>

            {store.items.length === 0 ? <Row className="crud-list__inner_empty"><Col xs={12}>{translate({ id: 'crudList.empty' })}</Col></Row> : ''}
          </div>
      );
    }

    //TODO
    if (isTrackingBlock /*&& this.state.isLogisticBlockView*/) {
      const items = store.items;

      const logisticBlocks = items.map((item, index) => {
        return (
            <LogisticBlock
                item={item}
                index={index}
                key={item.id}
                store={this.props.store}
                canEdit={this.state.isLogisticBlockView}
                isAllLogisticBlocksExpanded={this.state.isAllLogisticBlocksExpanded}
            />
        );
      });

      return (
          <div className="crud-list">
            {
              title && <div><h2>{translate({ id: this.props.title, defaultMessage: this.props.title })}</h2><hr /></div>
            }

            <div className="crud-list__inner">
              <Row className="align-items-center">
                <Col className="order-list">
                  {this.getTopControlsButton(true)}
                </Col>
              </Row>


              <div className={'order-short-m-card'}>
                <Table onClick={this.handle} hover striped className="crud-list__inner_table shorten-order-table">
                  <thead>
                  <tr>
                    { this.props.bulkOperations ? <th className="cell-ckeckbox"><Checkbox checked={this.state.isAllChecked} onChange={() => this.handleCheckAll(store.items)} /></th> : null }
                    {columnHead}
                    <th className="crud-list__inner_table_cell-action">{' '}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {logisticBlocks}
                  </tbody>
                  <tfoot />
                </Table>

                {/*<table className={'shorten-order-table'}>*/}
                {/*    <tbody>*/}
                {/*    {logisticBlocks}*/}
                {/*    </tbody>*/}
                {/*</table>*/}
              </div>


              {store.items.length === 0 || <hr />}
              {store.items.length === 0 ? null : this.getPagination()}
            </div>

            {store.items.length === 0 ? <Row className="crud-list__inner_empty"><Col xs={12}>{translate({ id: 'crudList.empty' })}</Col></Row> : ''}
          </div>
      );
    }

    return (
        <div className="crud-list">
          {
            title && <div><h2>{translate({ id: this.props.title, defaultMessage: this.props.title })}</h2><hr /></div>
          }

          <div className="crud-list__inner">
            <Row className="align-items-center">
              {!this.props.isTrackingBlock ?
                  <Col md={114}>
                    {this.getTopControlsButton()}
                  </Col>
                  :
                  <Col>
                    {this.getTopControlsButton(true)}
                  </Col>}
              {!this.props.isTrackingBlock && <Col md={118}>
                {store.items.length === 0 ? null : this.getPagination()}
              </Col>}
            </Row>
            <Table onClick={this.handle} hover striped className="crud-list__inner_table">
              <thead>
              <tr>
                { this.props.bulkOperations ? <th className="cell-ckeckbox"><Checkbox checked={this.state.isAllChecked} onChange={() => this.handleCheckAll(store.items)} /></th> : null }
                {columnHead}
                <th className="crud-list__inner_table_cell-action">{' '}</th>
              </tr>
              </thead>
              <tbody>
              {body}
              </tbody>
              <tfoot />
            </Table>

            {store.items.length === 0 || <hr />}
            {store.items.length === 0 ? null : this.getPagination()}
          </div>

          {store.items.length === 0 ? <Row className="crud-list__inner_empty"><Col xs={12}>{translate({ id: 'crudList.empty' })}</Col></Row> : ''}
        </div>
    );
  }
}

CrudListDesktop.defaultProps = {
  isAdd: true,
  editable: true,
  align: 'center',
  isPagination: true,
  crudType: 'list',
  bulkOperations: false,
  downloadCSV: false,
  uploadCSV: false,
  enableNotifications: true,
  isOnlyDelAction: false,
  isTrackingBlock: false,
  isDocs: false,
  isAllDocs: false,
  isEdit: true,
  isDelete: true,
};

CrudListDesktop.propTypes = {
  store: PropTypes.object,
  isLoaded: PropTypes.bool,
  title: PropTypes.string,
  isPagination: PropTypes.bool,
  isAdd: PropTypes.bool,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  enableNotifications: PropTypes.bool,
  bulkOperations: PropTypes.bool,
  downloadCSV: PropTypes.bool,
  uploadCSV: PropTypes.bool,
  endpoint: PropTypes.string,
  align: PropTypes.string,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onAdd: PropTypes.func,
  isDocs: PropTypes.bool,
  isAllDocs: PropTypes.bool,
  renderActionsButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  isOnlyDelAction: PropTypes.bool,
  isAdminOnlyView: PropTypes.bool,
  crudType: PropTypes.string,
  isTrackingBlock: PropTypes.bool,
  documentContainer: PropTypes.object,
  trackingInfo: PropTypes.arrayOf(PropTypes.object),
  contacts: PropTypes.object,
};

export default injectIntl(CrudListDesktop);
