import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import { ADMIN_ORDERS, AUTH_LOGIN, CLIENT_ORDERS, PARTNER_ORDERS, WIZARD } from '../../constants/routes';
import {cloneDeep, each, find, findKey, get, has, isEmpty, isNaN, isObject, omit, reduce, set} from 'lodash';
import {Button, Col,Container, Row} from 'reactstrap';
import 'iframe-resizer/js/iframeResizer.contentWindow.js';
import Page from '../../layouts/main';
import {Modal} from '../../components/ui/Modal';
import {DirectionForm} from '../../components/wizard/DirectionForm';
import {
    DEFAULT_CONTAINER,
    DEFAULT_FIELDS,
    DEFAULT_GENERAL,
    DEFAULT_VEHICLE,
    TAB_TO_CARGO_TYPE
} from '../../components/wizard/DefaultFields';
import {
    DIMENSIONS,
    LANG_CODE,
    MAX_CARGO_SIZE,
    MAX_CONTAINER_WEIGHT,
    TYPE_OF_LOCATIONS,
    TYPE_TO_LOCATION_FIELD_NAME as TYPE_TO_FIELDNAME,
} from '../../constants/global';
import {CargoForm} from '../../components/wizard/CargoForm';
import {CommonForm} from '../../components/wizard/CommonForm';
import {InsuranceForm} from '../../components/wizard/InsuranceForm';
import CustomsClearanceFee from '../../components/wizard/CustomsClearanceFee';
import * as ROLES from '../../constants/userRoles';
import AppStore from '../../stores/appStore';

import {
    convertKgToLb,
    getLangInStorage,
    getNearestItem,
    keyGenerator,
    orderRedirect, redirectToSubscription,
    setLangInStorage,
    setSessionCookie,
    translate,
} from '../../utils/utils';
import UserStore from '../../stores/userStore';
import DictionaryService from './../../services/dictionaryService';
import OrderService from '../../services/orderService';
import FlashStore from '../../stores/flashStore';
import {CUSTOM_HOUSE} from '../../constants/staticData';
import {API} from '../../constants/api';
import {AI} from '../../AppInsight';
import {metricsEvents, pushAnalyticalEvent} from '../../utils/analyticsMetrics';
import {Link} from "react-router-dom";
import OrderDetailsBlock from "../../components/wizard/OrderDetailsBlock";
import UserService from '../../services/userService';

export default class Wizard extends Component {
    state = {
        isWaiting: false,
        isCityLoading: false,
        fields: this.getInitFields(),
        fieldsErrors: {},
        directionErrors: {},
        generalError: '',
        containerTypes: [],
        generalTypes: [],
        palletTypes: [],
        sizeUnits: [],
        countries: [],
        toCities: [],
        fromCities: [],
        toPorts: [],
        fromPorts: [],
        toAirports: [],
        fromAirports: [],
        toStations: [],
        fromStations: [],
        seaLines: [],
        consolidators: [],
        vehicleTypes: [],
        activeTab: this.props.isSEO ? this.props.cargoType : TAB_TO_CARGO_TYPE.CONTAINER,
        fromCountry: '',
        toCountry: '',
        customsHouseState: {},
        isInsuranceIncluded: false,
        directInputsKeys: {},
        enabledHubs: {},
        disablingRules: [],
        customsClearanceData: null,
        countriesByType: null,
        enabledCargos: [],
        isDismiss: false,
        errorsRefs: [],
        isShowPointError: false,
        showIsLimitCalculation: false,
        domain:(new URLSearchParams(window.location.search)).get("domain"),
        orderDetailsParam:{
            companyName:'',
            phone:'',
            cargoDescription:'',
            dateReadyCargo:{plan:''},
            remarks:'',
            transitHubId:null,
            price:{
                value:0,
                currency:"USD"
            },
            newOwner:null
        },
        cargoTypeHighlight: false,
    };
    
    updateState=(state, callback=()=>{})=>{
        setTimeout(()=>{
            this.setState(state, callback)
        })
    }
    

    async UNSAFE_componentWillMount() {
        console.log('Wizard UNSAFE_componentWillMount');
        const {search, hash} = this.props.location;
        const fields = cloneDeep(this.state.fields);
        const isHasSearch = search.length !== 0;
        const widget = this.props.location.search.includes('isWidget=true');
        if(UserStore.isGuest() && !widget) {
            let domainV3 = this.state.domain;
            if(!window.location.host.includes("localhost"))
                if(window.location.host.split(".").length===3)
                    domainV3 = window.location.host.split(".")[0];
            let access = (await UserService.checkAllowAccess(domainV3)).data
            if (access === false) {
                this.props.history.push(AUTH_LOGIN)
            }
        }

        this.updateState({
            isWaiting: true,
            isDismiss: localStorage.getItem('isDismissWizard'),
            directInputsKeys: {
                fromAddress: keyGenerator(),
                toAddress: keyGenerator(),
                fromSea: keyGenerator(),
                toSea: keyGenerator(),
                fromAir: keyGenerator(),
                toAir: keyGenerator(),
                fromRailway: keyGenerator(),
                toRailway: keyGenerator(),
            },
        });

        const promises = [
            DictionaryService.getCargoContainerTypes('COMMON'),
            DictionaryService.getGeneralTypes(),
            DictionaryService.getPalletTypes(),
            DictionaryService.getSizeUnits(),
            DictionaryService.getCountries(),
            // DictionaryService.getSeaPortHubs(),
            DictionaryService.getSeaLines({type: 'COMMON'}),
            // DictionaryService.getAirHubs(),
            DictionaryService.getSeaLines({type: 'CONSOLIDATOR'}),
            !this.props.isSEO ? DictionaryService.getContainerTypesByCountries({
                countryFromId: this.state.fields.fromLocation.countryCode,
                countryToId: this.state.fields.toLocation.countryCode
            }) : DictionaryService.getContainerTypesByCountries({
                countryFromId: this.props.fromCity.countryCode,
                countryToId: this.props.toCity.countryCode
            }),
            DictionaryService.getTransportationRules(),
        ];

        Promise.all(promises).then((res) => {
            console.log('Wizard promises resoleved');
            const palletTypes = cloneDeep(res[2]);
            const fromCountry = res[4].find(item => item.code === this.state.fields.fromLocation.countryCode);
            const toCountry = res[4].find(item => item.code === this.state.fields.toLocation.countryCode);

            this.updateState({
                containerTypes: res[0],
                generalTypes: res[1],
                palletTypes,
                sizeUnits: res[3],
                countries: res[4],
                // ports: res[7],
                seaLines: res[5],
                // airports: res[9],
                consolidators: res[6],
                vehicleTypes: res[7],
                disablingRules: get(res[8], 'rules', null),
                countriesByType: get(res[8], 'availableCountriesForCargo', null),
                fields,
                fromCountry: get(fromCountry, 'id', null),
                toCountry: get(toCountry, 'id', null),
            }, async () => {
                if (isHasSearch) {
                    await this.decodeGetParams(search);
                }

                if (!isHasSearch) {
                    this.setEnabledHubs(this.state.fields);
                }

                this.fetchCustomsClearance();

                const nextPromises = [
                    DictionaryService.getSeaPortHubsByCityPopular({
                        countryId: get(this.state, 'toCountry'),
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: false
                    }),
                    DictionaryService.getSeaPortHubsByCityPopular({
                        countryId: get(this.state, 'fromCountry'),
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: true
                    }),
                    /*DictionaryService.getAirportHubsByCityPopular({
                        countryId: this.state.toCountry,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: false
                    }),
                    DictionaryService.getAirportHubsByCityPopular({
                        countryId: this.state.fromCountry,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: true
                    }),
                    DictionaryService.getRailwayHubsByCityPopular({
                        countryId: this.state.toCountry,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: false
                    }),
                    DictionaryService.getRailwayHubsByCityPopular({
                        countryId: this.state.fromCountry,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: true
                    }),*/
                    DictionaryService.getCityesPopular({
                        searchString: '',
                        language: getLangInStorage(),
                        country: this.state.fields.toLocation.countryCode,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: false
                    }),
                    DictionaryService.getCityesPopular({
                        searchString: '',
                        language: getLangInStorage(),
                        country: this.state.fields.fromLocation.countryCode,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: true
                    })
                ];

                Promise.all(nextPromises).then((nextRes) => {
                    console.log('Wizard next promises resoleved');
                    this.updateState({
                        isWaiting: false,
                        toPorts: nextRes[0],
                        fromPorts: nextRes[1],
                        /*toAirports: nextRes[2],
                        fromAirports: nextRes[3],
                        toStations: nextRes[4],
                        fromStations: nextRes[5],*/
                        toCities: nextRes[2],
                        fromCities: nextRes[3],
                    });
                });
                this.setEnabledCargos(this.state.fields);
                if (hash !== '') {
                    setTimeout(async ()=>{
                        const id = hash.replace('#', '');
                        await this.getExistingOrder(id);
                    }, 100)
                }
            });
        });
    }

    async getExistingOrder(id) {
        const res = await OrderService.getOrder(id);

        if (res.status === 'success') {
            const data = res.data;

            let activeTab = TAB_TO_CARGO_TYPE.GENERAL_CARGO;

            if (data.cargo.containers.length > 0) {
                activeTab = TAB_TO_CARGO_TYPE.CONTAINER;
            } else if (data.cargo.vehicle) {
                activeTab = TAB_TO_CARGO_TYPE.VEHICLE;
            }

            let containers = data.cargo.containers;
            const generals = data.cargo.generals.length === 0 ? [{...DEFAULT_GENERAL}] : data.cargo.generals;
            const vehicle = get(data, 'cargo.vehicle', {...DEFAULT_VEHICLE});

            if (data.cargo.containers.length === 0) {
                const containerFields = DEFAULT_CONTAINER;
                if (get(data, 'fromLocation.country.code') === 'US' || get(data, 'toLocation.country.code') === 'US') {
                    set(containerFields, 'weight', MAX_CONTAINER_WEIGHT.US);
                }
                containers = [{...containerFields}];
            }

            const getLocation = async (where) => {
                const locationType = get(data, `${where}Location.type`, '');
                const allHubs = await DictionaryService.getAllHubsByCityId({cityId: get(data, `${where}Location.city.id`, 0)});

                let location = {
                    ...this.getLocationArrayByHubs(allHubs),
                    address: {label: get(data, `${where}Location.city.name`, '')},
                    countryCode: get(data, `${where}Location.country.code`, ''),
                    postcode: get(data, `${where}Location.postcode`, ''),
                    city: get(data, `${where}Location.city`, null),
                    seaLineId: get(data, `${where}Location.seaLine.id`, null),
                    type: get(data, `${where}Location.type`, ''),
                };

                if (has(data, `${where}Location.hub`) && locationType in TYPE_TO_FIELDNAME) {
                    location = {
                        ...location,
                        [TYPE_TO_FIELDNAME[locationType]]: get(data, `${where}Location.hub.id`),
                    };
                }

                return location;
            };

            const newFields = {
                ...this.state.fields,
                cargo: {
                    containers: containers.map((item) => {
                        const fields = cloneDeep(item);
                        fields.typeId = get(item, 'type.id', '');
                        return fields;
                    }),
                    generals: generals.map((item) => {
                        const fields = cloneDeep(item);

                        const type = has(item, 'palletType') ?
                            this.state.generalTypes.filter(el => el.id === 2)
                            :
                            item.typeId === 3 ?
                            this.state.generalTypes.filter(el => el.id === 3)
                                :
                                this.state.generalTypes.filter(el => el.id === 1);


                        fields.typeId = get(type[0], 'id');
                        fields.palletId = get(item, 'palletType.id', '');
                        return fields;
                    }),
                    vehicle: {
                        ...cloneDeep(vehicle),
                        typeId: get(vehicle, 'type.id', ''),
                    },
                },
                insurance: {...data.insurance},
                fromLocation: {...(await getLocation('from'))},
                toLocation: {...(await getLocation('to'))},
                shippingDate: data.shippingDate,
                customsHouse: data.customsHouse,
                cargoInsurances: data.cargoInsurances ? data.cargoInsurances.map((item) => {
                    const fields = cloneDeep(item);
                    fields.interestTypeId = get(item, 'interestType.id', '');
                    return fields;
                }) : DEFAULT_FIELDS.cargoInsurances,
                hasCustomsClearance: data.hasCustomsClearance,
            };

            const customsHouseState = this.getCustomHouseValue(newFields, activeTab);
            set(newFields, 'customsHouse', customsHouseState.value);
            const vehicleTypes = await DictionaryService.getContainerTypesByCountries({
                countryFromId: get(data.fromLocation, 'country.id'),
                countryToId: get(data.toLocation, 'country.id')
            });

            this.updateState({
                fromCountry: get(data.fromLocation, 'country.id', null),
                toCountry: get(data.toLocation, 'country.id', null),
                activeTab,
                fields: newFields,
                customsHouseState,
                isInsuranceIncluded: get(data, 'cargoInsurances', false),
                vehicleTypes,
            }, () => {
                this.setEnabledCargos(newFields);
                this.LoadPopularHubsOnChange();
                this.props.history.replace(this.props.location.search ? this.props.location.pathname + this.props.location.search : this.props.location.pathname);
                setTimeout(() => this.setActiveTab(activeTab), 200);
            });
        }
    }

    LoadPopularHubsOnChange() {
        const fromlocationType = get(this.state.fields, `fromLocation.type`);
        const toLocationType = get(this.state.fields, `toLocation.type`);
        if (fromlocationType.includes(TYPE_OF_LOCATIONS.ADDRESS)) {
            let requests = [
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.fromLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
            ]
            Promise.all(requests).then((res) => {
                this.updateState({
                    fromCities: res[0],
                })
            });
        } else if (fromlocationType.includes(TYPE_OF_LOCATIONS.SEA_PORT)) {
            let requests_add = [
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: this.state.fromCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    fromPorts: res[0],
                })
            });
        } else if (fromlocationType.includes(TYPE_OF_LOCATIONS.AIRPORT_HUB)) {
            let requests_add = [
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: this.state.fromCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    fromAirports: res[0],
                })
            });
        } else if (fromlocationType.includes(TYPE_OF_LOCATIONS.RAILWAY_HUB)) {
            let requests_add = [
                DictionaryService.getRailwayHubsByCityPopular({
                    countryId: this.state.fromCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    fromStations: res[0],
                })
            });
        }

        if (toLocationType.includes(TYPE_OF_LOCATIONS.ADDRESS)) {
            let requests_add = [
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.toLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    toCities: res[0],
                })
            });
        } else if (toLocationType.includes(TYPE_OF_LOCATIONS.SEA_PORT)) {
            let requests_add = [
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: this.state.toCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    toPorts: res[0]
                })
            });
        } else if (toLocationType.includes(TYPE_OF_LOCATIONS.AIRPORT_HUB)) {
            let requests_add = [
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: this.state.toCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    toAirports: res[0]
                })
            });
        } else if (toLocationType.includes(TYPE_OF_LOCATIONS.RAILWAY_HUB)) {
            let requests_add = [DictionaryService.getRailwayHubsByCityPopular({
                countryId: this.state.toCountry,
                countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                countryTo: get(this.state.fields, `toLocation.countryCode`),
                typeFrom: get(this.state.fields, `fromLocation.type`),
                typeTo: get(this.state.fields, `toLocation.type`),
                isFrom: false
            }),
            ]
            Promise.all(requests_add).then((res) => {
                this.updateState({
                    toStations: res[0]
                })
            });
        }
    }

    async decodeGetParams(search) {
        let fromAddress = null;
        let toAddress = null;
        let fromCountryCode = LANG_CODE.CN;
        let toCountryCode = LANG_CODE.RU;
        let fromType = TYPE_OF_LOCATIONS.SEA_PORT;
        let toType = TYPE_OF_LOCATIONS.ADDRESS;
        let fromHubId = null;
        let toHubId = null;
        let fromHub = null;
        let toHub = null;

        const getParamsArr = search.replace('?', '').split('&');
        const isWidget = !!getParamsArr.find(x => x === 'isWidget=true');

        await getParamsArr.forEach((param) => {
            const str = param.split('=');

            if (!getParamsArr.includes('fromWhere=landing')) {
                if (str[0] === 'fromAddress' && str[1] !== '') {
                    if (isNaN(+str[1])) {
                        fromAddress = this.getGoogleInputObjByString(decodeURIComponent(str[1]));
                        fromType = TYPE_OF_LOCATIONS.ADDRESS;
                    } else {
                        fromHubId = +str[1];
                    }
                }

                if (str[0] === 'toAddress' && str[1] !== '') {
                    if (isNaN(+str[1])) {
                        toAddress = this.getGoogleInputObjByString(decodeURIComponent(str[1]));
                        toType = TYPE_OF_LOCATIONS.ADDRESS;
                    } else {
                        toHubId = +str[1];
                    }
                }
            }

            if (str[0] === 'fromCode' && str[1] !== 'null') {
                fromCountryCode = decodeURIComponent(str[1]);
            }

            if (str[0] === 'toCode' && str[1] !== 'null') {
                toCountryCode = decodeURIComponent(str[1]);
            }

            if (isWidget) {
                if (str[0] === 'language' && str[1]) {
                    if (getLangInStorage() !== str[1]) {
                        let lang = str[1]
                        if (lang === 'zh' || lang === 'en' || lang === 'ru')
                            lang = str[1]
                        else
                            lang = 'ru'
                        setLangInStorage(lang);
                        AppStore.changeLang(lang);
                    }
                }
                if (str[0] === 'appId' && str[1]) {
                    setSessionCookie('appId', str[1]);
                    AppStore.setAppId(str[1]);
                }
                if (str[0] === 'terms' && str[1]) {
                    setSessionCookie('terms', decodeURIComponent(str[1]));
                    AppStore.setTerms(decodeURIComponent(str[1]));
                }
                if (str[0] === 'isModal' && str[1] === '0') {
                    AppStore.setIsModal(false);
                }
            }
        });

        if (fromHubId) {
            fromHub = await DictionaryService.getAllHubById(fromHubId);
            fromType = fromHub.type;
            fromAddress = {label: fromHub.city.name};
            fromCountryCode = this.getCountryCodeByCity(fromHub.city);
        }

        if (toHubId) {
            toHub = await DictionaryService.getAllHubById(toHubId);
            toType = toHub.type;
            toAddress = {label: toHub.city.name};
            toCountryCode = this.getCountryCodeByCity(toHub.city);
        }

        await this.onChangeCity(fromHubId ? fromHub : fromAddress, 'from', fromType, fromCountryCode);
        await this.onChangeCity(toHubId ? toHub : toAddress, 'to', toType, toCountryCode);

        this.setEnabledHubs(this.state.fields);
    }

    getInitFields() {
        const {search} = this.props.location;
        const result = cloneDeep(DEFAULT_FIELDS);
        const getParamsArr = search.replace('?', '').split('&');

        if (this.props.isSEO) {
            result.fromLocation.countryCode = this.props.fromCountry;
            result.toLocation.countryCode = this.props.toCountry;
            result.fromLocation.address = this.props.fromCity;
            result.toLocation.address = this.props.toCity;
            result.fromLocation.type = this.props.fromHub;
            result.toLocation.type = this.props.toHub;
            result.fromLocation.cityId = this.props.fromCity.cityId || '';
            result.toLocation.cityId = this.props.toCity.cityId || '';
            result.fromLocation.airportId = this.props.fromCity.id || '';
            result.toLocation.airportId = this.props.toCity.id || '';
            result.fromLocation.seaPortId = this.props.fromCity.id || '';
            result.toLocation.seaPortId = this.props.toCity.id || '';
            result.fromLocation.stationId = this.props.fromCity.id || '';
            result.toLocation.stationId = this.props.toCity.id || '';

            return result;
        }

        if (getParamsArr.includes('fromWhere=landing')) {
            getParamsArr.forEach((param) => {
                const str = param.split('=');

                if (str[0] === 'fromCode' && str[1] !== '') {
                    if (isNaN(+str[1])) {
                        result.fromLocation.countryCode = decodeURIComponent(str[1]);
                    }
                }

                if (str[0] === 'toCode' && str[1] !== '') {
                    if (isNaN(+str[1])) {
                        result.toLocation.countryCode = decodeURIComponent(str[1]);
                    }
                }
            });

            return result;
        }

        getParamsArr.forEach((param) => {
            const str = param.split('=');

            if (str[0] === 'fromAddress' && str[1] !== '') {
                if (isNaN(+str[1])) {
                    result.fromLocation.address = this.getGoogleInputObjByString(decodeURIComponent(str[1]));
                }
            }

            if (str[0] === 'toAddress' && str[1] !== '') {
                if (isNaN(+str[1])) {
                    result.toLocation.address = this.getGoogleInputObjByString(decodeURIComponent(str[1]));
                }
            }

            if (str[0] === 'appId' && str[1] !== '') {
                result.appId = decodeURIComponent(str[1]);
            }

            if (str[0] === 'terms' && str[1] !== '') {
                result.terms = decodeURIComponent(str[1]);
            }
        });

        return result;
    }

    getGoogleInputObjByString(value) {
        let result = null;

        if (value.includes('_code_')) {
            const data = value.split('_code_');

            result = {
                label: {[getLangInStorage()]: data[0]},
                placeId: data[1],
            };
        }

        return result;
    }

    getCountryCodeByCity = (city) => {
        let countryCode = null;

        if (city.parent.type === 'REGION') {
            countryCode = city.parent.parent.code;
        } else if (city.parent.type === 'COUNTRY') {
            countryCode = city.parent.code;
        }

        return countryCode;
    };

    getCustomHouseValue(fields, activeTab) {
        let customsHouseState = {
            value: null,
            isDisabled: true,
            isShown: false,
        };

        if ((activeTab === TAB_TO_CARGO_TYPE.CONTAINER) &&
            ((fields.fromLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.toLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU) ||
                (fields.fromLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.toLocation.type === TYPE_OF_LOCATIONS.ADDRESS && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU) ||
                (fields.fromLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.toLocation.type === TYPE_OF_LOCATIONS.RAILWAY_HUB && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU))) {
            customsHouseState = {
                value: isEmpty(fields.customsHouse) ? CUSTOM_HOUSE.find(item => item.id === 'IN_PORT').id : fields.customsHouse,
                isDisabled: false,
                isShown: true,
            };
        }
        //CRBR-341 Disabled 21.10.2022.
        /*else if (activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO) { // Сборный груз
            if ((fields.fromLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU)||
              (fields.fromLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.toLocation.type === TYPE_OF_LOCATIONS.ADDRESS && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU))
            {
                customsHouseState = {
                    //value: isEmpty(fields.customsHouse) ? CUSTOM_HOUSE.find(item => item.id === 'IN_PORT').id : fields.customsHouse,
                    value: CUSTOM_HOUSE.find(item => item.id === 'IN_PORT').id,
                    isDisabled: false,
                    isShown: true,
                };
            } else if (fields.fromLocation.type === TYPE_OF_LOCATIONS.AIRPORT_HUB && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU) {
                customsHouseState = {
                    value: CUSTOM_HOUSE.find(item => item.id === 'IN_DESTINATION_PLACE').id,
                    isDisabled: true,
                    isShown: true,
                };
            }
        }*/
        else if (activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO) { // Сборный груз
            if (fields.fromLocation.type === TYPE_OF_LOCATIONS.SEA_PORT && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU) {
                customsHouseState = {
                    value: CUSTOM_HOUSE.find(item => item.id === 'IN_PORT').id,
                    isDisabled: true,
                    isShown: true,
                };
            } else if (fields.fromLocation.type === TYPE_OF_LOCATIONS.AIRPORT_HUB && fields.fromLocation.countryCode !== LANG_CODE.RU && fields.toLocation.countryCode === LANG_CODE.RU) {
                customsHouseState = {
                    value: CUSTOM_HOUSE.find(item => item.id === 'IN_DESTINATION_PLACE').id,
                    isDisabled: true,
                    isShown: true,
                };
            }
        }

        return customsHouseState;
    }

    setEnabledHubs(fields, where = 'toCountry') {
        const disablingRule = this.getDisablingRule();

        if (disablingRule) {
            const {directionFrom} = disablingRule;
            const allTypesOfLocations = Object.values(TYPE_OF_LOCATIONS);
            const from = Object.keys(directionFrom);
            const to = directionFrom[getNearestItem(fields.fromLocation.type, from, allTypesOfLocations)] || [];

            this.updateState({
                enabledHubs: {from, to},
                fields: {
                    ...fields,
                    fromLocation: {
                        ...fields.fromLocation,
                        type: getNearestItem(fields.fromLocation.type, from, allTypesOfLocations),
                    },
                    toLocation: {
                        ...fields.toLocation,
                        type: getNearestItem(fields.toLocation.type, to, allTypesOfLocations),
                    },
                },
                directionErrors: {},
            });
        } else {
            const {fromLocation = '', toLocation = ''} = fields;
            const isValidLocation = (location) => {
                return location.countryCode && location.countryCode !== 'null' && location.countryCode !== 'undefined';
            };

            if (isValidLocation(fromLocation) && isValidLocation(toLocation)) {
                const fromCountry = this.state.countries.filter(el => el.code === fromLocation.countryCode)[0].name.en;
                const toCountry = this.state.countries.filter(el => el.code === toLocation.countryCode)[0].name.en;
                AI.trackEvent({name: 'countryPair.without.transportationRules', properties: {fromCountry, toCountry}});
                this.updateState({
                    enabledHubs: {},
                    directionErrors: {
                        [where]: 'wizard.wrongDirection',
                    },
                });
            }
        }
    }

    setEnabledCargos = (fields, where) => {
        const {activeTab} = this.state;
        const {fromLocation, toLocation} = fields;
        const disablingRules = this.getDisablingRule(true)
        const rules = disablingRules.filter(r => r.countryFrom === fromLocation.countryCode && r.countryTo === toLocation.countryCode);
        //TODO: Правила транспортировки: Постоянно включенный сборный груз. Удалить когда будет не нужно
        rules.push(this.rulesGeneralItem(fromLocation.countryCode, toLocation.countryCode))
        rules.push(this.rulesContainerItem(fromLocation.countryCode, toLocation.countryCode))

        const enabledCargos = Object.entries(TAB_TO_CARGO_TYPE)
            .filter(([tabKey]) => rules.some(r => r.cargoType === tabKey))
            .map(entry => entry[1]);

        if (enabledCargos.length && !enabledCargos.includes(activeTab)) {
            this.setActiveTab(getNearestItem(activeTab, enabledCargos, Object.values(TAB_TO_CARGO_TYPE)), where, fields);
        }

        this.updateState({
            enabledCargos,
        });
    };

    setCargoTypeHighlight = (value) => {
        this.updateState({cargoTypeHighlight: value});
    }

    async setAvailableCountry(fields) {
        const {countriesByType, activeTab} = this.state;

        const cargoType = findKey(TAB_TO_CARGO_TYPE, val => val === activeTab);

        if (countriesByType) {
            const countries = get(countriesByType, cargoType, null);
            const setCountry = async (whereLoc, defaultCountryCode) => {
                if (!countries.some(item => item.code === get(fields, `${whereLoc}Location.countryCode`))) {
                    let country = null;
                    if (defaultCountryCode) {
                        country = find(countries, item => item.code === defaultCountryCode);
                    }
                    if (!country && countries.length) {
                        country = countries[0];
                    }

                    await this.onChangeCountry(country, `${whereLoc}Location.countryCode`, `${whereLoc}Country`);
                }
            };
            if (activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO) {
                await setCountry('from');
                await setCountry('to');
            } else if (activeTab === TAB_TO_CARGO_TYPE.CONTAINER) {
                await setCountry('from', LANG_CODE.CN);
                await setCountry('to', LANG_CODE.RU);
            } else if (activeTab === TAB_TO_CARGO_TYPE.VEHICLE) {
                await setCountry('from', LANG_CODE.RU);
                await setCountry('to', LANG_CODE.RU);
            }
        }
    }

    rulesGeneralItem = (from, to) => {
        return {
            id: 99999,
            countryFrom: from,
            countryTo: to,
            cargoType: "GENERAL_CARGO",
            directionFrom: {
                AIRPORT_HUB: [
                    "ADDRESS",
                    "AIRPORT_HUB"
                ],
                RAILWAY_HUB: [
                    "ADDRESS",
                    "RAILWAY_HUB",
                ],
                ADDRESS: [
                    "AIRPORT_HUB",
                    "RAILWAY_HUB",
                    "ADDRESS",
                    "SEA_PORT"
                ],
                SEA_PORT: [
                    "SEA_PORT",
                    "ADDRESS",
                    "RAILWAY_HUB"
                ]
            },
            directionTo: {
                AIRPORT_HUB: [
                    "ADDRESS",
                    "AIRPORT_HUB"
                ],
                ADDRESS: [
                    "RAILWAY_HUB",
                    "AIRPORT_HUB",
                    "ADDRESS",
                    "SEA_PORT"
                ],
                RAILWAY_HUB: [
                    "ADDRESS",
                    "RAILWAY_HUB",
                ],
                SEA_PORT: [
                    "SEA_PORT",
                    "ADDRESS",
                    "RAILWAY_HUB"
                ]
            },
            hasCustomsClearance: (from === "RU" ^ to === "RU") === 1
        }
    }
    rulesContainerItem = (from, to) => {
        return {
            id: 99998,
            countryFrom: from,
            countryTo: to,
            cargoType: "CONTAINER",
            directionFrom: {
                SEA_PORT: [
                    "SEA_PORT"
                ]
            },
            directionTo: {
                SEA_PORT: [
                    "SEA_PORT"
                ]
            },
            hasCustomsClearance: (from === "RU" ^ to === "RU") === 1
        }
    }

    getDisablingRule(isCargo = false) {
        const {disablingRules, fields, activeTab} = this.state;
        //console.log("DR: ", disablingRules ?  disablingRules.filter(r=>r.countryFrom === fields.fromLocation.countryCode):"")

        let rules = find(disablingRules, rule => (rule.cargoType === findKey(TAB_TO_CARGO_TYPE, val => val === activeTab))
            && (rule.countryFrom === fields.fromLocation.countryCode && rule.countryTo === fields.toLocation.countryCode)) || null
        if (findKey(TAB_TO_CARGO_TYPE, val => val === activeTab) === "GENERAL_CARGO" && rules !== null) {
            if (Array.isArray(rules)) {
                rules.forEach(item => {
                    if (item.directionFrom.ADDRESS === undefined)
                        item.directionFrom.ADDRESS = []
                    if (item.directionTo.ADDRESS === undefined)
                        item.directionTo.ADDRESS = []
                    if (item.directionFrom.ADDRESS.find(item => item === "ADDRESS") === undefined)
                        item.directionFrom.ADDRESS.push("ADDRESS")
                    if (item.directionTo.ADDRESS.find(item => item === "ADDRESS") === undefined)
                        item.directionTo.ADDRESS.push("ADDRESS")
                })
            } else {
                if (rules.directionFrom.ADDRESS === undefined)
                    rules.directionFrom.ADDRESS = []
                if (rules.directionTo.ADDRESS === undefined)
                    rules.directionTo.ADDRESS = []
                if (rules.directionFrom.ADDRESS.find(item => item === "ADDRESS") === undefined)
                    rules.directionFrom.ADDRESS.push("ADDRESS")
                if (rules.directionTo.ADDRESS.find(item => item === "ADDRESS") === undefined)
                    rules.directionTo.ADDRESS.push("ADDRESS")
            }
        }
        //TODO: Правила транспортировки: Постоянно включенный сборный груз. Удалить когда будет не нужно
        //START ALWAYS ENABLED CARGO
        let rulesGeneral = find(disablingRules, rule => (rule.cargoType === "GENERAL_CARGO")
            && (rule.countryFrom === fields.fromLocation.countryCode && rule.countryTo === fields.toLocation.countryCode)) || null
        if (rulesGeneral === null) {
            disablingRules.push(this.rulesGeneralItem(fields.fromLocation.countryCode, fields.toLocation.countryCode))
            this.updateState({disablingRules,})
            rules = find(disablingRules, rule => (rule.cargoType === findKey(TAB_TO_CARGO_TYPE, val => val === activeTab))
                && (rule.countryFrom === fields.fromLocation.countryCode && rule.countryTo === fields.toLocation.countryCode)) || null
        }

        if (findKey(TAB_TO_CARGO_TYPE, val => val === activeTab) === "CONTAINER" && rules !== null) {
            if (Array.isArray(rules)) {
                rules.forEach(item => {
                    if (item.directionFrom.SEA_PORT === undefined)
                        item.directionFrom.SEA_PORT = []
                    if (item.directionTo.SEA_PORT === undefined)
                        item.directionTo.SEA_PORT = []
                    if (item.directionFrom.SEA_PORT.find(item => item === "SEA_PORT") === undefined)
                        item.directionFrom.SEA_PORT.push("SEA_PORT")
                    if (item.directionTo.SEA_PORT.find(item => item === "SEA_PORT") === undefined)
                        item.directionTo.SEA_PORT.push("SEA_PORT")
                })
            } else {
                if (rules.directionFrom.SEA_PORT === undefined)
                    rules.directionFrom.SEA_PORT = []
                if (rules.directionTo.SEA_PORT === undefined)
                    rules.directionTo.SEA_PORT = []
                if (rules.directionFrom.SEA_PORT.find(item => item === "SEA_PORT") === undefined)
                    rules.directionFrom.SEA_PORT.push("SEA_PORT")
                if (rules.directionTo.SEA_PORT.find(item => item === "SEA_PORT") === undefined)
                    rules.directionTo.SEA_PORT.push("SEA_PORT")
            }
        }

        let rulesContainer = find(disablingRules, rule => (rule.cargoType === "CONTAINER")
            && (rule.countryFrom === fields.fromLocation.countryCode && rule.countryTo === fields.toLocation.countryCode)) || null
        if (rulesContainer === null) {
            disablingRules.push(this.rulesContainerItem(fields.fromLocation.countryCode, fields.toLocation.countryCode))
            this.updateState({disablingRules,})
            rules = find(disablingRules, rule => (rule.cargoType === findKey(TAB_TO_CARGO_TYPE, val => val === activeTab))
                && (rule.countryFrom === fields.fromLocation.countryCode && rule.countryTo === fields.toLocation.countryCode)) || null
        }
        if (isCargo)
            return disablingRules
        //END ALWAYS ENABLED CARGO
        return rules
    }

    onChangeField = (value, name, pathToErr) => {
        const fields = cloneDeep(this.state.fields);
        const errors = cloneDeep(this.state.fieldsErrors);
        if (!name && isObject(value)) {
            each(value, (fieldValue, fieldName) => {
                set(fields, fieldName, fieldValue);
            });
        } else {
            set(fields, name, value);
            if(name.includes("cargo.containers") && name.includes("typeId")){
                const weightInKg = MAX_CONTAINER_WEIGHT.US;
                let nameContainer = `${name.split('.')[0]}.${name.split('.')[1]}`;
                const el = get(fields, nameContainer);
                const maxValue = el.weightUnit === 'LB' ? convertKgToLb(MAX_CONTAINER_WEIGHT.US) : weightInKg;
                const isUs = get(fields, 'fromLocation.countryCode') === 'US' || get(fields, 'toLocation.countryCode') === 'US';
                if (isUs) {
                    if(el.typeId===1)
                        set(fields, `${nameContainer}.weight`, maxValue);
                    else
                        set(fields, `${nameContainer}.weight`, el.weightUnit === 'LB'?convertKgToLb(MAX_CONTAINER_WEIGHT.default) : MAX_CONTAINER_WEIGHT.default);
                }
            }
            delete errors[name];
            delete errors[pathToErr];
            //Обработка ошибки ввода размеров сборного груза
            fields.cargo.generals.forEach((valueGeneral, indexElem) => {
                if (valueGeneral.palletId !== '') {
                    //Перевод неизменямых полей у паллетов в соответствующую единицу измерения
                    if (name.split('.')[2] === 'sizeUnit') {
                        set(fields, `cargo.generals[${indexElem}].length`, valueGeneral.length * (DIMENSIONS[valueGeneral.prevSizeUnit]) / (DIMENSIONS[valueGeneral.sizeUnit]));
                        set(fields, `cargo.generals[${indexElem}].width`, valueGeneral.width * (DIMENSIONS[valueGeneral.prevSizeUnit]) / (DIMENSIONS[valueGeneral.sizeUnit]));
                        set(fields, `cargo.generals[${indexElem}].prevSizeUnit`, valueGeneral.sizeUnit);
                    }
                }
                    const countError = [0, 0, 0, 0, 0]; //Height, Length, Width, Purchase Volume, Weight
                    if (valueGeneral.typeId === 3) {
                        if (valueGeneral.cubeVolume * (DIMENSIONS[valueGeneral.sizeUnit]) > (MAX_CARGO_SIZE.WIDTH * MAX_CARGO_SIZE.HEIGHT * MAX_CARGO_SIZE.LENGTH)) {
                            countError[3] = 1;
                        } else {
                            countError[3] = 0;
                        }
                    } else {
                        if (valueGeneral.height * (DIMENSIONS[valueGeneral.sizeUnit]) > MAX_CARGO_SIZE.HEIGHT) {
                            countError[0] = 1;
                        } else {
                            countError[0] = 0;
                        }
                        if (valueGeneral.length * (DIMENSIONS[valueGeneral.sizeUnit]) > MAX_CARGO_SIZE.LENGTH) {
                            countError[1] = 1;
                        } else {
                            countError[1] = 0;
                        }
                        if (valueGeneral.width * (DIMENSIONS[valueGeneral.sizeUnit]) > MAX_CARGO_SIZE.WIDTH) {
                            countError[2] = 1;
                        } else {
                            countError[2] = 0;
                        }
                    }
                    if (valueGeneral.weight * (DIMENSIONS[valueGeneral.weightUnit]) > MAX_CARGO_SIZE.WEIGHT) {
                        countError[4] = 1;
                    } else {
                        countError[4] = 0;
                    }

                if (countError.includes(1)) {
                    set(fields, `cargo.generals[${indexElem}].errorOverload`, true);
                } else {
                    set(fields, `cargo.generals[${indexElem}].errorOverload`, false);
                }
            });
        }

        this.updateState({
            fields,
            fieldsErrors: errors,
        });

        return fields;
    };

    onChangeContainerAmount = (value, name, pathToErr) => {
        const fields = this.onChangeField(value, name, pathToErr);
        this.fetchCustomsClearance(fields);
    };

    onChangeInsurance = (value, name, pathToErr, lineToRemove = null) => {
        this.onChangeField(value, name, pathToErr);

        if (lineToRemove !== null) {
            const fieldsErrors = cloneDeep(this.state.fieldsErrors);

            if (isEmpty(fieldsErrors)) return;

            const errorsKeys = Object.keys(fieldsErrors);
            const resultErrors = {};

            errorsKeys.forEach((key) => {
                const insuranceReg = key.match(/cargoInsurances\[(.*)\]/);

                if (!isEmpty(insuranceReg)) {
                    const idx = get(insuranceReg, '[1]', null);

                    if (+idx < lineToRemove) {
                        resultErrors[key] = fieldsErrors[key];
                    } else if (+idx > lineToRemove) {
                        const newKey = key.replace(/\[(.+?)\]/g, `[${idx - 1}]`);
                        resultErrors[newKey] = fieldsErrors[key];
                    }
                } else {
                    resultErrors[key] = fieldsErrors[key];
                }
            });

            this.updateState({fieldsErrors: resultErrors});
        }
    };

    getLocationArrayByHubs = (hubs) => {
        const preparedTypeToFieldName = omit(TYPE_TO_FIELDNAME, TYPE_OF_LOCATIONS.ADDRESS);

        const preparedLocation = reduce(preparedTypeToFieldName, (result, val, key) => {
            const hub = find(hubs, el => el.type === key);
            const id = hub ? hub.id : '';
            return Object.assign(result, {[val]: id});
        }, {});

        return preparedLocation;
    };

    getAllLocationsByCity = async (value, locationType) => {
        if (!value) return null;

        const isAddress = locationType === TYPE_OF_LOCATIONS.ADDRESS;
        const allHubs = isAddress ?
            await DictionaryService.getAllHubsByAddress(value) :
            await DictionaryService.getAllHubsByCityId({cityId: get(value, 'city.id', 0)});

        const preparedLocation = this.getLocationArrayByHubs(allHubs);

        const cityName = get(value, 'city.name', '');

        Object.assign(preparedLocation, {
            address: isAddress ? value : {label: cityName},
            city: get(allHubs, '[0].city', ''),
            [TYPE_TO_FIELDNAME[locationType]]: value.id || null,
        });

        return preparedLocation;
    };

    onChangeCity = async (value, where, locationType, _currentCountry = null) => {
        const fieldsErrors = this.deleteLocationErrors(where);
        if (!value || (Object.keys(value).length === 0)) {
            this.updateState({
                fields: {
                    ...this.state.fields,
                    [`${where}Location`]: {
                        ...get(this.state.fields, `${where}Location`, {}),
                        address: null,
                        seaPortId: '',
                        airportId: '',
                        stationId: '',
                        city: null,
                    },
                },
                fieldsErrors,
            });

            return;
        }

        const isAddress = locationType === TYPE_OF_LOCATIONS.ADDRESS;
        const isPort = locationType === TYPE_OF_LOCATIONS.SEA_PORT;

        if (isAddress) {
            if (where === 'to') {
                let nextPromises = [
                    DictionaryService.getCityesPopular({
                        searchString: '',
                        language: getLangInStorage(),
                        country: this.state.fields.fromLocation.countryCode,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        hubToId: value.placeId,
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: true
                    }),
                ];
                Promise.all(nextPromises).then((nextRes) => {
                    this.updateState({
                        fromCities: nextRes[0],
                    });
                })
            }
            if (where === 'from') {
                let nextPromises = [
                    DictionaryService.getCityesPopular({
                        searchString: '',
                        language: getLangInStorage(),
                        country: this.state.fields.toLocation.countryCode,
                        countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                        countryTo: get(this.state.fields, `toLocation.countryCode`),
                        typeFrom: get(this.state.fields, `fromLocation.type`),
                        hubToId: value.placeId,
                        typeTo: get(this.state.fields, `toLocation.type`),
                        isFrom: false
                    }),
                ];
                Promise.all(nextPromises).then((nextRes) => {
                    this.updateState({
                        toCities: nextRes[0],
                    });
                })
            }
        }
        if(isPort){
            if(where==='to'){

            }
            if(where==='from'){

            }
        }

        this.updateState({
            ...(isAddress && {
                fields: {
                    ...this.state.fields,
                    [`${where}Location`]: {
                        ...get(this.state.fields, `${where}Location`, {}),
                        address: value,
                    },
                },
            }),
            isCityLoading: true,
        });

        const currentCountry = _currentCountry || get(this.state.fields, `${where}Location.countryCode`, 'RU');
        const country = this.state.countries.find(el => el.code === currentCountry);

        const params = isAddress ? Object.assign(value, {countryId: country.id}) : value;
        const allHubs = await this.getAllLocationsByCity(params, locationType);

        const location = {
            ...get(this.state.fields, `${where}Location`, {}),
            countryCode: currentCountry,
            type: locationType,
            ...allHubs,
        };

        this.updateState({
            isCityLoading: false,
            [`${where}Country`]: country.id,
            fields: {
                ...this.state.fields,
                [`${where}Location`]: location,
            },
            fieldsErrors,
        });
    };

    onChangeLocation = (value, name, pathToErr) => {
        const fields = cloneDeep(this.state.fields);
        const errors = cloneDeep(this.state.fieldsErrors);

        const {activeTab} = this.state;

        if (!name && isObject(value)) {
            each(value, (fieldValue, fieldName) => {
                set(fields, fieldName, fieldValue);
            });
        } else {
            set(fields, name, value);
            delete errors[name];
            delete errors[pathToErr];
        }

        const customsHouseState = this.getCustomHouseValue(fields, activeTab);
        set(fields, 'customsHouse', customsHouseState.value);

        this.updateState({
            fields,
            fieldsErrors: errors,
            activeTab,
            customsHouseState,
        }, () => {
            if (name === 'fromLocation.type' || name === 'toLocation.type') {
                this.setEnabledCargos(fields);
                this.setEnabledHubs(fields, name.startsWith('from') ? 'fromCountry' : 'toCountry');
            }
            this.LoadPopularHubsOnChange();
        });
    };

    onChangeCountry = (value, name, path, callback=() => {}) => {
        const fields = cloneDeep(this.state.fields);
        const errors = cloneDeep(this.state.fieldsErrors);
        set(fields, name, value.code);
        delete errors[name];
        delete errors[path];

        const where = path === 'fromCountry' ? 'from' : 'to';
        const isFrom = path === 'fromCountry' ? true : false;

        const ports = ['city', 'seaPortId', 'airportId', 'stationId'];
        ports.forEach((port) => {
            set(fields, `${where}Location.${port}`, '');
        });
        set(fields, `${where}Location.address`, null);

        const customsHouseState = this.getCustomHouseValue(fields, this.state.activeTab);

        set(fields, 'customsHouse', customsHouseState.value);

        const containers = fields.cargo.containers.map((el) => {
            const weightInKg = MAX_CONTAINER_WEIGHT.US;
            const maxValue = el.weightUnit === 'LB' ? convertKgToLb(MAX_CONTAINER_WEIGHT.US) : weightInKg;
            const isUs = get(fields, 'fromLocation.countryCode') === 'US' || get(fields, 'toLocation.countryCode') === 'US';

            if ((isUs && +el.weight > maxValue) || (!isUs && +el.weight === maxValue)) {
                if(el.typeId===1)
                    set(el, 'weight', maxValue);
            }

            return el;
        });

        set(fields.cargo, 'containers', containers);

        this.setEnabledCargos(fields);

        const clearPorts = {
            [`${where}Ports`]: null,
            [`${where}Airports`]: null,
            [`${where}Stations`]: null,
        };


        this.updateState({
            fields,
            fieldsErrors: errors,
            [path]: value.id,
            customsHouseState,
            ...clearPorts,
        }, () => {
            this.setEnabledHubs(fields, path);
            this.fetchCustomsClearance();

            const promises = [
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: get(this.state, `${where}Country`),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: isFrom
                }),
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: get(this.state, `${where}Country`),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: isFrom
                }),
                DictionaryService.getRailwayHubsByCityPopular({
                    countryId: get(this.state, `${where}Country`),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: isFrom
                }),
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.toLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.fromLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getContainerTypesByCountries({
                    countryFromId: get(this.state, 'fromCountry'),
                    countryToId: get(this.state, 'toCountry')
                }),
            ];
            Promise.all(promises).then((res) => {
                this.updateState({
                    [`${where}Ports`]: res[0],
                    [`${where}Airports`]: res[1],
                    [`${where}Stations`]: res[2],
                    toCities: res[3],
                    fromCities: res[4],
                    vehicleTypes: res[5],
                });
            });
            callback();
        });
    };

    onAddCargoType = (type) => {
        const cloned = cloneDeep(this.state.fields.cargo[type]);
        const typeToFields = {
            containers: DEFAULT_CONTAINER,
            generals: DEFAULT_GENERAL,
            vehicle: DEFAULT_VEHICLE,
        };

        cloned.push({...typeToFields[type]});

        this.updateState({
            fields: {
                ...this.state.fields,
                cargo: {
                    ...this.state.fields.cargo,
                    [type]: cloned,
                },
            },
        });
    };

    onRemoveCargoType = (type, index) => {
        const cloned = cloneDeep(this.state.fields.cargo[type]);

        cloned.splice(index, 1);

        this.updateState({
            fields: {
                ...this.state.fields,
                cargo: {
                    ...this.state.fields.cargo,
                    [type]: cloned,
                },
            },
        });
    };

    switchIncludeState = (propName) => {
        this.updateState({[propName]: !this.state[propName]});
    };

    fetchCustomsClearance(_fields = null) {
        const {fromCountry, toCountry, activeTab} = this.state;

        const fields = _fields || this.state.fields;

        const data = {
            countryFrom: fromCountry,
            countryTo: toCountry,
            containers: get(fields, 'cargo.containers', null),
            cargoType: findKey(TAB_TO_CARGO_TYPE, val => val === activeTab),
        };

        OrderService.calcCustoms(data).then((res) => {
            if (res.status === 'success' && res.data) {
                this.updateState({
                    customsClearanceData: res.data,
                });
            }
        });
    }

    getDirectionForm() {
        return (
            <DirectionForm
                fields={this.state.fields}
                swapCountries={this.swapCountries}
                countries={this.state.countries}
                toCities={this.state.toCities}
                fromCities={this.state.fromCities}
                toPorts={this.state.toPorts}
                fromPorts={this.state.fromPorts}
                toAirports={this.state.toAirports}
                fromAirports={this.state.fromAirports}
                toStations={this.state.toStations}
                fromStations={this.state.fromStations}
                seaLines={this.state.seaLines}
                consolidators={this.state.consolidators}
                from={this.state.fields.fromLocation}
                to={this.state.fields.toLocation}
                date={this.state.fields.shippingDate}
                activeTab={this.state.activeTab}
                onChange={this.onChangeLocation}
                onChangeCountry={this.onChangeCountry}
                onChangeCity={this.onChangeCity}
                errors={this.state.fieldsErrors}
                errorsRef={this.state.errorsRefs}
                onErrorsRefs={this.onErrorsExists}
                directionErrors={this.state.directionErrors}
                directInputsKeys={this.state.directInputsKeys}
                enabledHubs={this.state.enabledHubs}
                // onSuccess={() => this.onChangeView(VIEWS.PARAMETERS)}
                cargoTypeHighlight={this.setCargoTypeHighlight}
            />
        );
    }

    getCleanLocation = (fields, where) => {
        const locationType = get(fields, `${where}Location.type`);
        const emptyLocation = {
            address: null,
            seaPortId: '',
            airportId: '',
            stationId: '',
        };
        const addressField = get(fields, `${where}Location.address.placeId`, false) ? 'address' : 'cityId';
        const preparedTypeToFieldName = {...TYPE_TO_FIELDNAME, [TYPE_OF_LOCATIONS.ADDRESS]: addressField};
        const fieldName = preparedTypeToFieldName[locationType];
        const locationFields = {
            ...fields[`${where}Location`],
            ...emptyLocation,
            [fieldName]: get(fields, `${where}Location.${fieldName}`, ''),
        };

        return locationFields;
    };

    onSuccess = async () => {
        const startSearching = performance.now();
        this.updateState({
            errorsRefs: [],
            isShowPointError: false
        });

        const fields = cloneDeep(this.state.fields);


        // удаляем морскую линию, если порты в разных странах
        if (fields.fromLocation.countryCode !== fields.toLocation.countryCode || fields.fromLocation.type !== TYPE_OF_LOCATIONS.SEA_PORT) {
            delete fields.fromLocation.seaLineId;
        }

        if (get(fields, 'fromLocation.address.label', false)) {
            delete fields.fromLocation.address.label;
        }

        if (isEmpty(fields.fromLocation.address)) {
            set(fields.fromLocation, 'address', null);
            set(fields.fromLocation, 'cityId', get(fields, 'fromLocation.city.id'));
        }

        delete fields.fromLocation.city;

        if (get(fields, 'toLocation.address.label', false)) {
            delete fields.toLocation.address.label;
        }

        if (isEmpty(fields.toLocation.address)) {
            set(fields.toLocation, 'address', null);
            set(fields.toLocation, 'cityId', get(fields, 'toLocation.city.id'));
        }
        delete fields.toLocation.city;

        set(fields, 'fromLocation', this.getCleanLocation(fields, 'from'));
        set(fields, 'toLocation', this.getCleanLocation(fields, 'to'));

        let domain = "";
        let hostname = window.location.hostname;
        if(this.state.domain!==null){
            domain = this.state.domain
        }else if(hostname.split(".").length===3){
            domain = hostname.split(".")[0]
        }else if (UserStore.user && UserStore.user.organization && UserStore.user.organization.domain){
            domain = UserStore.user.organization.domain
        }
        console.log("DOMAIN: ", domain)
        set(fields, "domain", domain)



        // удаляем условия таможни
        //delete fields.customOfCargo;

        // if (fields.fromLocation.cityId) {
        //   set(fields.fromLocation, 'address', null);
        // }
        //
        // if (fields.toLocation.cityId) {
        //   set(fields.toLocation, 'address', null);
        // }

        const cleanCargo = fields.cargo.generals.map((el) => {
            if (el.typeId === 1) {
                set(el, 'palletId', '');
            }

            return omit(el, 'palletType');
        });

        set(fields.cargo, 'generals', cleanCargo);

        if (this.state.activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO) {
            fields.cargo.containers.length = 0;
            fields.cargo.vehicle = null;
        } else if (this.state.activeTab === TAB_TO_CARGO_TYPE.CONTAINER) {
            fields.cargo.generals.length = 0;
            fields.cargo.vehicle = null;
        } else if (this.state.activeTab === TAB_TO_CARGO_TYPE.VEHICLE) {
            fields.cargo.containers.length = 0;
            fields.cargo.generals.length = 0;

            if (get(fields, 'fromLocation.countryCode') !== 'RU' && get(fields, 'toLocation.countryCode') !== 'RU') {
                set(fields, 'cargo.vehicle.bodyType', 'TENT');
            }
        }

        if (!this.state.isInsuranceIncluded) {
            delete fields.cargoInsurances;
        }

        this.updateState({
            isWaiting: true,
        });

        if (!this.isCustomsClearanceFeeEnabled()) {
            fields.hasCustomsClearance = false;
        }

        if(this.props.isModalElement===true) {
            const orderDetailsParam = cloneDeep(this.state.orderDetailsParam)
            const request = {}
            set(request,"from", fields.fromLocation)
            set(request,"to", fields.toLocation)
            set(request,"cargo", fields.cargo)
            set(request,"shortOrderRequestDetailsParam", orderDetailsParam)
            set(request, "transitHubId", orderDetailsParam.transitHubId?orderDetailsParam.transitHubId.id:null)
            if(orderDetailsParam.newOwner && orderDetailsParam.newOwner.user && orderDetailsParam.newOwner.organization){
                set(request, "newOwner.organization", orderDetailsParam.newOwner.organization)
                set(request, "newOwner.user", orderDetailsParam.newOwner.user)
            }
            set(request,"price", orderDetailsParam.price)
            const res = await OrderService.createOrderFake(request);
            if(res.status==='success'){
                this.updateState({
                    fieldsErrors: {},
                    isWaiting: false,
                });
                this.props.onSave(res.data)
            }else if(res.status==='fail'){
                const errors = {};
                let generalError = '';

                get(res, 'data.errors', []).forEach((error) => {
                    if (has(error, 'fieldName')) {
                        let fieldName = error.fieldName
                        if(fieldName.includes("to."))
                            fieldName = fieldName.replace("to.","toLocation.")
                        if(fieldName.includes("from."))
                            fieldName = fieldName.replace("from.","fromLocation.")
                        errors[fieldName] = error.errorText;
                    } else {
                        generalError = error.errorText;
                    }
                });
                this.updateState({
                    fieldsErrors: errors,
                    generalError,
                    isWaiting: false,

                });
            } else {
                this.updateState({
                    isWaiting: false,
                });
            }

            return ;
        }

        const res = await OrderService.createOrder(fields);
        if(res.code===403){
            this.updateState({
                showIsLimitCalculation: true,
                isWaiting: false,
            });
        }
        if (res.status === 'success') {
            const orderId = get(res, 'data.id', 0);

            this.updateState({
                fieldsErrors: {},
                isWaiting: false,
            });

            const isWidget = this.props.location.search.includes('isWidget=true');

            // eslint-disable-next-line quote-props
            pushAnalyticalEvent(metricsEvents.ButtonSearch);

            UserStore.addCalcToBeShowAll(orderId);
            orderRedirect(UserStore.user.generalRole, orderId, this.props.history, '', isWidget, startSearching);
            UserStore.setGuestOrder(orderId);

            // FlashStore.successNow(translate({ id: 'confirm.order.success' }), 4000);
        } else if (res.status === 'fail') {
            const errors = {};
            let generalError = '';

            get(res, 'data.errors', []).forEach((error) => {
                if (has(error, 'fieldName')) {
                    errors[error.fieldName] = error.errorText;
                } else {
                    generalError = error.errorText;
                }
            });
            this.updateState({
                fieldsErrors: errors,
                generalError,
                isWaiting: false,

            });


        } else {
            this.updateState({
                isWaiting: false,
            });
        }
    };

    onErrorsExists = async () => {
        if (this.state.isShowPointError === false) {

            let errors = cloneDeep(this.state.errorsRefs)
            let errorsNew = []
            errors = errors.filter(value => value != null)
            errors.forEach(value => {
                if (!errorsNew.find(item => value.id === item.id))
                    errorsNew.push(value)
            })
            if (errorsNew.length > 0) {
                this.updateState({
                    isShowPointError: true
                });
                setTimeout(() => {
                    if (!this.state.generalError) {
                        FlashStore.errorNow(translate({id: 'confirm.order.fail'}), 3000);
                    }
                    let item = errorsNew[0]
                    item.scrollIntoView()
                }, 1000)
            }
        }
    };

    onReset = () => {
        FlashStore.successNow(translate({id: 'confirm.order.reset'}), 4000);

        const fromCountry = this.state.countries.find(item => item.code === DEFAULT_FIELDS.fromLocation.countryCode);

        const toCountry = this.state.countries.find(item => item.code === DEFAULT_FIELDS.toLocation.countryCode);

        this.updateState({
            fields: DEFAULT_FIELDS,
            fieldsErrors: {},
            directionErrors: {},
            activeTab: TAB_TO_CARGO_TYPE.GENERAL_CARGO,
            fromCountry: get(fromCountry, 'id', null),
            toCountry: get(toCountry, 'id', null),
            isInsuranceIncluded: false,
            orderDetailsParam:{
                companyName:'',
                phone:'',
                cargoDescription:'',
                dateReadyCargo:{plan:''},
                remarks:'',
                transitHubId:null,
                price:{
                    value:0,
                    currency:"USD"
                },
                newOwner:null
            }
        }, () => {
            this.setEnabledHubs(this.state.fields);
            this.fetchCustomsClearance();

            const promises = [
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: get(this.state, 'fromCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: get(this.state, 'fromCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getRailwayHubsByCityPopular({
                    countryId: get(this.state, 'fromCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: this.state.toCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: get(this.state, 'toCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getRailwayHubsByCityPopular({
                    countryId: get(this.state, 'toCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.toLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.fromLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getContainerTypesByCountries({
                    countryFromId: get(this.state, 'fromCountry'),
                    countryToId: get(this.state, 'toCountry')
                }),
            ];

            Promise.all(promises).then((res) => {
                this.updateState({
                    fromPorts: res[0],
                    fromAirports: res[1],
                    fromStations: res[2],
                    toPorts: res[3],
                    toAirports: res[4],
                    toStations: res[5],
                    toCities: res[6],
                    fromCities: res[7],
                    vehicleTypes: res[8],
                });
            });
        });
    };

    setActiveTab = (activeTab, where, newFields = null) => {
        let fields;

        if (newFields) {
            fields = cloneDeep(newFields);
        } else {
            fields = cloneDeep(this.state.fields);
        }

        const customsHouseState = this.getCustomHouseValue(fields, activeTab);
        set(fields, 'customsHouse', customsHouseState.value);

        this.updateState({
            activeTab,
            fields,
            fieldsErrors: {},
            customsHouseState,
        }, async () => {
            await this.setAvailableCountry(fields);
            this.setEnabledHubs(fields, where);
            this.fetchCustomsClearance();
            const promises = [
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: get(this.state, 'fromCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: get(this.state, 'fromCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getRailwayHubsByCityPopular({
                    countryId: get(this.state, 'fromCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getSeaPortHubsByCityPopular({
                    countryId: this.state.toCountry,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getAirportHubsByCityPopular({
                    countryId: get(this.state, 'toCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getRailwayHubsByCityPopular({
                    countryId: get(this.state, 'toCountry'),
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.toLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: false
                }),
                DictionaryService.getCityesPopular({
                    searchString: '',
                    language: getLangInStorage(),
                    country: this.state.fields.fromLocation.countryCode,
                    countryFrom: get(this.state.fields, `fromLocation.countryCode`),
                    countryTo: get(this.state.fields, `toLocation.countryCode`),
                    typeFrom: get(this.state.fields, `fromLocation.type`),
                    typeTo: get(this.state.fields, `toLocation.type`),
                    isFrom: true
                }),
                DictionaryService.getContainerTypesByCountries({
                    countryFromId: get(this.state, 'fromCountry'),
                    countryToId: get(this.state, 'toCountry')
                }),
            ];

            Promise.all(promises).then((res) => {
                this.updateState({
                    fromPorts: res[0],
                    fromAirports: res[1],
                    fromStations: res[2],
                    toPorts: res[3],
                    toAirports: res[4],
                    toStations: res[5],
                    toCities: res[6],
                    fromCities: res[7],
                    vehicleTypes: res[8],
                });
            });
        });
    };

    deleteLocationErrors = (where = null, errors = null) => {
        const fieldsErrors = errors || cloneDeep(this.state.fieldsErrors);
        const errorsKeys = Object.keys(fieldsErrors);

        const condition = where ?
            (key) => {
                return key.includes(`${where}Location`);
            }
            :
            (key) => {
                return key.includes('fromLocation') || key.includes('toLocation');
            };

        errorsKeys.forEach((key) => {
            if (condition(key)) {
                delete fieldsErrors[key];
            }
        });

        return fieldsErrors;
    };

    onLangChange = (lang) => {
        const fields = cloneDeep(this.state.fields);

        const getTranslateByAddress = (where) => {
            const address = get(fields, `${where}Location.address`, null);

            if (isEmpty(get(address, `label.${lang}`)) && (get(address, 'placeId'))) {
                return fetch(`${API.GOOGLE.BASE}&place_id=${address.placeId}&language=${lang}`)
                    .then(res => res.json())
                    .then((res) => {
                        const city = get(res, 'results[0]', null);

                        if (city) {
                            set(fields, `${where}Location.address.label.${lang}`, city.formatted_address);
                        }
                    });
            }

            return null;
        };

        const promises = [getTranslateByAddress('from'), getTranslateByAddress('to')];

        Promise.all(promises).then(() => {
            this.updateState({fields});
        });
    };

    getGeneralModal() {
        const {generalError} = this.state;

        return (
            <Modal
                top
                isShow={!isEmpty(generalError)}
                className="crud-modal"
                onClose={() => this.updateState({generalError: ''})}
            >
                <Col xs={12} className="text-center mb-3">
                    <FormattedMessage id={generalError || 'confirm.order.fail'}/>
                </Col>
                <Col xs={12} className="text-center">
                    {(generalError === 'general.cargo.compound.to.container.40' || generalError === 'general.cargo.compound.to.container.20') ?
                        <div>
                            <Button onClick={() => this.submitCompoundModal('GENERAL')}
                                    className="mr-2"><FormattedMessage
                                id={'general.cargo.compound.generalBtn'}/></Button>
                            <Button onClick={() => this.submitCompoundModal('CONTAINER')}><FormattedMessage
                                id={'general.cargo.compound.containerBtn'}/></Button>
                        </div> :
                        <Button onClick={() => this.updateState({generalError: ''})}><FormattedMessage
                            id={'common.close'}/></Button>
                    }
                </Col>
            </Modal>
        );
    }

    submitCompoundModal(type) {
        const fields = cloneDeep(this.state.fields);
        set(fields, 'calculationCargoType', type);
        const {generalError} = this.state;

        if (generalError.includes('general.cargo.compound.to.container')) {
            const typeValue = generalError.slice(-2) || '';
            set(fields, 'typeContainerForCalculate', `CONTAINER${typeValue}`);
        }

        this.updateState({
            fields,
            generalError: '',
        }, () => {
            this.onSuccess();
        });
    }

    swapCountries = () => {
        const newToCountryCode = this.state.fields.fromLocation;
        const newFromCountryCode = this.state.fields.toLocation;
        const newToCountryId = this.state.fromCountry;
        const newFromCountryId = this.state.toCountry;

        const to = {
            id: newToCountryId,
            code: newToCountryCode.countryCode,
        };
        const from = {
            id: newFromCountryId,
            code: newFromCountryCode.countryCode,
        };
        const newState = {
            ...this.state.fields,
            fromLocation: {
                ...this.state.fields.fromLocation,
                type: 'ADDRESS',
            },
            toLocation: {
                ...this.state.fields.toLocation,
                type: 'ADDRESS',
            },
        }
        this.updateState({
            fields: newState,
        }, () => this.onChangeBothCountries(from, to));
    }

    onChangeBothCountries(from, to) {
        this.onChangeCountry(from, 'fromLocation.countryCode', 'fromCountry', () => this.onChangeCountry(to, 'toLocation.countryCode', 'toCountry'));
        //setTimeout(() => this.onChangeCountry(to, 'toLocation.countryCode', 'toCountry'), 100);
    }

    dismissText() {
        localStorage.setItem('isDismissWizard', 'true');
        this.updateState({
            isDismiss: true,
        });
    }

    redirectToOrders() {
        const widget = this.props.location.search.includes('isWidget=true');
        let urlPrefix = null;

        switch (UserStore.user.generalRole) {
            case ROLES.OPERATOR_ADMIN:
                urlPrefix = ADMIN_ORDERS;
                break;

            case ROLES.PARTNER_ADMIN:
            case ROLES.PARTNER_MANAGER:
                urlPrefix = PARTNER_ORDERS;
                break;

            case ROLES.CLIENT:
            default:
                urlPrefix = CLIENT_ORDERS;
                break;
        }

        this.props.history.push({
            pathname: `${urlPrefix}/`,
            state: {
                widget,
            },
        });
    }

    getOrderButton() {
        if (UserStore.user.generalRole === ROLES.GUEST) {
            return null;
        }

        return (
            <Button color="primary" onClick={() => this.redirectToOrders()} className="wizard_btn_calculate">
                <FormattedMessage id="person.calculations"/>
            </Button>
        );
    }

    getTitle() {
        return (
            <div className="wizard_title">
                <FormattedMessage id="form.wizard.title" tagName="h1"/>
            </div>);
    }

    getBootstrapText() {
        /*if (!this.state.isDismiss) {
            if (getLangInStorage() === 'ru') {
                return (
                    <div className="alert alert-warning alert-dismissible fade show alert-warning-wizard" role="alert">
                        <div className="warning"/>
                        <ul className="mb-0">
                            <li>Актуальные тарифы участников нашей сети по всему миру.</li>
                            <li>В режиме реального времени вы получите все доступные маршруты по стоимости и срокам.
                            </li>
                        </ul>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                onClick={() => this.dismissText()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                );
            }
            if (getLangInStorage() === 'zh') {
                //TODO: Добавить перевод для китайского
                return (
                    <div className="alert alert-warning alert-dismissible fade show alert-warning-wizard" role="alert">
                        <div className="warning"/>
                        <ul className="mb-0">
                            <li>填写以下的表格并点击“搜索”，查询所有可用的路线和最新价格。</li>
                            <li>您可以选择最适合的路线和价格，或申请报价。</li>
                        </ul>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                onClick={() => this.dismissText()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>)
            }

            return (
                <div className="alert alert-warning alert-dismissible fade show alert-warning-wizard" role="alert">
                    <div className="warning"/>
                    <ul className="mb-0">
                        <li>Receive all currently available routes and up-to-date prices in real time by filling in the
                            short form below and clicking ‘Search’.
                        </li>
                        <li>You can pick the rate and route which is best for you or apply for a custom quote in one
                            click.
                        </li>
                    </ul>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                            onClick={() => this.dismissText()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
        return '';*/
    }

    isCustomsClearanceFeeEnabled = () => {
        return get(this.getDisablingRule(), 'hasCustomsClearance', false);
    };

    onChangeOrderDetailsParam=(data)=>{
        this.updateState({
            orderDetailsParam:data
        })
    }

    render() {
        const {
            containerTypes,
            palletTypes,
            generalTypes,
            sizeUnits,
            vehicleTypes,
            fields,
            fieldsErrors,
            directionErrors,
            isWaiting,
            isCityLoading,
            customsClearanceData
        } = this.state;
        const widget = this.props.location.search.includes('isWidget=true');
        const query = new URLSearchParams(this.props.location.search);

        /*
        if (query.get('appId')) {
          console.log('appId =', query.get('appId'));
          AppStore.setAppId(query.get('appId'));
        }
        if (query.get('terms')) {
          AppStore.setTerms(query.get('terms'));
        }
        const terms = AppStore.terms;
        console.log('%%> terms', terms);
        */

        if (query.get('language')) {
            setTimeout(() => AppStore.changeLang(query.get('language')), 300);
        }

        const allCargos = Object.entries(TAB_TO_CARGO_TYPE).map(entry => entry[1]);

        if(this.props.isModalElement===true)
            return (<div>
                {/*{this.getTitle()}
                {this.getBootstrapText()}*/}
                <div className="wizard">
                    {this.getGeneralModal()}
                    {this.getDirectionForm()}
                    <CargoForm
                      fields={fields}
                      generalTypes={generalTypes}
                      sizeUnits={sizeUnits}
                      containerTypes={containerTypes}
                      palletTypes={palletTypes}
                      vehicleTypes={vehicleTypes}
                      activeTab={this.state.activeTab}
                      allCargos={allCargos}
                      enabledCargos={this.state.enabledCargos}
                      onChange={this.onChangeField}
                      onChangeContainerAmount={this.onChangeContainerAmount}
                      onAddCargoType={this.onAddCargoType}
                      onRemoveCargoType={this.onRemoveCargoType}
                      setActiveTab={this.setActiveTab}
                      errors={fieldsErrors}
                      errorsRef={this.state.errorsRefs}
                      onErrorsRefs={this.onErrorsExists}
                      directionErrors={directionErrors}
                      isHighlight={this.state.cargoTypeHighlight}
                    />
                    <OrderDetailsBlock
                      toCountry={this.state.toCountry}
                      errors={this.state.fieldsErrors}
                      onErrorDelete={(error)=>{
                          const errors = cloneDeep(this.state.fieldsErrors)
                          delete errors[error]
                          this.updateState({
                              fieldsErrors:errors
                          })
                      }}
                      onSave={this.onChangeOrderDetailsParam} data={this.state.orderDetailsParam}
                    />

                    <Container>
                        <Row className={widget ? 'justify-content-end mt-4' : 'my-4 justify-content-end'}>
                            <Col xs={6} md={3} className="align-self-end pl-0">
                                <Button disabled={this.state.isWaiting} className="btn-reset" block type="button" onClick={this.onReset}>
                                    <FormattedMessage id="wizard.button.reset"/>
                                </Button>
                            </Col>
                            <Col xs={6} md={3} className="align-self-end pr-0">
                                <Button className="btn-search" block type="button" color="success" onClick={this.onSuccess}
                                        disabled={this.state.isWaiting || !isEmpty(directionErrors) || isCityLoading}>
                                    <div className={"d-flex justify-content-center"}>
                                        {(this.state.isWaiting || !isEmpty(directionErrors) || isCityLoading)&& <div className="fa-1x mr-2">
                                            <i className="fa fa-spinner fa-spin" style={{ color: '#fff' }} />
                                        </div>}
                                        <FormattedMessage id="crudForm.button.save"/>
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>)

        return this.props.isSEO ? (
            <div className="wizard">
                {this.getGeneralModal()}
                {this.getDirectionForm()}
                <CargoForm
                    fields={fields}
                    generalTypes={generalTypes}
                    sizeUnits={sizeUnits}
                    containerTypes={containerTypes}
                    palletTypes={palletTypes}
                    vehicleTypes={vehicleTypes}
                    activeTab={this.state.activeTab}
                    allCargos={allCargos}
                    errorsRef={this.state.errorsRefs}
                    onErrorsRefs={this.onErrorsExists}
                    enabledCargos={this.state.enabledCargos}
                    onChange={this.onChangeField}
                    onChangeContainerAmount={this.onChangeContainerAmount}
                    onAddCargoType={this.onAddCargoType}
                    onRemoveCargoType={this.onRemoveCargoType}
                    setActiveTab={this.setActiveTab}
                    errors={fieldsErrors}
                    directionErrors={directionErrors}
                    isHighlight={this.state.cargoTypeHighlight}
                />
                {this.state.customsHouseState.isShown &&
                    <CommonForm
                        fields={fields}
                        onChange={this.onChangeField}
                        tabIndex={this.state.activeTab}
                        customsHouseState={this.state.customsHouseState}
                    />
                }
                {get(this.getDisablingRule(), 'hasCustomsClearance', false) &&
                    <CustomsClearanceFee
                        containers={get(fields, 'cargo.containers', [])}
                        isIncluded={get(fields, 'hasCustomsClearance', false)}
                        switchInclude={() => this.onChangeField(!this.state.fields.hasCustomsClearance, 'hasCustomsClearance')}
                        customsData={customsClearanceData}
                    />
                }
                <InsuranceForm
                    cargoInsurances={fields.cargoInsurances}
                    isIncluded={this.state.isInsuranceIncluded}
                    errors={fieldsErrors}
                    onChange={this.onChangeInsurance}
                    switchInclude={() => this.switchIncludeState('isInsuranceIncluded')}
                />
                <Row className={widget ? 'justify-content-end' : 'my-4 justify-content-end'}>
                    <Col xs={12} md={3} className="align-self-end mb-2 mb-md-0">
                        <Button block type="button" onClick={this.onReset}>
                            <FormattedMessage id="wizard.button.reset"/>
                        </Button>
                    </Col>
                    <Col xs={12} md={3} className="align-self-end pr-0">
                        <Button block type="button" color="success" onClick={this.onSuccess}
                                disabled={!isEmpty(directionErrors) || isCityLoading}>
                            <FormattedMessage id="wizard.button.search"/>
                        </Button>
                    </Col>
                </Row>
            </div>
        ) : (
            <Page
                // pageTitle={<FormattedMessage id={this.getTitleId()} />}
                changeLangCallback={this.onLangChange}
                history={this.props.history}
                isPreloaderShow={isWaiting}
                location={this.props.location}
                scope="user"
                widget={widget}
                seoTitle={translate('form.wizard.seo.title')}
                seoDescription={translate('form.wizard.seo.description')}
            >
                <Modal
                    title={<FormattedMessage id={"common.error"}/>}
                    onClose={()=>
                        this.updateState({
                            showIsLimitCalculation: false
                        }
                        )}
                    isShow={this.state.showIsLimitCalculation}
                >
                    <FormattedMessage id={"errorLimit"}/>
                    <Link className={"ml-2"} to={redirectToSubscription(UserStore.user.generalRole)}><FormattedMessage id={"detailsOfCalculation.details"}/></Link>
                </Modal>
                {this.getTitle()}
                {this.getBootstrapText()}
                <div className="wizard">
                    {this.getGeneralModal()}
                    {this.getDirectionForm()}
                    <CargoForm
                        fields={fields}
                        generalTypes={generalTypes}
                        sizeUnits={sizeUnits}
                        containerTypes={containerTypes}
                        palletTypes={palletTypes}
                        vehicleTypes={vehicleTypes}
                        activeTab={this.state.activeTab}
                        allCargos={allCargos}
                        enabledCargos={this.state.enabledCargos}
                        onChange={this.onChangeField}
                        onChangeContainerAmount={this.onChangeContainerAmount}
                        onAddCargoType={this.onAddCargoType}
                        onRemoveCargoType={this.onRemoveCargoType}
                        setActiveTab={this.setActiveTab}
                        errors={fieldsErrors}
                        errorsRef={this.state.errorsRefs}
                        onErrorsRefs={this.onErrorsExists}
                        directionErrors={directionErrors}
                        isHighlight={this.state.cargoTypeHighlight}
                    />
                    {this.state.customsHouseState.isShown &&
                        <CommonForm
                            fields={fields}
                            onChange={this.onChangeField}
                            tabIndex={this.state.activeTab}
                            customsHouseState={this.state.customsHouseState}
                        />
                    }
                    {/* HARDCODE: disable ClearanceFee clock for Incargo S.L. widget  */}
                    {(this.isCustomsClearanceFeeEnabled() && !(widget && AppStore.appId === '999999999')) &&
                        <CustomsClearanceFee
                            containers={get(fields, 'cargo.containers', [])}
                            isIncluded={get(fields, 'hasCustomsClearance', false)}
                            switchInclude={() => this.onChangeField(!this.state.fields.hasCustomsClearance, 'hasCustomsClearance')}
                            customsData={customsClearanceData}
                        />
                    }
                    {
                        <InsuranceForm
                            cargoInsurances={fields.cargoInsurances}
                            isIncluded={this.state.isInsuranceIncluded}
                            errors={fieldsErrors}
                            onChange={this.onChangeInsurance}
                            switchInclude={() => this.switchIncludeState('isInsuranceIncluded')}
                        />
                    }
                    <Row className={widget ? 'justify-content-end mt-4' : 'my-4 justify-content-end'}>
                        <Col xs={6} md={3} className="align-self-end">
                            <Button className="btn-reset" block type="button" onClick={this.onReset}>
                                <FormattedMessage id="wizard.button.reset"/>
                            </Button>
                        </Col>
                        <Col xs={6} md={3} className="align-self-end pr-0">
                            <Button className="btn-search" block type="button" color="success" onClick={this.onSuccess}
                                    disabled={!isEmpty(directionErrors) || isCityLoading}>
                                <FormattedMessage id="wizard.button.search"/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Page>
        );
    }
}
Wizard.defaultProps={
    isModalElement:false
}

Wizard.propTypes = {
    onSave:PropTypes.func,
    isModalElement: PropTypes.bool,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isSEO: PropTypes.bool,
    fromCountry: PropTypes.string,
    toCountry: PropTypes.string,
    fromCity: PropTypes.object,
    toCity: PropTypes.object,
    cargoType: PropTypes.string,
    fromHub: PropTypes.string,
    toHub: PropTypes.string,
};

Wizard.defaultProps = {
    widget: false,
    isSEO: false,
};
