import {get, isArray, set} from "lodash";
import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {DATE_FORMAT} from "../../../constants/global";
import {convertErrorsArrayToObject, getExistLangValue} from "../../../utils/utils";
import {toJS} from "mobx";
import FontAwesome from "react-fontawesome";

export const getError = (errors, index, name) => {
    return get(errors, `tariffs[${index}].${name}`, null) !== null
}

export const isError = (errors, index, name) => {
    if (errors)
        return get(errors, `tariffs[${index}].${name}`, null) !== null
    return null;
}

//Copy and paste functions
export const onCopyValueSelect = (rowData, values) => {
    if (isArray(rowData)) {
        return rowData.map(v => v.label).join(", ")
    }
    const result = values.find((value) => {
        return (rowData ? value.value === rowData.value : false)
    })
    return result ? result.label : null;
}

export const onPasteValueSelect = (paste, values = [], rowData, rowIndex) => {
    const result = values.find(
        (value) => {
            return (paste ? value.label === paste : false)
        });
    return result ? result : null
}
export const onPasteValueSelectSurcharges = (paste, values = []) => {
    let pasteArrParse = paste ? paste.includes(", ") ? paste.split(", ") : [paste] : []
    const result = values.filter(
        (value) => {
            return (pasteArrParse ? pasteArrParse.includes(value.label) : false)
        });
    return result ? result : null
}

export const onCopyValueDate = (rowData) => {
    return rowData;
}

export const onPasteValueDate = (paste, rowData, rowIndex) => {
    const isDate = function (date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }
    if (paste != null) {
        if (isDate(paste) === false)
            return null;

        if (paste.includes(".")) {
            const date = paste.split(".")
            const res = `${date[2]}-${date[1]}-${date[0]}`;
            if (isDate(res))
                return res
            else
                return null
        }
        return paste;
    }
}

export const onCopyValueText = (rowData) => {
    return rowData;
}

export const onPasteValueText = (paste, rowData, rowIndex) => {
    if (paste != null)
        return paste;
}

//!Copy and paste functions
//DataGrid handler functions

export const onDuplicate = (rowData) => {
    const newObj = {...rowData}
    set(newObj, "id", "-")
    delete newObj["organizationId"]
    delete newObj["owner"]
    return newObj
}



//!DataGrid handler functions

//DataGrid Custom components
export const createAddRowsComponent = () => ({addRows}) => {
    const [value, setValue] = useState(1)
    const [rawValue, setRawValue] = useState(String(value))

    return (
        <div className="dsg-add-row">

            <button
                type="button"
                className="dsg-add-row-btn"
                onClick={() => addRows(value)}
            >
                <FormattedMessage id={"common.addRow"}/>
            </button>
            {' '}

        </div>
    )
}

//!DataGrid Custom components


export const emptyRowsFilter = (rows = []) => {
    const result = []
    for (let row of rows) {
        let isAdd = false;
        for (let key in row) {
            let data = get(row, key, null);
            if (data !== null && data !== undefined) {
                isAdd = true;
            }
        }
        set(row, "__emptyRow", !isAdd)
        result.push(row)
    }
    return result;
}


//Formating functions
export const createParam4Deleted = (arr) => {
    const resRows = []
    for (let id of arr) {
        let row = {}
        set(row, "__id", id)
        resRows.push(row)
    }
    return resRows;
}

export const patchRow = (row) => {

    if(get(row, "__emptyRow", false)===true)
        return row;

    const formatDate = (date) => {
        if (date !== null && date !== undefined)
            return moment(date).format(Array.isArray(DATE_FORMAT.ru) ? DATE_FORMAT.ru[0] : DATE_FORMAT.ru)
        return null;
    }
    const buildContainerRates = (data) => {
        let obj = []
        if (get(data, 'freight20', null) !== null) {
            obj.push({
                containerTypeId: 1,
                price: {value: get(data, 'freight20'), currency: get(data, 'currency')},
                maxWeight: {value: 20000, unit: 'KG'}
            })
        }
        if (get(data, 'freight40', null) !== null) {
            obj.push({
                containerTypeId: 2,
                price: {value: get(data, 'freight40'), currency: get(data, 'currency')},
                maxWeight: {value: 20000, unit: 'KG'}
            })
        }
        if (get(data, 'freight40HC', null) !== null) {
            obj.push({
                containerTypeId: 3,
                price: {value: get(data, 'freight40HC'), currency: get(data, 'currency')},
                maxWeight: {value: 20000, unit: 'KG'}
            })
        }
        return obj
    }
    set(row, 'departurePortId', get(row, 'departurePortId.value', null))
    set(row, 'destinationPortId', get(row, 'destinationPortId.value', null))
    set(row, 'seaLineId', get(row, 'seaLineId.value', null))

    let surcharges = get(row, 'freightId', [])
    let freightSurcharges = []
    if (surcharges !== null && surcharges !== undefined)
        for (let i = 0; i < surcharges.length; i++) {
            freightSurcharges.push(surcharges[i].value)
        }
    set(row, 'freightSurcharges', freightSurcharges)
    delete row["freightId"];
    set(row, 'freightCondition', get(row, 'freightCondition.value', null))
    set(row, 'currency', get(row, 'currency.value', null))
    set(row, 'weightUnit', 'KG')
    set(row, 'modifier', 'PUBLIC')
    set(row, 'active', true)
    set(row, 'dateFrom', formatDate(get(row, 'dateFrom')))
    set(row, 'dateTo', formatDate(get(row, 'dateTo')))
    set(row, 'cargoContainerRates', buildContainerRates(row))
    set(row, '__organizationId', get(row, 'organizationId', null))
    delete row["organizationId"];
    if (get(row, "id", null) === "-")
        delete row["id"]
    set(row, '__id', get(row, 'id', null))
    delete row["owner"];

    return row
}

export const prepareExternalData = (dataArr = []) => {
    const resultData = []
    for (let dataItem of dataArr) {
        let resultDataItem = {}
        for (const key of Object.keys(dataItem)) {
            switch (key) {
                case 'departurePort': {
                    let val = get(dataItem, key, null);
                    set(resultDataItem, "departurePortId", {value: val.id, label: getExistLangValue(val.name)})
                    break;
                }
                case 'destinationPort': {
                    let val = get(dataItem, key, null);
                    set(resultDataItem, "destinationPortId", {value: val.id, label: getExistLangValue(val.name)})
                    break;
                }
                case 'seaLine': {
                    let val = get(dataItem, key, null);
                    set(resultDataItem, "seaLineId", {value: val.id, label: getExistLangValue(val.name)})
                    break;
                }
                case 'freightSurcharges': {
                    let val = get(dataItem, key, null);
                    let valJs = toJS(val, true)
                    if (isArray(valJs)) {
                        for (let i = 0; i < valJs.length; i++) {
                            set(resultDataItem, `freightId[${i}]`, {
                                value: valJs[i].id,
                                label: valJs[i].code,
                                name: getExistLangValue(valJs[i].name)
                            });

                        }
                    }
                    break;
                }
                case 'freightCondition': {
                    let val = get(dataItem, key, null);
                    set(resultDataItem, 'freightCondition', {value: val, label: val})
                    break;
                }
                case 'currency': {
                    let val = get(dataItem, key, null);
                    set(resultDataItem, 'currency', {value: val, label: val})
                    break;
                }
                case 'cargoContainerRates': {
                    let val = get(dataItem, key, [])
                    const valJs = toJS(val, true)
                    for (let container of valJs) {
                        if (container && container.containerType && container.containerType.id) {
                            if (container.containerType.id === 1) {
                                set(resultDataItem, "freight20", container.price.value)
                            }
                            if (container.containerType.id === 2) {
                                set(resultDataItem, "freight40", container.price.value)
                            }
                            if (container.containerType.id === 2) {
                                set(resultDataItem, "freight40HC", container.price.value)
                            }
                        }
                    }
                    break;
                }
                default:
                    set(resultDataItem, key, get(dataItem, key, null))
                    break;
            }
        }
        resultData.push(resultDataItem)
    }

    return resultData;
}

export const formatValues = (values = []) => {
    let result = []
    for (let val of values) {
        result.push({value: val.id, label: getExistLangValue(val.name)})
    }
    return result
}

export const formatSurchargesValues = (values = []) => {
    let result = []
    for (let val of values) {
        if (val.surcharge)
            result.push({
                value: val.surcharge.id,
                label: val.surcharge.code,
                name: getExistLangValue(val.surcharge.name),
                seaLine: val.seaLine ? val.seaLine.id : null
            })
    }
    return result
}

export const formatError = (errs = []) => {
    let objErrs = convertErrorsArrayToObject(errs)
    for (let keyErr of Object.keys(objErrs)) {
        const keyRow = keyErr.split(".")[0]
        if (keyErr.includes("cargoContainerRates") && !keyErr.includes("cargoContainerRates[")) {
            set(objErrs, keyRow + "cargoContainerRates[0].price", "common.required")
            set(objErrs, keyRow + "cargoContainerRates[1].price", "common.required")
            set(objErrs, keyRow + "cargoContainerRates[2].price", "common.required")
        }
    }
    return objErrs
}

//!Formating functions

export const renderFreight = (option, {context}) => {
    return context === "menu" ? option.name : option.label
}

// Для картинок в options
const typeToIconName = {
    SEA_PORT: 'anchor',
    TRANSPORT: 'truck',
    AIRPORT_HUB: 'plane',
    RAILWAY_HUB: 'train',
};

export const renderOptions = (option) => {

    const icon = typeToIconName.SEA_PORT;

    return (
        <div>
            <FontAwesome name={icon} style={{paddingRight: '10px'}}/>
            {option.label}
        </div>
    );
};

//!Utils section