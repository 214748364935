import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactSelect from 'react-select-old';
import { addFormatName } from '../../utils/utils';
import './common.css';

import AppStore from '../../stores/appStore';
import {margin} from "polished";

@observer
class LanguageSwitcher extends Component {
  state = {
    lang: localStorage.getItem('userLang'),
  };

  handleSwitch = (langCode) => {
    // смена языка гугл апи при смене языка
    this.props.changeGoogleApi(langCode);

    const params = new URLSearchParams(window.location.search);
    AppStore.changeLang(langCode);
    this.setState({
      lang: langCode,
    });
    // change language get param in URL
    if (params.has('language')) {
      const langFromSearch = params.get('language');
      if (langFromSearch !== langCode) {
        params.set('language', langCode);
        const { origin, pathname } = window.location;
        window.location.search = params.toString();
      }
    }
  };

  optionComponent = (option) => {
    return (
      <div className="options">
        <span className={`flag flag-${option.code}`} />
        {/*{option.code}*/}
      </div>
    );
  };

  valueComponent = (value) => {
    return (
      <div className="values">
        <span className={`flag flag-${value.code}`} />
        {/*{value.code}*/}
      </div>
    );
  };

  render() {
    const languages = [];

    if (AppStore.languages) {
      AppStore.languages.forEach((item) => {
        const _item = Object.assign(item);

        _item.title = `${item.name[item.code]} (${item.code})`;
        languages.push(_item);
      });
    }


    const prepareLanguages = addFormatName(languages);
    const language = prepareLanguages.find(item => item.code === this.state.lang);

    return (
      <ReactSelect
        className={this.props.widget
          ? 'header__buttons_language-switcher_select header__buttons_language-switcher_select_widget'
          : 'header__buttons_language-switcher_select'
        }
        options={languages}
        value={language}
        optionRenderer={this.optionComponent}
        valueRenderer={this.valueComponent}
        labelKey="code"
        valueKey="code"
        onChange={value => this.handleSwitch(value.code)}
        searchable={false}
        clearable={false}
      />
    );
  }
}

LanguageSwitcher.propTypes = {
  changeGoogleApi: PropTypes.func.isRequired,
  widget: PropTypes.bool,
};

LanguageSwitcher.defaultProps = {
  widget: false,
};

export default LanguageSwitcher;
