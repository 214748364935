import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import { get, includes, find, isEmpty, orderBy } from 'lodash';
import AppStore from '../../stores/appStore';
import { InterlayerAsyncSelect } from './../ui/form-control';
import { addFormatName, addFormatNameToCity, addFormatNameWithCode, addFormatNameWithHub, addFormatNameToAddress, translate } from './../../utils/utils';
import { TYPE_OF_LOCATIONS } from '../../constants/global';
import { TAB_TO_CARGO_TYPE } from './DefaultFields';
import Tooltip from "../ui/Tooltip";

export const DirectionForm = (props) => {
  const {
    swapCountries,
    countries = [],
    toPorts = [],
    fromCities = [],
    toCities = [],
    fromPorts = [],
    toAirports = [],
    fromAirports = [],
    toStations = [],
    fromStations = [],
    seaLines = [],
    consolidators = [],
    from,
    to,
    onChange,
    onChangeCountry,
    onChangeCity,
    errors,
    errorsRef,
    onErrorsRefs,
    directionErrors,
    activeTab,
    directInputsKeys,
    enabledHubs,
    cargoTypeHighlight,
    // landingData,
    // changeLandingData,
  } = props;

  const prepareCountries = countries && orderBy(addFormatName(countries), el => el.formatName.toLowerCase());
  const prepareToCities = toCities && addFormatNameToAddress(toCities);
  const prepareFromCities = fromCities && addFormatNameToAddress(fromCities);
  const prepareToPorts = toPorts && addFormatNameToCity(toPorts);
  const prepareFromPorts = fromPorts && addFormatNameToCity(fromPorts);
  const prepareToAirports = toAirports && addFormatNameWithCode(toAirports);
  const prepareFromAirports = fromAirports && addFormatNameWithCode(fromAirports);
  const prepareToStations = toStations && addFormatNameWithHub(toStations);
  const prepareFromStations = fromStations && addFormatNameWithHub(fromStations);
  const prepareSeaLines = addFormatName(seaLines);
  const prepareConsolidators = addFormatName(consolidators);
  const isOneRowButton = useMediaQuery({ query: '(min-width: 1024px)' });

  // const prepareCustomsPosts = addFormatName(CUSTOMS_POSTS);

  const fromCountry = find(prepareCountries, item => item.code === from.countryCode);
  const toCountry = find(prepareCountries, item => item.code === to.countryCode);
  const seaLine = prepareSeaLines.find(item => item.id === from.seaLineId);
  const consolidator = prepareConsolidators.find(item => item.id === from.seaLineId);
  // const customsPost = prepareCustomsPosts.find(item => item.id === from.customsPostId);
  const fromCountryId = get(fromCountry, 'id', null);
  const toCountryId = get(toCountry, 'id', null);

  const getLocationTitle = (type) => {
    switch (type) {
      case TYPE_OF_LOCATIONS.AIRPORT_HUB: {
        return <div>
                    <span style={{
                      display: "block",
                      color: "rgba(141,152,166,0.6)",
                      fontSize: "14px",
                      lineHeight:"14px",
                      marginBottom: "0px",
                      paddingTop:"5px",
                    }}>{translate({id: "form.field.airport"})}</span>
        </div>;
      }
      case TYPE_OF_LOCATIONS.SEA_PORT: {
        return <div>
                    <span style={{
                      display: "block",
                      color: "rgba(141,152,166,0.6)",
                      fontSize: "14px",
                      lineHeight:"14px",
                      marginBottom: "0px",
                      paddingTop:"5px",
                    }}>{translate({id: "form.field.seaPort"})}</span>
        </div>;
      }
      case TYPE_OF_LOCATIONS.RAILWAY_HUB: {
        return <div>
                    <span style={{
                      display: "block",
                      color: "rgba(141,152,166,0.6)",
                      fontSize: "14px",
                      lineHeight:"14px",
                      marginBottom: "0px",
                      paddingTop:"5px",
                    }}>{translate({id: "form.field.station"})}</span>
        </div>
      }
      default: {
        return <div>
                    <span style={{
                      display: "block",
                      color: "rgba(141,152,166,0.6)",
                      fontSize: "14px",
                      lineHeight:"14px",
                      marginBottom: "0px",
                      paddingTop:"5px",
                    }}>{translate({id: "form.address.defaultPlaceholder"})}</span>
        </div>
      }
    }
  };

  const cargoTypeHighlightOn = () => cargoTypeHighlight(true);
  const cargoTypeHighlightOff = () => cargoTypeHighlight(false);


  const getLocationButtons = (type, onChangeType, disabledParameter, enabledHubsParam, isForAddressFrom = false) => {

    if (isOneRowButton) {
      return (
        <ButtonGroup style={{position: 'relative'}}>

          <Tooltip text={translate({ id: 'wizard.directionForm.disabledButtonHint' })} disabled={includes(enabledHubsParam, TYPE_OF_LOCATIONS.ADDRESS) || disabledParameter} onShow={cargoTypeHighlightOn} onHide={cargoTypeHighlightOff} >
          <Button
              style={AppStore.userLang==='en'?{minHeight:"60px"}:{}}
            className={type === TYPE_OF_LOCATIONS.ADDRESS ? 'isSelected': ''}
            onClick={() => onChange(TYPE_OF_LOCATIONS.ADDRESS, onChangeType)}
            disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.ADDRESS) || disabledParameter}
          >
            {translate({ id: isForAddressFrom? 'wizard.directionForm.addressFrom' : 'wizard.directionForm.addressTo' })}
          </Button>
          </Tooltip>

          <Tooltip text={translate({ id: 'wizard.directionForm.disabledButtonHint' })} disabled={includes(enabledHubsParam, TYPE_OF_LOCATIONS.SEA_PORT) || disabledParameter} onShow={cargoTypeHighlightOn} onHide={cargoTypeHighlightOff}>
          <Button
              style={AppStore.userLang==='en'?{minHeight:"60px"}:{}}
            className={type === TYPE_OF_LOCATIONS.SEA_PORT ? 'isSelected': ''}
            onClick={() => onChange(TYPE_OF_LOCATIONS.SEA_PORT, onChangeType)}
            disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.SEA_PORT) || disabledParameter}
          >
            {translate({ id: 'wizard.directionForm.seaPort' })}
          </Button>
          </Tooltip>

          <Tooltip text={translate({ id: 'wizard.directionForm.disabledButtonHint' })} disabled={includes(enabledHubsParam, TYPE_OF_LOCATIONS.AIRPORT_HUB) || disabledParameter} onShow={cargoTypeHighlightOn} onHide={cargoTypeHighlightOff}>
            <Button
                style={AppStore.userLang==='en'?{minHeight:"60px"}:{}}
              color='transparent'
              className={type === TYPE_OF_LOCATIONS.AIRPORT_HUB ? 'isSelected': ''}
              onClick={() => onChange(TYPE_OF_LOCATIONS.AIRPORT_HUB, onChangeType)}
              disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.AIRPORT_HUB) || disabledParameter}
            >
              {translate({ id: 'wizard.directionForm.airport' })}
            </Button>
          </Tooltip>

          <Tooltip text={translate({ id: 'wizard.directionForm.disabledButtonHint' })} disabled={includes(enabledHubsParam, TYPE_OF_LOCATIONS.RAILWAY_HUB) || disabledParameter} onShow={cargoTypeHighlightOn} onHide={cargoTypeHighlightOff} >
          <Button
              style={AppStore.userLang==='en'?{minHeight:"60px"}:{}}
            className={type === TYPE_OF_LOCATIONS.RAILWAY_HUB ? 'isSelected': ''}
            onClick={() => onChange(TYPE_OF_LOCATIONS.RAILWAY_HUB, onChangeType)}
            disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.RAILWAY_HUB) || disabledParameter}
          >
            {translate({ id: 'wizard.directionForm.railway' })}
          </Button>
          </Tooltip>
        </ButtonGroup>
      );
      }

      return (
        <div>
          <ButtonGroup>
            <Button
              className={type === TYPE_OF_LOCATIONS.ADDRESS ? 'isSelected': ''}
              onClick={() => onChange(TYPE_OF_LOCATIONS.ADDRESS, onChangeType)}
              disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.ADDRESS) || disabledParameter}
              >
              {translate({ id: isForAddressFrom? 'wizard.directionForm.addressFrom' : 'wizard.directionForm.addressTo' })}
            </Button>
            <Button
              className={type === TYPE_OF_LOCATIONS.SEA_PORT ? 'isSelected': ''}
              onClick={() => onChange(TYPE_OF_LOCATIONS.SEA_PORT, onChangeType)}
              disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.SEA_PORT) || disabledParameter}
            >
              {translate({ id: 'wizard.directionForm.seaPort' })}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              className={type === TYPE_OF_LOCATIONS.AIRPORT_HUB ? 'isSelected': ''}
              onClick={() => onChange(TYPE_OF_LOCATIONS.AIRPORT_HUB, onChangeType)}
              disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.AIRPORT_HUB) || disabledParameter}
            >
              {translate({ id: 'wizard.directionForm.airport' })}
            </Button>
            <Button
              className={type === TYPE_OF_LOCATIONS.RAILWAY_HUB ? 'isSelected': ''}
              onClick={() => onChange(TYPE_OF_LOCATIONS.RAILWAY_HUB, onChangeType)}
              disabled={!includes(enabledHubsParam, TYPE_OF_LOCATIONS.RAILWAY_HUB) || disabledParameter}
            >
              {translate({ id: 'wizard.directionForm.railway' })}
            </Button>
          </ButtonGroup>
        </div>
      );
  };

  const removeTerminals = (values) => {
    const result_array = [];
    if (values !== null) {
      for (let i = 0; i < values.length; i++) {
        let count_terminals = 0;
        for (let j = 0; j < values.length; j++) {
          if (values[i].city.id === values[j].city.id){
            count_terminals += 1;
          }
        }
        if (count_terminals >= 1 && values[i].hasTerminals === true) {
          result_array.push(values[i]);
        } else if (count_terminals === 1 && values[i].hasTerminals === false) {
          result_array.push(values[i]);
        } else if(count_terminals>=1 && values[i].hasTerminals === false){
          let hasTerm = false;
          for(let j=0;j<values.length;j++){
            if(values[i].city.id=== values[j].city.id)
              if(values[j].hasTerminals === true){
                hasTerm = true;
              }
          }
          if(!hasTerm)
            result_array.push(values[i]);
        }
      }
      return result_array;
    }
    return [];
  };

  const setInputFields = (item, where) => {
    const countryId = where === 'from' ? fromCountryId : toCountryId;
    const isFrom = where === 'from'

    return (
      <div>
        <div className={item.type !== TYPE_OF_LOCATIONS.ADDRESS ? 'd-none' : ''}>
          <InterlayerAsyncSelect
            key={`${directInputsKeys[`${where}Address`]}_${countryId}`}
            async
            name="wizardAddress"
            country={item.countryCode}
            countryId={countryId}
            countryFrom={fromCountryId}
            countryTo={toCountryId}
            hubTypeFrom={from.type}
            hubTypeTo={to.type}
            hubToId={to.address?to.address.placeId:undefined}
            hubFromId={from.address?from.address.placeId:undefined}
            isFrom={isFrom}
            searchable
            value={item.address}
            clearable={false}
            values={where === 'from' ? prepareFromCities : prepareToCities}
            labelKey="formatName"
            valueKey="placeId"
            placeholder="form.address.defaultPlaceholder"
            onChange={value => onChangeCity(value, where, TYPE_OF_LOCATIONS.ADDRESS)}
            error={errors[`${where}Location.address`]}
            filterOption={() => { return true; }}
            disabled={!isEmpty(directionErrors) || !countryId}
            backspaceRemoves={false}
          />
        </div>
        <div className={item.type !== TYPE_OF_LOCATIONS.SEA_PORT ? 'd-none' : ''}>
          <InterlayerAsyncSelect
            key={`${directInputsKeys[`${where}Sea`]}_${countryId}`}
            async
            name="wizardPort"
            countryId={countryId}
            countryFrom={fromCountryId}
            countryTo={toCountryId}
            hubTypeFrom={from.type}
            hubTypeTo={to.type}
            hubToId={to.address?to.address.placeId:undefined}
            hubFromId={from.address?from.address.placeId:undefined}
            isFrom={isFrom}
            searchable
            clearable={false}
            value={item.seaPortId}
            /*values={where === 'from' ? removeTerminals(prepareFromPorts) : removeTerminals(prepareToPorts)}*/
            values={where === 'from' ? (prepareFromPorts) : (prepareToPorts)}
            labelKey="formatName"
            valueKey="id"
            placeholder="form.field.seaPort"
            onChange={value => onChangeCity(value, where, TYPE_OF_LOCATIONS.SEA_PORT)}
            error={errors[`${where}Location.seaPortId`]}
            filterOption={() => { return true; }}
            disabled={!isEmpty(directionErrors) || !countryId}
          />
        </div>
        <div className={item.type !== TYPE_OF_LOCATIONS.AIRPORT_HUB ? 'd-none' : ''}>
          <InterlayerAsyncSelect
            key={`${directInputsKeys[`${where}Air`]}_${countryId}`}
            async
            name="wizardAirport"
            countryId={countryId}
            countryFrom={fromCountryId}
            countryTo={toCountryId}
            hubTypeFrom={from.type}
            hubTypeTo={to.type}
            hubToId={to.address?to.address.placeId:undefined}
            hubFromId={from.address?from.address.placeId:undefined}
            isFrom={isFrom}
            searchable
            clearable={false}
            value={item.airportId}
            values={where === 'from' ? prepareFromAirports : prepareToAirports}
            labelKey="formatName"
            valueKey="id"
            placeholder="form.field.airport"
            onChange={value => onChangeCity(value, where, TYPE_OF_LOCATIONS.AIRPORT_HUB)}
            error={errors[`${where}Location.airportId`]}
            filterOption={() => { return true; }}
            disabled={!isEmpty(directionErrors) || !countryId}
          />
        </div>
        <div className={item.type !== TYPE_OF_LOCATIONS.RAILWAY_HUB ? 'd-none' : ''}>
          <InterlayerAsyncSelect
            key={directInputsKeys[`${where}Railway`]}
            async
            name="wizardStation"
            countryId={countryId}
            countryFrom={fromCountryId}
            countryTo={toCountryId}
            hubTypeFrom={from.type}
            hubTypeTo={to.type}
            hubToId={to.address?to.address.placeId:undefined}
            hubFromId={from.address?from.address.placeId:undefined}
            isFrom={isFrom}
            searchable
            clearable={false}
            value={item.stationId}
            values={where === 'from' ? prepareFromStations : prepareToStations}
            labelKey="formatName"
            valueKey="id"
            placeholder="form.field.station"
            onChange={value => onChangeCity(value, where, TYPE_OF_LOCATIONS.RAILWAY_HUB)}
            error={errors[`${where}Location.stationId`]}
            filterOption={() => { return true; }}
            disabled={!isEmpty(directionErrors) || !countryId}
          />
        </div>
      </div>
    );
  };

  const renderSeaLines = () => {
    if (from.countryCode === to.countryCode && from.type === TYPE_OF_LOCATIONS.SEA_PORT) {
      return (
        <Row className="px-2">
          <Col xs={12}>
            {
              activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO ?
                <InterlayerAsyncSelect
                  async
                  name="consolidator"
                  searchable
                  clearable={false}
                  values={prepareConsolidators}
                  valueKey="id"
                  labelKey="formatName"
                  value={consolidator}
                  onChange={value => onChange(value.id, 'fromLocation.seaLineId')}
                  label="crudForm.field.consolidator"
                  className="crud-select"
                  error={get(errors, 'fromLocation.seaLineId', '')}
                  disabled={!fromCountry}
                />
                :
                <InterlayerAsyncSelect
                  async
                  name="seaLineId"
                  searchable
                  clearable={false}
                  values={prepareSeaLines}
                  valueKey="id"
                  labelKey="formatName"
                  value={seaLine}
                  onChange={value => onChange(value.id, 'fromLocation.seaLineId')}
                  label="crudForm.field.seaLines"
                  className="crud-select"
                  error={get(errors, 'fromLocation.seaLineId', '')}
                  disabled={!toCountry}
                />
            }
          </Col>
          {/*<Col xs={12} md={6}>*/}
          {/*<Select*/}
          {/*searchable*/}
          {/*values={prepareCustomsPosts}*/}
          {/*valueKey="id"*/}
          {/*labelKey="formatName"*/}
          {/*value={customsPost}*/}
          {/*onChange={value => onChange(value.id, 'fromLocation.customsPostId')}*/}
          {/*label="crudForm.field.customsPost"*/}
          {/*className="crud-select"*/}
          {/*error={errors.seaLineId}*/}
          {/*/>*/}
          {/*</Col>*/}
        </Row>
      );
    }

    return null;
  };

  return (
    <div className="container">
      <Row>
        <Col xs={12} md={6} className="wizard__direction-block_first">
          <div ref={(ref)=>{
            if (directionErrors.fromCountry ||
                errors['fromLocation.seaLineId'] ||
                errors[`fromLocation.address`] ||
                errors[`fromLocation.seaPortId`] ||
                errors[`fromLocation.airportId`] ||
                errors[`fromLocation.stationId`]
            ){
              errorsRef.push(ref);
              onErrorsRefs();
            }
          }} className="wizard__direction-block first">
            <Row className="wizard__direction-block_header align-items-center">
              <Col xs={12} className="wizard__direction-block_header_title mb-3">
                <FormattedMessage id="form.wizard.title.from" />
                <div className="wizard__direction-block_header_subtitle">
                  {AppStore.userLang !== 'zh' ?
                      <FormattedMessage id="form.wizard.pickup"/>:null
                  }
                </div>
              </Col>
            </Row>
            <Row className="wizard__direction-block_info mt-3 px-2">
              <Col xs={12} className="wizard__direction-block_country">
                <InterlayerAsyncSelect
                  key={`from_${fromCountryId}`}
                  async
                  name="wizardCountry"
                  label={<div>
                    <span style={{
                      display: "block",
                      color: "rgba(141,152,166,0.6)",
                      fontSize: "16px",
                      lineHeight:"16px",
                      marginBottom: "0px"
                    }}>{translate({id: "form.field.country"})}</span>
                    <span style={{
                      color: "rgba(145, 152, 167, 0.5)",
                      fontSize: "14px",
                      marginBottom: "5px"
                    }}>{translate({id:"form.field.countryNew"})}</span>
                </div>}
                  placeholder="form.field.country"
                  clearable={false}
                  values={prepareCountries}
                  value={fromCountry}
                  labelKey="formatName"
                  valueKey="code"
                  onChange={value => onChangeCountry(value, 'fromLocation.countryCode', 'fromCountry')}
                  // disabled={(from.type === 'SEA_PORT' || from.type === 'AIRPORT_HUB') && true}
                  searchable
                  backspaceRemoves={false}
                />
                { (directionErrors.fromCountry || directionErrors.toCountry) &&
                  <div className="wizard__direction-block_country_error">
                    { directionErrors.fromCountry && <span className="errorMessage"><FormattedMessage id={directionErrors.fromCountry} /></span> }
                  </div>
                }
              </Col>
              <Col xs={12} className="wizard__direction-block_location">
                {getLocationButtons(from.type, 'fromLocation.type', !fromCountry, enabledHubs.from, true)}
              </Col>
              <Col xs={12} className="wizard__direction-block_location-placeholder">
                {getLocationTitle(from.type)}
              </Col>
              <Col xs={12} className="wizard__direction-block_info-hubs">
                { setInputFields(from, 'from') }
              </Col>
            </Row>
            { renderSeaLines() }
          </div>
        </Col>
        <Col xs={12} md={6} className="wizard__direction-block_last">
          <div ref={(ref)=>{
            if (directionErrors.toCountry ||
                errors[`toLocation.address`] ||
                errors[`toLocation.seaPortId`] ||
                errors[`toLocation.airportId`] ||
                errors[`toLocation.stationId`]
            ){
              errorsRef.push(ref);
              onErrorsRefs();
            }
          }} className="wizard__direction-block last">
            <Row className="wizard__direction-block_header align-items-center">
              <Col xs={12} className="wizard__direction-block_header_title mb-3">
                <FormattedMessage id="form.wizard.title.to" />
                <div className="wizard__direction-block_header_subtitle">
                  {AppStore.userLang !== 'zh' ?
                    <FormattedMessage id="form.wizard.delivery" />:null
                  }
                </div>
              </Col>
            </Row>
            <Row className="wizard__direction-block_info mt-3 px-2">
              <Col xs={12} className="wizard__direction-block_country">
                <InterlayerAsyncSelect
                  key={`to_${toCountryId}`}
                  async
                  name="wizardCountry"
                  placeholder="form.field.country"
                  clearable={false}
                  label={<div>
                    <span style={{
                      display: "block",
                      color: "rgba(141,152,166,0.6)",
                      fontSize: "16px",
                      lineHeight:"16px",
                      marginBottom: "0px"
                    }}>{translate({id: "form.field.country"})}</span>
                    <span style={{
                      color: "rgba(145, 152, 167, 0.5)",
                      fontSize: "14px",
                      marginBottom: "5px"
                    }}>{translate({id:"form.field.countryNew"})}</span>
                  </div>}
                  values={prepareCountries}
                  value={toCountry}
                  labelKey="formatName"
                  valueKey="code"
                  onChange={value => onChangeCountry(value, 'toLocation.countryCode', 'toCountry')}
                  // disabled={(to.type === 'SEA_PORT' || to.type === 'AIRPORT_HUB') && true}
                  searchable
                  backspaceRemoves={false}
                />
                { (directionErrors.fromCountry || directionErrors.toCountry) &&
                  <div className="wizard__direction-block_country_error">
                    { directionErrors.toCountry && <span className="errorMessage"><FormattedMessage id={directionErrors.toCountry} /></span> }
                  </div>
                }
              </Col>
              <div className="swap-button-block" onClick={swapCountries} />
              <Col xs={12} className="wizard__direction-block_location">
                {getLocationButtons(to.type, 'toLocation.type', !toCountry, enabledHubs.to, false)}
              </Col>
              <Col xs={12} className="wizard__direction-block_location-placeholder">
                {getLocationTitle(to.type)}
              </Col>
              <Col xs={12} className="wizard__direction-block_info-hubs">
                { setInputFields(to, 'to') }
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

DirectionForm.propTypes = {
  swapCountries: PropTypes.func,
  countries: PropTypes.array,
  toCities: PropTypes.array,
  fromCities: PropTypes.array,
  toPorts: PropTypes.array,
  fromPorts: PropTypes.array,
  toAirports: PropTypes.array,
  fromAirports: PropTypes.array,
  toStations: PropTypes.array,
  fromStations: PropTypes.array,
  seaLines: PropTypes.array,
  consolidators: PropTypes.array,
  from: PropTypes.object,
  to: PropTypes.object,
  onChange: PropTypes.func,
  onChangeCountry: PropTypes.func,
  onChangeCity: PropTypes.func,
  errors: PropTypes.object,
  directionErrors: PropTypes.object,
  // fields: PropTypes.object,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  directInputsKeys: PropTypes.object,
  enabledHubs: PropTypes.object,
  errorsRef: PropTypes.array,
  onErrorsRefs: PropTypes.func,
  cargoTypeHighlight: PropTypes.func,
  // landingData: PropTypes.object,
  // changeLandingData: PropTypes.func,
};
