/* eslint-disable */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Container} from 'reactstrap';
import { AUTH_LOGIN, AUTH_LOGIN_FACEBOOK, AUTH_REGISTER, AUTH_LOGIN_VK , name_routes} from '../../constants/routes';
import LanguageSwitcher from './LanguageSwitcher';
import UserStore from '../../stores/userStore';
import AppStore from '../../stores/appStore';
import FlashStore from '../../stores/flashStore';
import { confirm, getLandingHref, translate, getLangInStorage } from '../../utils/utils';
import config from '../../config';
import {useHistory, useLocation} from "react-router";
import Breadcrumbs from "./Breadcrumbs";
import * as URL from "../../constants/routes";
import {pushAnalyticalEvent, metricsEvents} from "../../utils/analyticsMetrics";
import NotificationsModalWindow from "./notifications/NotificationsModalWindow";


const Header = (props) => {
  const { history, userIsAuthenticated, onContactUs, isMenuOpen, toggleUserMenu, toggleModalCurrentUser,
    changeGoogleApi, isLeftMenuOpen, isNotificationMenuOpen, toggleNotificationMenu } = props;
  const currentUser = UserStore.user || {};
  let historyHook = useHistory();

  const redirectToLandingPage = (e) => {
    e.preventDefault();
    const baseHostHref = getLandingHref();
    const lang = getLangInStorage();
    window.location.replace(`${baseHostHref}/${lang === 'ru' ? '' : 'en'}`);
  };

  const handleLogout = () => {
    confirm(<FormattedMessage id="dialog.confirm.default.question" />, async () => {
      await UserStore.logout();
      /*AppStore.changeStatusLeftMenu(false);
      FlashStore.info(<FormattedMessage id="site.user.logoutSuccess" />);
      let arrayHost = window.location.host.split(".");
      if(window.location.host.includes("localhost") === false && arrayHost.length===3){
        let newHost = `https://${arrayHost[1]}.${arrayHost[2]}`
        let newRoute = `${newHost}${AUTH_LOGIN}?redirect=true`
        window.location.assign(newRoute)
      }else{
        history.push(AUTH_LOGIN);
      }*/
    });
  };

  const fbLink = (`${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}` === 'guest/orders') ? `${AUTH_LOGIN_FACEBOOK}&redirectAfterLoginTo=${window.location.href}` : AUTH_LOGIN_FACEBOOK;
  const vkLink = (`${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}` === 'guest/orders') ? `${AUTH_LOGIN_VK}?redirectAfterLoginTo=${window.location.href}` : AUTH_LOGIN_VK;

  const phoneHref = `tel:${config.phoneNumber.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')}`;

  if(UserStore.isGuest()){
    AppStore.changeStatusLeftMenu(false)
  }

  const onLoginRedirect = (path) => {
    let arrayHost = window.location.host.split(".");
    if(window.location.host.includes("localhost") === false && arrayHost.length===3){
      let newHost = `https://${arrayHost[1]}.${arrayHost[2]}`
      let newRoute = `${newHost}${path}?redirect=true`
      window.location.assign(newRoute)
    }else{
      history.push(path);
    }
  }
  return (
      <div>
      <div className={`header d-flex align-items-center ${isLeftMenuOpen ? 'menu-open' : 'menu-close'}`}>
        <div className="container">
          <Row>
            { !UserStore.isGuest() ?
                <Col
                    xs={1}
                    sm={'auto'}
                    className="order-sm-0 d-sm-none order-sm-0-without-padding"
                ><Button className="header__menu-btn" onClick={() => AppStore.changeStatusLeftMenu()}/></Col> :
                null
            }
            {!UserStore.isGuest() &&!AppStore.isLeftMenuOpen ?
                < Col xs={5} sm={'auto'} className="header__logo d-lg-none  order-sm-1 order-md-1 col-sm-auto">
              <div className="header__logo_image" onClick={redirectToLandingPage} />
              </Col>
                : null
            }
            {UserStore.isGuest() &&
                < Col xs={5} sm={'auto'} className="header__logo  order-sm-1 order-md-1 col-sm-auto">
                  <div className="header__logo_image" onClick={redirectToLandingPage} />
                </Col>
            }
            {!UserStore.isGuest() &&
                <Col xs={5} sm={'auto'}
                     className="header__lk-title pl-0 d-lg-inline-block d-md-none order-sm-1 order-md-1 col-sm-auto d-sm-none d-none">
                  <div className=""><FormattedMessage id="site.leftMenu.link.personalArea"/></div>
                </Col>
            }
            {/*<Col xs={12} sm={'auto'} className="header__buttons_breadcrumbs mr-auto d-lg-block d-xl-block pl-0 order-md-2 d-md-none d-sm-none d-none">
              <div className="">
                {getBreadcrumbs()}
                <Breadcrumbs {...props}/>
              </div>
            </Col>*/}
            <Col className="phone-block order-md-2 ml-auto order-sm-2" xs={12} sm={'auto'} >
              {/*{AppStore.userLang === 'ru' &&
              <a href={phoneHref} className={'phone-link-btn'}>
                <div className={'phone-link-icon'}></div>
              </a>
              }*/}
            </Col>
            <Col xs={12} sm={'auto'} className="header__buttons contact_us_block order-sm-5 order-md-2">
              {/*{AppStore.userLang === 'ru' &&
                  <a href={phoneHref} className={'phone-link'}>{config.phoneNumber}</a>
              }*/}
              {/*<div className="header__buttons_contact-us">*/}
                {/*<Button onClick={onContactUs}>
                  { <FormattedMessage id="site.header.contactUs" /> }
                </Button>
              </div>*/}
            </Col>
            {!UserStore.isGuest() &&
                <Col xs={1} className="header__notification col-sm-auto pl-0 order-sm-3 order-md-3">
                  <NotificationsModalWindow
                      isNotificationMenuOpen={isNotificationMenuOpen}
                      toggleNotificationMenu={toggleNotificationMenu}
                  />
                </Col>
            }
            <Col xs={2} className="header__dropdown col-sm-auto pl-0 order-sm-4 order-md-4">
              { userIsAuthenticated &&
                  <Dropdown
                      isOpen={isMenuOpen}
                      toggle={toggleUserMenu}
                      className="header__dropdown_pull-right"
                      title={translate({ id: 'modal.currentUserInfo' })}
                  >
                    <DropdownToggle className="btn-default user-info-btn" caret>
                      <div className={'arrow'}>
                        <pre></pre>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu right>
                      { !UserStore.isGuest() &&
                          <div>
                            <div className="header__dropdown_text-title user-name user-info-item">
                              {currentUser.lastName} {currentUser.firstName} {currentUser.middleName}
                            </div>
                            <div className="header__dropdown_text-item pale user-role user-info-item user-info-item-last">
                              <FormattedMessage id={UserStore.getRoleTranslation()} />
                            </div>
                            <DropdownItem divider />
                            <div className="header__dropdown_text-item user-info-item user-info-item-first">
                              {currentUser.email}
                            </div>
                            <div className="header__dropdown_text-item user-info-item user-info-item-last">
                              {currentUser.phone}
                            </div>
                            <DropdownItem divider />
                          </div>
                      }

                      { UserStore.isGuest() ?
                          <div className={'account-btns'}>
                            <DropdownItem onClick={() => onLoginRedirect(AUTH_LOGIN)}>
                              <FontAwesome name="sign-in" className="mr-2" />
                              <FormattedMessage id="login.exit" />
                            </DropdownItem>
                            <DropdownItem onClick={() => { location.href = vkLink; }}>
                              <FontAwesome name="vk" className="mr-2 m-vk" />
                              <FormattedMessage id="login.submit.vk" />
                            </DropdownItem>
                           {/* <DropdownItem onClick={() => { location.href = fbLink; }}>
                              <FontAwesome name="facebook-square" className="mr-2" />
                              <FormattedMessage id="login.submit.facebook" />
                            </DropdownItem>*/}
                            <DropdownItem divider />
                            <DropdownItem onClick={() => onLoginRedirect(AUTH_REGISTER)}>
                              <FontAwesome name="user-plus" className="mr-2" />
                              <FormattedMessage id="register.title" />
                            </DropdownItem>
                          </div>
                          :
                          <div className={'account-btns'}>
                            <DropdownItem onClick={toggleModalCurrentUser}>
                              <div className={'user-edit-logo'}></div>
                              <FormattedMessage id="site.header.dropdown.edit" />
                            </DropdownItem>
                            <DropdownItem onClick={handleLogout}>
                              <div className={'user-logout-logo'}></div>
                              <FormattedMessage id="button.logout" />
                            </DropdownItem>
                          </div>
                      }
                    </DropdownMenu>
                  </Dropdown>
              }
            </Col>
            <Col xs={2} className="header__buttons_language-switcher col-md-auto col-sm-auto order-sm-5 order-md-5" style={UserStore.isGuest()?{display:"flex"}:{}}>
              <LanguageSwitcher changeGoogleApi={changeGoogleApi} />
            </Col>
            {!UserStore.isGuest() &&
                <Col xs={2}
                     className="col-md-auto col-lg-auto col-sm-auto order-sm-6 order-md-6 d-md-block d-sm-none d-none pr-0">
                  <div className="header__buttons_wizard_btn">
                    <Button onClick={() => {
                      pushAnalyticalEvent(metricsEvents.wizardBtnHeader)
                      historyHook.push(URL.WIZARD)
                    }}>
                      {<FormattedMessage id="person.wizard"/>}
                    </Button>
                  </div>
                </Col>
            }
          </Row>
        </div>
      </div>
        <div className={` ${isLeftMenuOpen ? 'menu-open-breadcrumb' : 'menu-close-breadcrumb'}`}>
          <div className="container">
            {/*{getBreadcrumbs()}*/}
            <Breadcrumbs {...props}/>
          </div>

        </div>
      </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  history: PropTypes.object,
  userIsAuthenticated: PropTypes.bool,
  onContactUs: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  isLeftMenuOpen: PropTypes.bool,
  toggleUserMenu: PropTypes.func,
  toggleModalCurrentUser: PropTypes.func,
  changeGoogleApi: PropTypes.func,
};

export default Header;
