import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

const HeaderModalTableTariffs = ({isEdit, tariffType }) => {

    const nameTariffPage ={
        SEA_TARIFFS: "partner.rates.sea"
    }

    return (
        <div className={"header-table"}>
            <div className={"header-table_image"}></div>
            <div className={"header-table_title"}>
                <FormattedMessage id={`crud.form.${isEdit===true?'edit':'add'}.table.title`}/>{" — "}
                <FormattedMessage id={nameTariffPage[tariffType]}/>
            </div>
        </div>
    );
};

HeaderModalTableTariffs.propTypes = {

};

export default HeaderModalTableTariffs;